import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Fragment, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ModelsUpload, ModelsUser } from "../../api";
import AddUserDialog from "../../components/OrgManagement/AddUserDialog";
import EditUserDialog from "../../components/OrgManagement/EditUserDialog";
import SearchDialog from "../../components/SearchDialog";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import {
  useToast,
  useIssue,
  usePageWaiting,
  useCheckAdmin,
} from "../../utils/Properties";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { useClipboard } from "../../utils/CopyToClipboard";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import UserUploadDialog from "./UserUploadDialog";
import { Chip } from "primereact/chip";
import UserAddFileDialog from "./UserAddFileDialog";
import UserDownloadCsvDialog from "./UserDownloadCSVDialog";
import { useFileWithType } from "../../utils/File";
import UserConfirmTriggerDialog from "./UserConfirmTrigger";
import UserDeleteFileDialog from "./UserDeleteFileDialog";
import { useCookies } from "react-cookie";
import UserDownloadSourceDialog from "./UserDownloadSourceDialog";
import { Card } from "primereact/card";
import FailedCaseDialog from "./FailedCaseDialog";
import TotalDetailDialog from "./TotalDetailDialog";
import CustomDeleteDialog from "../OrgManagement/CustomDeleteDialog";
import { Tag } from "primereact/tag";

const UserDashboard = () => {
  const properties = {
    title: "Users",
  };
  const issue = useIssue();
  const setPageWaiting = usePageWaiting();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogUpload, setDialogUpload] = useState(false);
  const [dialogAddFile, setDialogAddFile] = useState(false);
  const [dialogDownloadCsv, setDialogDownloadCsv] = useState(false);
  const [dialogConfirmTrigger, setDialogConfirmTrigger] = useState(false);
  const [dialogDeleteFile, setDialogDeleteFile] = useState(false);
  const [dialogSearch, setSearchDialog] = useState(false);
  const [objectAddFileState, setObjectAddFileState] = useState(
    {} as ModelsUpload
  );
  const [objectDeleteState, setObjectDeleteState] = useState(
    {} as ModelsUpload
  );
  const [objectDownloadCsvState, setObjectDownloadCsvState] = useState(
    {} as ModelsUpload
  );
  const [objectConfirmTriggerState, setConfirmTriggerState] = useState(
    {} as ModelsUpload
  );
  const [objectDownloadSourceState, setObjectDownloadSourceState] = useState(
    {} as ModelsUpload
  );
  const [dialogDownloadSource, setDialogDownloadSource] = useState(false);
  const [dialogTotalPagesDetail, setDialogTotalPagesDetail] = useState(false);
  const [dialogDelete, setDeleteDialog] = useState(false);
  const [uploadData, setUploadData] = useState({} as ModelsUpload);
  const [successToast, errorToast, ,] = useToast();
  const [initData, setInitData] = useState([] as ModelsUpload[]);
  const [data, setData] = useState([] as ModelsUpload[]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState("");
  const apiInstance = useSecureApiConnector();
  const [cookies, ,] = useCookies(["api-token"]);
  const [firstTrigger, setFirstTrigger] = useState(false);
  const location = useLocation();
  const isAdmin = useCheckAdmin();
  const [dialogFailState, setDialogFailState] = useState(false);
  const [objectFailState, setObjectFailState] = useState({} as ModelsUpload);
  const clipboard = useClipboard();
  const saveAsWithType = useFileWithType();

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [metric, setMetric] = useState(
    {} as {
      this_month: number;
      total: number;
    }
  );

  const fetchData = () => {
    console.log("Fetch");

    apiInstance.uploads
      .getMetric()
      .then((res) => res.data)
      .then((data) => {
        setMetric(data);
      })
      .catch(() => {
        // navigate("/forbidden")
      });

    apiInstance.uploads
      .getOffset(lazyParams.first, lazyParams.rows)
      .then((res) => res.data)
      .then((data) => {
        setInitData(data.record_list);
        setData(data.record_list);
        setTotalRecords(data.record_count!);
        setLoading(false);
      })
      .catch(() => {
        // navigate("/forbidden")
      });
  };
  const refresh = () => {
    setTriggerFetch((triggerFetch) => !triggerFetch);
  };
  const copy = (msg: string) => {
    clipboard(msg)
      .then(() => {
        successToast(`Copy upload id ${msg} to clipboard`);
      })
      .catch(() => {
        errorToast(`Copy upload id ${msg} to clipboard failed`);
      });
  };
  const paginatorTemplate = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </Fragment>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };
  const downloadSource = (data: ModelsUpload) => {
    setPageWaiting(true);
    apiInstance.uploads
      .downloadSources(data.id!, data.files!)
      .then((res) => {
        saveAsWithType(
          `sources-${data.name!}.zip`,
          res.data,
          "application/zip"
        );
      })
      .catch(() => {
        errorToast("download file failed");
      })
      .finally(() => {
        setPageWaiting(false);
      });
  };
  const onSubmit = (data: any) => {
    apiInstance.users
      .support(uploadData)
      .then(() => {
        successToast("Email sended");
        setObjectDeleteState({});
        setDeleteDialog(false);
        refresh();
      })
      .catch(() => {
        errorToast("Email send failed");
        setObjectDeleteState({});
      });
  };

  useEffect(() => {
    let timer = 0

    if (filter === "" || filter === undefined) {
      timer = 10000
    } else {
      timer = 30000
    }

    if (!firstTrigger) {
      setLoading(true);
      setInterval(() => {
        if (location.pathname === "/dashboard" && !isAdmin) {
          refresh();
        }
      }, timer);
      setFirstTrigger(true);
    }
  }, [firstTrigger]);
  useEffect(() => {
    // setLoading(true);
    // if (cookies["api-token"]) fetchData();
    fetchData();
  }, [triggerFetch]);
  useEffect(() => {
    setLoading(true);
    refresh();
  }, [lazyParams]);
  useEffect(() => {
    setLoading(true);
    let sfilter = filter;
    if (moment(filter, "MMMM Do YYYY", true).isValid()) {
      sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
    }

    apiInstance.uploads
      .getSearch({
        search: sfilter,
        offset: lazyParams.first,
        limit: lazyParams.rows,
      })
      .then((res) => res.data)
      .then((data) => {
        setTotalRecords(data.record_count!);
        setData(data.record_list);
        setLoading(false);
      })
      .catch(() => {
        setData([]);
        setLoading(false);
      });
  }, [lazyParams]);
  useEffect(() => {
    if (objectAddFileState.id !== undefined) {
      setDialogAddFile(true);
    }
  }, [objectAddFileState]);
  useEffect(() => {
    if (objectConfirmTriggerState.id !== undefined) {
      setDialogConfirmTrigger(true);
    }
  }, [objectConfirmTriggerState]);
  useEffect(() => {
    if (objectDownloadCsvState.id !== undefined) {
      setDialogDownloadCsv(true);
    }
  }, [objectDownloadCsvState]);
  useEffect(() => {
    if (objectDownloadSourceState.id !== undefined) {
      setDialogDownloadSource(true);
    }
  }, [objectDownloadSourceState]);
  useEffect(() => {
    if (objectDeleteState.id !== undefined) {
      setDialogDeleteFile(true);
    }
  }, [objectDeleteState]);
  useEffect(() => {
    if (filter === " " && data !== initData) {
      setData(initData);
    } else {
      setLoading(true);
      let sfilter = filter;
      if (moment(filter, "MMMM Do YYYY", true).isValid()) {
        sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
      }

      apiInstance.uploads
        .getSearch({
          search: sfilter,
          offset: lazyParams.first,
          limit: lazyParams.rows,
        })
        .then((res) => res.data)
        .then((data) => {
          setTotalRecords(data.record_count!);
          setData(data.record_list);
          setLoading(false);
        })
        .catch(() => {
          setData([]);
          setLoading(false);
        });
    }
  }, [filter]);

  return (
    <>
      <CustomDeleteDialog
        data={objectDeleteState}
        setData={setObjectDeleteState}
        visible={dialogDelete}
        setVisible={setDeleteDialog}
        refresh={refresh}
        onSubmit={onSubmit}
        meessage={<>Confirm to notification support team? </>}
        titlename="Support Confirmation"
        data2={uploadData}
      />
      <UserUploadDialog
        visible={dialogUpload}
        setVisible={setDialogUpload}
        refresh={refresh}
        doc_ai={issue.directory?.tenant?.docoment_ai_flag}
      />
      <UserAddFileDialog
        data={objectAddFileState}
        setData={setObjectAddFileState}
        visible={dialogAddFile}
        setVisible={setDialogAddFile}
        refresh={refresh}
      />
      <UserDownloadCsvDialog
        data={objectDownloadCsvState}
        setData={setObjectDownloadCsvState}
        visible={dialogDownloadCsv}
        setVisible={setDialogDownloadCsv}
        refresh={refresh}
      />
      <UserConfirmTriggerDialog
        data={objectConfirmTriggerState}
        setData={setConfirmTriggerState}
        visible={dialogConfirmTrigger}
        setVisible={setDialogConfirmTrigger}
        refresh={refresh}
      />
      <UserDeleteFileDialog
        data={objectDeleteState}
        setData={setObjectDeleteState}
        visible={dialogDeleteFile}
        setVisible={setDialogDeleteFile}
        refresh={refresh}
      />
      <UserDownloadSourceDialog
        data={objectDownloadSourceState}
        setData={setObjectDownloadSourceState}
        visible={dialogDownloadSource}
        setVisible={setDialogDownloadSource}
        refresh={refresh}
      />
      <FailedCaseDialog
        data={objectFailState}
        setData={setObjectFailState}
        visible={dialogFailState}
        setVisible={setDialogFailState}
      />

      <TotalDetailDialog
        visible={dialogTotalPagesDetail}
        setVisible={setDialogTotalPagesDetail}
        title_name="Total Pages Detail"
        user_id=" "
        refresh={refresh}
      />

      <ConfirmDialog />
      <SearchDialog
        visible={dialogSearch}
        setVisible={setSearchDialog}
        filter={filter}
        setFilter={setFilter}
      />

      <div className="grid grid-nogutter">
        <div className="col">
          <div className="mt-2 mb-4 font-bold text-v1">
            Document Upload System
          </div>
          <div className="grid p-0" style={{ flexWrap: "inherit" }}>
            <div
              className="surface-0 p-3 custom-border "
              style={{ height: "96px", width: "522px", marginLeft: "9px" }}
            >
              <div className="flex mb-1">
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "3.5rem", height: "4rem" }}
                >
                  <img
                    src={require("../../assets/File_dock_add.png")}
                    alt="filedockadd"
                  />
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <span className="block gable-text-dark-blue text-v1 font-bold">
                    Upload File
                  </span>
                </div>
                <div>
                  <Button
                    className="p-button p-button-rounded text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700 "
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "88px",
                      marginLeft: "11.4rem",
                      marginTop: "2rem",
                    }}
                    onClick={() => setDialogUpload(true)}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>

            <div
              className="surface-0 p-3 custom-border mx-3"
              style={{ height: "96px", width: "522px" }}
            >
              <div className="flex mb-1">
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "3.5rem", height: "4rem" }}
                >
                  <img
                    src={require("../../assets/File_dock.png")}
                    alt="filedock"
                  />
                </div>

                <div style={{ textAlign: "right", marginLeft: "10rem" }}>
                  <span className="block gable-text-dark-blue text-v1 font-bold">
                    TOTAL PAGES
                  </span>
                  <span className="gable-text-dark-blue font-score font-bold">
                    {!!metric.total ? metric.total : 0}
                  </span>
                </div>
                <div>
                  <Button
                    className="p-button p-button-rounded text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700 "
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "88px",
                      marginLeft: "1rem",
                      marginTop: "2rem",
                    }}
                    onClick={() => setDialogTotalPagesDetail(true)}
                  >
                    View
                  </Button>
                </div>
              </div>
            </div>

            <div
              className="surface-0 p-3 custom-border"
              style={{ height: "96px", width: "522px" }}
            >
              <div className="flex  mb-1">
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "3.5rem", height: "4rem" }}
                >
                  <img
                    src={require("../../assets/Group 47.png")}
                    alt="filedock"
                  />
                </div>
                <div style={{ textAlign: "right", marginLeft: "6rem" }}>
                  <span className="block gable-text-dark-blue text-v1 font-bold">
                    TOTAL PAGES OF THIS MONTH
                  </span>
                  <span className="gable-text-dark-blue font-score font-bold">
                    {!!metric.this_month ? metric.this_month : 0}
                  </span>
                </div>
                <div></div>
              </div>
            </div>
          </div>

          <Card style={{ marginTop: "1rem" }}>
            <div className="w-full relative flex" style={{ height: "48px" }}>
              <span
                className="text-v1 flex align-items-center justify-content-center text-v1 font-bold"
                style={{ paddingLeft: "0.5rem" }}
              >
                Total list
              </span>
              <span
                className="absolute hidden md:flex p-input-icon-right"
                style={{ right: "1rem", top: "5px" }}
              >
                <InputText
                  className="w-25rem custom-search-box text-v3"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  placeholder="  Keyword Search..."
                />
                <i className="pi pi-search gable-text-dark-blue" />
              </span>
              <Button
                icon="mdi mdi-magnify"
                style={{ right: "1rem", top: "5px" }}
                className="absolute p-button-rounded p-button-secondary flex md:hidden "
                onClick={() => {
                  setSearchDialog(true);
                }}
              />
            </div>
            <DataTable
              className="text-v2"
              value={data}
              lazy
              loading={loading}
              responsiveLayout="scroll"
              paginator
              first={lazyParams.first}
              rows={lazyParams.rows}
              totalRecords={totalRecords}
              onPage={(e: any) => {
                setLazyParams(e);
              }}
              paginatorTemplate={paginatorTemplate}
              paginatorClassName="justify-content-end"
            >
              <Column
                header="Timestamp"
                field="updated_at"
                style={{
                  minWidth: "200px",
                  height: "55px",
                }}
                body={(item) => (
                  <>{moment(item.updated_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                )}
              ></Column>
              <Column
                style={{
                  minWidth: "200px",
                  height: "55px",
                }}
                header="Name"
                field="name"
                className="cursor-pointer"
              ></Column>

              <Column
                field="detail"
                header="Detail"
                body={(item: ModelsUpload) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <b>Document Type: </b>{" "}
                      <Tag style={{ marginLeft: "0.5rem" }}>
                        {!item.doc_mode ? "formatted" : item.doc_mode}
                      </Tag>
                    </div>
                    {!!item.doc_mode && item.doc_mode !== "" && (
                      <>
                        <div className="mt-2">
                          <b>Header Columns: </b>
                          <div>
                            {item.predefined_document_ai?.fields
                              ?.split(",")
                              .map((item: any, index: number) => {
                                let split = item.split(":");
                                return (
                                  <Tag
                                    style={{
                                      background: "#fff",
                                      color: "#222",
                                      fontWeight: "normal",
                                      border: "1px solid #777",
                                      marginLeft: index > 0 ? "0.1rem" : 0,
                                    }}
                                  >
                                    {split[1].trim() === "" ? (
                                      <>&nbsp;</>
                                    ) : (
                                      <>
                                        <b>{split[1]}</b> ({split[0]})
                                      </>
                                    )}
                                  </Tag>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    )}
                    {!item.doc_mode && (
                      <>
                        <div className="mt-2">
                          <b>Transport: </b> {item.ship_type?.name}
                        </div>
                        <div>
                          <b>Type: </b> {item.mode?.name}
                        </div>
                        <div>
                          <b>Input/Output: </b> {item.in_format?.name} /{" "}
                          {item.out_format?.name}
                        </div>
                      </>
                    )}
                  </>
                )}
                style={{
                  minWidth: "180px",
                  height: "55px",
                }}
              ></Column>
              <Column
                header={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <span style={{ marginRight: "5px" }}>Page</span>
                    </div>
                    <div style={{ width: "7rem" }}>
                      <span>(Data/No data)</span>
                    </div>
                  </div>
                }
                body={(item: any) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginLeft: "1.5rem" }}>
                      {item.page_charge} / {item.page_charge_skip}
                    </span>
                  </div>
                )}
              />
              <Column
                header="Status"
                alignHeader={"center"}
                style={{
                  width: "205px",
                  height: "55px",
                }}
                body={(item) => (
                  <>
                    {item.status === "pending" && (
                      <div
                        className="status-pending text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-clock"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">Pending</span>
                      </div>
                    )}
                    {item.status === "robot_working" && (
                      <div
                        className="status-robot-working text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">Working</span>
                      </div>
                    )}
                    {item.status === "incompleted" && (
                      <div
                        className="status-incompleted text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-check"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">
                          Incompleted{" "}
                          {!!item.remark && (
                            <span
                              className="ml-2 bg-gray-50 px-2 cursor-pointer text-sm border-round-xl text-gray-500 transition-duration-200 hover:bg-red-100"
                              onClick={() => {
                                setObjectFailState(item);
                                setDialogFailState(true);
                              }}
                            >
                              More
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                    {item.status === "failed" && (
                      <div
                        className="status-failed text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-times-circle"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">
                          {!!item.remark ? "Failed " : "Process Failed"}
                          {!!item.remark && (
                            <span
                              style={{ marginLeft: "55px" }}
                              className="bg-gray-50 px-2 cursor-pointer text-sm border-round-xl text-gray-500 transition-duration-200 hover:bg-red-100"
                              onClick={() => {
                                setObjectFailState(item);
                                setDialogFailState(true);
                              }}
                            >
                              More
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                    {item.status === "completed" && (
                      <div
                        className="status-success-user text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-check-circle"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">Completed</span>
                      </div>
                    )}
                  </>
                )}
              />
              <Column
                style={{
                  minWidth: "460px",
                }}
                header="Download Operation"
                body={(item: ModelsUpload) => (
                  <div className="text-center text-v2-14px">
                    {item.status === "pending" && (
                      <div className="text-left">
                        <Tooltip target=".copy-btn" />
                        <span
                          onClick={() => {
                            copy(item.id!);
                          }}
                          className="ml-2 copy-btn text-lg border-circle gable-text-orange hover:bg-orange-400 hover:text-white transition-duration-300 cursor-pointer border-1 px-1"
                          data-pr-tooltip="Copy to clipboard"
                        >
                          <i className="mdi mdi-clipboard-text"></i>
                        </span>
                        <span
                          className={`ml-2 attached text-white px-3 py-1 ${!!item.files
                            ? "cursor-pointer hover:bg-gray-200"
                            : ""
                            }`}
                          onClick={() => {
                            if (!!item.files) {
                              setObjectDeleteState(item);
                            }
                          }}
                        >
                          {!!item.files && (
                            <>
                              Attached {item.files.length}{" "}
                              {item.files.length > 1 ? "files" : "file"}
                            </>
                          )}

                          {!item.files && <>No attachments</>}
                        </span>
                        <span
                          className={`ml-1 start-robot transition-duration-200 ${!!item.files
                            ? "bg-primary cursor-pointer hover:bg-primary-300"
                            : "bg-blue-300"
                            }  px-4 py-1`}
                          onClick={() => {
                            if (!!item.files) {
                              setConfirmTriggerState(item);
                            }
                          }}
                        >
                          Start
                        </span>
                        <span
                          className=" ml-1 bg-indigo-500 transition-duration-200 cursor-pointer hover:bg-cyan-300 add-file px-5 py-1"
                          onClick={() => {
                            setObjectAddFileState(item);
                          }}
                        >
                          Add File
                        </span>
                      </div>
                    )}
                    {(item.status === "completed" ||
                      item.status === "incompleted") && (
                        <div>
                          <div className="text-left">
                            <Tooltip target=".copy-btn" />
                            <span
                              onClick={() => {
                                copy(item.id!);
                              }}
                              className="ml-2 copy-btn text-lg border-circle gable-text-orange hover:bg-orange-400 hover:text-white transition-duration-300 cursor-pointer border-1 px-1"
                              data-pr-tooltip="Copy to clipboard"
                            >
                              <i className="mdi mdi-clipboard-text"></i>
                            </span>
                            {!!item.csv_files && (
                              <div
                                className={`ml-2 transition-duration-200 px-3 py-1 cursor-pointer inline-block text-center ${!!item.csv_files && item.csv_files.length > 0
                                  ? "download-border download-background cursor-pointer hover:bg-orange-100 "
                                  : "download-border download-background bg-gray-300 "
                                  }`}
                                style={{
                                  width: "152.5px",
                                }}
                                onClick={() => {
                                  if (
                                    !!item.csv_files &&
                                    item.csv_files.length > 0
                                  )
                                    setObjectDownloadCsvState(item);
                                }}
                              >
                                CSV (
                                {!!item.csv_files ? item.csv_files.length : 0}{" "}
                                {!!item.csv_files && item.csv_files.length > 1
                                  ? "files"
                                  : "file"}
                                )
                              </div>
                            )}
                            <div
                              className={`ml-2 transition-duration-200 px-3 cursor-pointer py-1 inline-block text-center ${!!item.csv_files && item.csv_files.length > 0
                                ? "download-border download-background cursor-pointer hover:bg-orange-100 "
                                : "download-border download-background bg-gray-300 "
                                }`}
                              style={{
                                width: "152.5px",
                              }}
                              onClick={() => {
                                if (!!item.files && item.files!.length > 0)
                                  setObjectDownloadSourceState(item);
                              }}
                            >
                              Source ({!!item.files ? item.files.length : 0}{" "}
                              {!!item.files && item.files.length > 1
                                ? "files"
                                : "file"}
                              )
                            </div>
                            {/* <div className="text-left">
                              <span
                                className="ml-2 transition-duration-200 px-3 cursor-pointer py-1 inline-block text-center attached hover:bg-gray-200"
                                onClick={() => {
                                  setConfirmTriggerState(item);
                                }}
                                style={{
                                  width: "152.5px",
                                }}
                              >
                                Reset status
                              </span>
                            </div> */}
                          </div>
                        </div>
                      )}

                    {item.status === "failed" && (
                      <div>
                        <div className="text-left">
                          <Tooltip target=".copy-btn" />
                          <span
                            onClick={() => {
                              copy(item.id!);
                            }}
                            className="ml-2 copy-btn text-lg border-circle gable-text-orange hover:bg-orange-400 hover:text-white transition-duration-300 cursor-pointer border-1 px-1"
                            data-pr-tooltip="Copy to clipboard"
                          >
                            <i className="mdi mdi-clipboard-text"></i>
                          </span>
                          <span
                            className="ml-2 transition-duration-200 px-3 cursor-pointer py-1 inline-block text-center attached hover:bg-gray-200"
                            onClick={() => {
                              setConfirmTriggerState(item);
                            }}
                            style={{
                              width: "152.5px",
                            }}
                          >
                            Reset status
                          </span>
                          {/* <div
                            className={`ml-2 transition-duration-200 px-3 py-1 inline-block text-center download-border download-background cursor-pointer hover:bg-orange-100`}
                            style={{
                              width: "152.5px",
                            }}
                            onClick={() => {
                              setUploadData(item)
                              setDeleteDialog(true)
                            }}
                          >
                            Need support
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              ></Column>
            </DataTable>
          </Card>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
