import { useState, Fragment, useEffect } from "react";
import { Divider } from 'primereact/divider';
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { ModelsInputFormat, ModelsShipType } from "../../api";
import './style.css';
import { useSecureApiConnector } from "../../utils/ApiConnector";
import {
    useToast,
    usePageWaiting,
} from "../../utils/Properties";
import { InputSwitch } from 'primereact/inputswitch';
import CustomEnableDialog from "../../components/OrgManagement/CustomEnableDialog";
import moment from "moment";

const AdminTemplatManagement = () => {
    const properties = {
        title: "Template Enablement",
    };
    const [successToast, errorToast] = useToast();
    const [loading, setLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const apiInstance = useSecureApiConnector()
    const setPageWaiting = usePageWaiting()
    const [shiptypeList, setShiptypeList] = useState<ModelsShipType[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [checked1, setChecked1] = useState(false);
    const [updateEnable, setUpdateEnable] = useState({} as ModelsInputFormat)
    const [confirmEnable, setConfirmEnable] = useState(false)
    const [objectEnable, setEnable] = useState({} as any)
    const [data, setData] = useState([] as ModelsInputFormat[]);
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(10);

    const onCustomPage2 = (event: any) => {
        setFirst2(event.first);
        setRows2(event.rows);
    }

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const fetchData = () => {
        apiInstance.facilities.getAllShipType().then((res) => res.data)
            .then((data) => {
                setShiptypeList(data.record_list);

            })
            .catch(() => { });
    }

    const refresh = () => {

        setTriggerFetch((triggerFetch) => !triggerFetch);
    };


    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },


    };

    const onShipTypeChange = (e: { value: any }) => {

        setSelectedItem(e.value);
        fetchInputFormat(e.value)
    }
    const onSubmitEnableOrDisable = async (data: any) => {
        setPageWaiting(true)
        updateEnable.enable = checked1
        setConfirmEnable(false)
        await apiInstance.inputFormat.updateEnable(updateEnable)
            .then(() => { successToast(`${checked1 ? "enable" : "disable"} success`); fetchInputFormat(selectedItem) })
            .catch(() => { errorToast(`${checked1 ? "enable" : "disable"} failed`); setPageWaiting(false) })
        setPageWaiting(false)
    }

    const fetchInputFormat = async (data: any) => {
        setPageWaiting(true)
        let inputFormatId = ""
        if (data === undefined) {
            inputFormatId = ""
        } else {
            inputFormatId = data.id
        }

        apiInstance.facilities.getAllInputFormatByShipType(inputFormatId).then((response) => response.data)
            .then((data2) => {
                setData(data2.record_list);
                setTotalRecords(data2.record_count!);
                setPageWaiting(false)

            })
    }

    useEffect(() => {
        fetchData();
    }, [triggerFetch]);


    return (
        <>
            <CustomEnableDialog data={objectEnable} setData={setEnable} visible={confirmEnable} setVisible={setConfirmEnable} refresh={refresh}
                onSubmit={onSubmitEnableOrDisable} meessage={<>Confirm to {checked1 ? "enable" : "disable"} this&nbsp;template? </>} customTitle={`${checked1 ? "Enable" : "Disable"} CONFIRMATION`} />
            <div className="grid grid-nogutter">
                <div className="col">
                    <div className="mt-2 mb-4 font-bold text-v1">
                        {properties.title}
                    </div>
                    <Divider />

                    <Card style={{ marginTop: "1rem" }}>

                        <div className="w-full relative flex" style={{ height: "48px" }}>
                            <div className="dropdown-demo">
                                <Dropdown value={selectedItem} options={shiptypeList} onChange={onShipTypeChange} optionLabel="name" filter showClear filterBy="name" placeholder="Select a shiptype" />

                            </div>

                        </div>

                        <DataTable
                            value={data}
                            loading={loading}
                            paginator
                            responsiveLayout="scroll"
                            first={first2}
                            rows={rows2}
                            totalRecords={totalRecords}
                            onPage={(e: any) => {
                                onCustomPage2(e)
                            }}
                            paginatorTemplate={paginatorTemplate}
                            paginatorClassName="justify-content-end"
                            tableStyle={{ minWidth: "100%" }}
                        >
                            <Column
                                field="created_at"
                                header="Timestamp"
                                style={{ width: "35%" }}
                                body={(item: ModelsInputFormat) => (
                                    <>{moment(item.created_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                                )}
                            ></Column>
                            <Column
                                field="name"
                                header="Name"
                                style={{ width: "35%" }}
                            ></Column>
                            <Column header="Status" body={(item: ModelsInputFormat) => (<>
                                <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.enable ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                            </>)}></Column>
                            <Column className='text-right' body={(item) => (
                                <span className="p-buttonset flex">
                                    <div
                                        className="align-items-center justify-content-center toggle-demo"
                                        style={{ width: "3.5rem" }}
                                    >
                                        <InputSwitch checked={item.enable} onChange={(e) => { setChecked1(e.value); setUpdateEnable(item); setConfirmEnable(true) }} />
                                    </div>
                                </span>

                            )}></Column>
                        </DataTable>
                    </Card>
                </div>
            </div>
        </>




    )
}
export default AdminTemplatManagement;
