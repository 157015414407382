import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory, ModelsUser } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Checkbox } from 'primereact/checkbox'
import "./style.css"
import { RadioButton } from "primereact/radiobutton"

interface props {
    data: ModelsUser
    setData: Dispatch<SetStateAction<ModelsUser>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
}

const EditDirectoryDialog: FC<props> = ({ data, setData, visible, setVisible, refresh }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Edit Directory"
    const [successToast, errorToast, ,] = useToast();
    const setPageWaiting = usePageWaiting()
    const [directories, setDirectories] = useState([] as ModelsDirectory[])

    const defaultValues = {
        first_name: '',
        last_name: '',
        personal_id: '',
        tel_number: '',
        email: '',
        role: 'user',
        enable: false,
        directory: null,
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm({ defaultValues });

    const onSubmit = (submitData: any) => {
        setPageWaiting(true)
        apiInstance.users.updateUser({
            ...data,
            first_name: submitData.first_name,
            last_name: submitData.last_name,
            enable: submitData.enable,
            directory: undefined,
            directory_id: submitData.directory.id,
            personal_id: submitData.personal_id,
            tel_number: submitData.tel_number,
            role: {
                name: submitData.role
            }
        })
            .then(() => {
                successToast("user updated")
                setData({})
                setVisible(false)
                reset()
                refresh()
                setPageWaiting(false)
            })
            .catch(() => {
                errorToast("user update failed")
                setPageWaiting(false)
            })
    };

    useEffect(() => {
        if (data) {
            setValue("first_name", data.first_name!)
            setValue("last_name", data.last_name!)
            setValue("email", data.email!)
            setValue("enable", data.enable!)
            setValue("directory", (data.directory! as any))
            setValue("personal_id", data.personal_id!)
            setValue("tel_number", data.tel_number!)
            if (data.role) {
                setValue("role", data.role!.name!)
            }

        }
    }, [data])
    useEffect(() => {
        if (visible) {
            apiInstance.directories.getDirectoryAll()
                .then(res => res.data)
                .then((data) => {
                    setDirectories(data.record_list)
                })
        }
    }, [visible])

    return (
        <Dialog closable={false} draggable={false}
            visible={visible}
            className="custom-dialog p-0 m-0 w-23rem md:w-30rem dialog-width"
            showHeader={false}
            onHide={() => {
                setVisible(false)
                setData({})
                reset()
            }}
        >
            <div className="w-full text-v3 font-bold py-2 px-4" style={{marginTop:'2rem'}}>
                <div className="grid grid-nogutter add-branch">
                    <div className="col-10 hidden md:flex relative">
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-10 flex md:hidden relative" style={{ top: '4px' }}>
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-2 text-right">
                        <Badge value="X"
                            className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                            severity="danger"
                            onClick={() => {
                                setVisible(false)
                                setData({})
                                reset()
                            }}
                            style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                        ></Badge>
                    </div>
                </div>
            </div>
            <div className="py-2 px-4 w-full text-v2 font-bold">

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid ">
                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">First Name<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="first_name" control={control} rules={{
                                required: 'First Name is required.',
                                pattern: {
                                    value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Example, ตัวอย่าง'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field} autoFocus
                                    className={`${!!errors.first_name ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                        <div className="flex-initial align-items-center justify-content-center px-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Last Name<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="last_name" control={control} rules={{
                                required: 'Last Name is required.',
                                pattern: {
                                    value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Example, ตัวอย่าง'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}
                                    className={`${!!errors.last_name ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                    </div>

                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Employee ID<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="personal_id" control={control} rules={{
                                required: 'Employee ID is required.',

                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}
                                    className={`${!!errors.personal_id ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                        <div className="flex-initial align-items-center justify-content-center px-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Email<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="email" control={control} rules={{
                                required: 'Email is required.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address. E.g. example@g-able.com'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}

                                    className={`${!!errors.email ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>

                    </div>

                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Tel Number</p>
                            <Controller name="tel_number" control={control} rules={{
                                pattern: {
                                    value: /^0[23457]{1}[0-9]{7}|0[689]{1}[0-9]{8}$/i, message: 'Invalid Tel Number',
                                },

                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}
                                    className={`${!!errors.tel_number ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                        <div className="flex-initial align-items-center justify-content-center px-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Branch<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="directory" control={control}
                                rules={{
                                    required: 'Branch is required.',
                                }} render={({ field }) => (
                                    <Dropdown id={field.name} {...field}
                                        options={directories} onChange={(e: { value: any }) => {
                                            setValue("directory", e.value);
                                        }}
                                        className={`${!!errors.directory ? 'p-invalid' : ''} custom-dropdown input-border`}
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Select a Branch"
                                    />
                                )} /></div>

                    </div>

                    <p className="mb-2 mt-2 font-bold text-sm">Role<span style={{ color: 'red' }}>*</span></p>
                    <Controller name="role" control={control} render={({ field }) => (
                        <>
                            <RadioButton id="adm" name="role" value="administrator" onChange={field.onChange} checked={field.value === 'administrator'} />
                            <label className="mx-2" htmlFor="adm">Administrator</label>

                            <RadioButton id="usr" name="role" value="user" onChange={field.onChange} checked={field.value === 'user'} />
                            <label className="mx-2" htmlFor="usr">User</label>
                        </>
                    )} />

                    <p className="mb-2 mt-3">
                        <Controller name="enable" control={control}
                            render={({ field }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value}
                                    className={!!errors.enable ? 'p-invalid' : ''} />
                            )} />
                        <label htmlFor="accept" className={!!errors.enable ? 'p-invalid' : ''}> User account enable</label>
                    </p>


                    {(errors.first_name || errors.last_name || errors.email || errors.directory || errors.enable || errors.personal_id || errors.role || errors.tel_number) &&
                        <div className="pl-2 mt-2">
                            <p className="p-error font-bold m-0">Hint</p>
                            <p className="m-0 p-error">{errors.first_name ? <small>{errors.first_name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.last_name ? <small>{errors.last_name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.email ? <small>{errors.email.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.directory ? <small>{errors.directory.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.enable ? <small>{errors.enable.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.personal_id ? <small>{errors.personal_id.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.tel_number ? <small>{errors.tel_number.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.role ? <small>{errors.role.message}</small> : <></>}</p>
                        </div>
                    }

                    <Button
                        label="Submit"
                        type="submit"
                        className="p-button mt-3 transition-duration-300 hover:bg-indigo-700"
                        style={{ borderRadius: '30px', width: '194px', marginLeft: '43rem', background: '#292666' ,marginBottom:'2rem'}}

                    />  
                </form>
            </div>
        </Dialog>
    )
}

export default EditDirectoryDialog;