import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  DocumentAIType,
  ModelsDirectory,
  ModelsInputFormat,
  ModelsOutputFormat,
  ModelsShipType,
  ModelsUpload,
  PredefinedDocumentAI,
} from "../../api";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import { useClipboard } from "../../utils/CopyToClipboard";
import { RadioButton } from "primereact/radiobutton";
import {
  FileUpload,
  FileUploadHandlerParam,
  FileUploadRemoveParams,
} from "primereact/fileupload";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DOCUMENT_AI_INVOICE_FIELDS } from "../../constants/upload";
import { OverlayPanel } from "primereact/overlaypanel";
import { TabView, TabPanel } from "primereact/tabview";

interface props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  doc_ai?: boolean;
}

const UserUploadDialog: FC<props> = ({ visible, setVisible, refresh, doc_ai }) => {
  const apiInstance = useSecureApiConnector();
  const title = "Document Upload";
  const [uploadMode, setUploadMode] = useState("");
  const [successToast, errorToast, , warnToast] = useToast();
  const setPageWaiting = usePageWaiting();
  const [shipTypes, setShipTypes] = useState([] as ModelsShipType[]);
  const [inputFormats, setInputFormats] = useState([] as ModelsInputFormat[]);
  const [outputFormats, setOutputFormats] = useState(
    [] as ModelsOutputFormat[]
  );
  const fileUploadRef = useRef(null);
  const [allFile, setAllFile] = useState([] as File[]);
  const chooseOptions = {
    icon: "",
    iconOnly: false,
    className:
      "custom-choose-btn p-button-rounded p-button-outlined browse-custom",
  };

  // MB
  const mb = 1000000;
  const maxUpload = 20 * mb;
  const [totalSize, setTotalSize] = useState(0);
  const getSize = (s: number) => s / mb;

  const defaultValues = {
    name: "",
    ship_type: null,
    inputFormat: null,
    outputFormat: null,
    mode: "Import",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm({ defaultValues });

  const submitDocumentAIInvoice = () => {
    if (totalSize === 0) {
      warnToast("Please upload file");
    } else if (choseDocTypeFields.id === undefined) {
      warnToast("Please select pre-built columns");
    } else {
      if (totalSize > maxUpload) {
        errorToast("File upload size exceded");
      } else {
        if (!watch().name) {
          warnToast("Please enter upload name");
        } else {
          setVisible(false);
          setPageWaiting(true);
          let prepared_fields: string[] = [];
          selectedDocAiInvoiceFields.forEach((item) => {
            prepared_fields.push(item.Name);
          });

          apiInstance.uploads
            .create_with_doc_mode(
              watch().name,
              "document-ai-invoice",
              choseDocTypeFields.id!,
              allFile
            )
            .then((res) => res.data)
            .then((res) => {
              if (!!res.have_duplicated && res.have_duplicated) {
                if (res.file_name) {
                  res.file_name.forEach((e) => {
                    errorToast(`duplicated filename ${e}`);
                  });
                }
              } else {
                successToast("File uploaded");
              }

              setChoseDocTypeFields({});
              setSelectedDocAiInvoiceFields([]);
              setAllFile([]);
              setTotalSize(0);
              reset();
            })
            .catch(() => {
              errorToast("File upload failed");
            })
            .finally(() => {
              setPageWaiting(false);
            });
        }
      }
    }
  };

  const onSubmit = (data: any) => {
    if (totalSize === 0) {
      warnToast("Please upload file");
    } else {
      if (totalSize > maxUpload) {
        errorToast("File upload size exceded");
      } else {
        setVisible(false);
        setPageWaiting(true);
        apiInstance.uploads
          .create(
            data.name,
            data.inputFormat.input_format,
            data.outputFormat.output_format,
            data.ship_type,
            data.mode,
            allFile
          )
          .then((res) => res.data)
          .then((res) => {
            if (!!res.have_duplicated && res.have_duplicated) {
              if (res.file_name) {
                res.file_name.forEach((e) => {
                  errorToast(`duplicated filename ${e}`);
                });
              }
            } else {
              successToast("File uploaded");
            }

            setAllFile([]);
            setTotalSize(0);
            reset();
            refresh();
          })
          .catch(() => {
            errorToast("File upload failed");
          })
          .finally(() => {
            setPageWaiting(false);
          });
      }
    }
  };
  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? (fileUploadRef.current as any).formatSize(totalSize)
        : "0 B";
    return (
      <div
        className={`${className} header-file-upload`}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        <div className="w-full srelative font-bold text-right">
          <span
            className={
              getSize(totalSize) < getSize(maxUpload)
                ? "text-green-700"
                : "text-red-600"
            }
          >
            {formatedValue}
          </span>
          /{getSize(maxUpload)}MB
        </div>
      </div>
    );
  };
  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "1.5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span style={{ color: "var(--text-color-secondary)" }} className="my-3">
          Drag and Drop Here
        </span>
      </div>
    );
  };
  const customUploader = (e: FileUploadHandlerParam) => {
    setAllFile(e.files);

    let total = 0;
    e.files.forEach((f) => {
      total += f.size;
    });
    setTotalSize(total);
  };

  const getInputFormatByShipType = async (value: any) => {
    // console.log("aaaaa");
    setValue("ship_type", value);
    if (value) {
      await apiInstance.facilities
        .getInputFormatByShipType(value.id)
        .then((res) => res.data)
        .then((data) => {
          // console.log(data.record_list);
          setInputFormats(data.record_list);
        });
    } else {
      setInputFormats([]);
    }

    // console.log("inputFormats" + inputFormats);
  };

  const [docAiTypes, setDocAiTypes] = useState([] as DocumentAIType[]);

  useEffect(() => {
    if (visible) {
      // apiInstance.facilities.getAllInputFormat()
      //     .then(res => res.data)
      //     .then((data) => {
      //         setInputFormats(data.record_list)
      //         console.log(data.record_list);

      //     })

      setInputFormats([]);
      apiInstance.facilities
        .getAllOutputFormat()
        .then((res) => res.data)
        .then((data) => {
          setOutputFormats(data.record_list);
        });
      apiInstance.facilities
        .getAllShipType()
        .then((res) => res.data)
        .then((data) => {
          setShipTypes(data.record_list);
        });

      setDocAiTypes([]);
      apiInstance.documentAi
        .getType()
        .then((res) => res.data)
        .then((data) => {
          setDocAiTypes(data);
        });
    }
  }, [visible]);

  const [fieldsDocAiInvoiceFields, setFieldsDocAiInvoiceFields] = useState([]);
  const [selectedDocAiInvoiceFields, setSelectedDocAiInvoiceFields] = useState(
    [] as any[]
  );

  const [choseDocTypeFields, setChoseDocTypeFields] =
    useState<PredefinedDocumentAI>({});

  const [activeDocAIIndex, setActiveDocAIIndex] = useState(-1);

  useEffect(() => {
    if (activeDocAIIndex >= 0) {
      apiInstance.documentAi
        .getPredefined(0, -1, docAiTypes[activeDocAIIndex].id!)
        .then((res) => res.data)
        .then((data) => {
          setFieldsDocAiInvoiceFields(data.record_list);
        })
        .catch((e) => {
          console.log(e);
          // navigate("/forbidden")
        });
    }
  }, [activeDocAIIndex]);

  return (
    <>
      <Dialog
        closable={false}
        draggable={false}
        visible={visible}
        className="custom-dialog p-0 m-0 w-50rem md:w-30rem dialog-width"
        showHeader={false}
        onHide={() => {
          setVisible(false);
          setAllFile([]);
          setTotalSize(0);
          setUploadMode("");
          setChoseDocTypeFields({});
          setSelectedDocAiInvoiceFields([]);
          reset();
        }}
      >
        <div
          className="w-full text-v3 font-bold py-2 px-4"
          style={{ marginTop: "2rem" }}
        >
          <div className="grid grid-nogutter add-branch">
            <div className="col-10 hidden md:flex relative">
              {uploadMode === "" ? "Select upload mode" : title}
            </div>
            <div
              className="col-10 flex md:hidden relative"
              style={{ top: "4px" }}
            >
              {uploadMode === "" ? "Select upload mode" : title}
            </div>
            <div className="col-2 text-right">
              <Badge
                value="X"
                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                severity="danger"
                onClick={() => {
                  setVisible(false);
                  setAllFile([]);
                  setTotalSize(0);
                  setUploadMode("");
                  setChoseDocTypeFields({});
                  setSelectedDocAiInvoiceFields([]);
                  reset();
                }}
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.5rem",
                  background: "#A098AE",
                }}
              ></Badge>
            </div>
          </div>
        </div>
        {uploadMode === "" && (
          <>
            <div
              className="w-full text-v3 font-bold py-2 px-4"
              style={{ margin: "2rem 0", padding: "3rem", display: "flex" }}
            >
              <div
                className="select-upload-mode"
                style={{
                  height: "100px",
                  width: "100%",
                  margin: "0.25rem",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexFlow: "column",
                }}
                onClick={() => {
                  setUploadMode("template");
                }}
              >
                <div>Formatted Document</div>
                <div style={{ fontSize: "13px", fontWeight: "normal" }}>
                  Document with input/output format
                </div>
              </div>
              {!!doc_ai && (
                <div
                  className="select-upload-mode"
                  style={{
                    height: "100px",
                    width: "100%",
                    margin: "0.25rem",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexFlow: "column",
                  }}
                  onClick={() => {
                    setUploadMode("document-ai-invoice");
                  }}
                >
                  <div>Document AI for invoice</div>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Use pre-built fields to extract data
                  </div>
                </div>
              )}

            </div>
          </>
        )}

        {uploadMode === "template" && (
          <>
            <div className="py-2 px-4 w-full text-v2 font-bold">
              <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="grid no-gutter">
                  <div className="col-6">
                    <div className="flex">
                      <div className="flex-initial align-items-center justify-content-center pr-5">
                        <p className="mb-2 mt-2 font-bold text-sm">Name</p>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: "Name is required.",
                          }}
                          render={({ field }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              className={`${!!errors.name ? "p-invalid" : ""
                                } input-bordered`}
                              style={{ width: "400px" }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-initial align-items-center justify-content-center pr-5">
                        <p className="mb-2 mt-/ font-bold text-sm">Transport</p>
                        <Controller
                          name="ship_type"
                          control={control}
                          rules={{
                            required: "Transport is required.",
                          }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              {...field}
                              options={shipTypes}
                              onChange={(e: { value: any }) => {
                                // setValue("ship_type", e.value);
                                getInputFormatByShipType(e.value);
                              }}
                              className={`${!!errors.ship_type ? "p-invalid" : ""
                                } input-bordered`}
                              optionLabel="name"
                              filter
                              showClear
                              filterBy="name"
                              placeholder="Select a transport"
                              style={{ width: "400px" }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    {watch().ship_type && (
                      <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5">
                          <p className="mb-2 mt-/ font-bold text-sm">
                            Input Format
                          </p>
                          <Controller
                            name="inputFormat"
                            control={control}
                            rules={{
                              required: "Input format is required.",
                            }}
                            render={({ field }) => (
                              <Dropdown
                                id={field.name}
                                {...field}
                                options={inputFormats}
                                onChange={(e: { value: any }) => {
                                  setValue("inputFormat", e.value);
                                }}
                                className={`${!!errors.inputFormat ? "p-invalid" : ""
                                  } input-bordered`}
                                optionLabel="input_format.name"
                                filter
                                showClear
                                filterBy="input_format.name"
                                placeholder="Select a input format"
                                style={{ width: "400px" }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <div className="flex">
                      <div className="flex-initial align-items-center justify-content-center pr-5">
                        <p className="mb-2 mt-/ font-bold text-sm">
                          Output Format
                        </p>
                        <Controller
                          name="outputFormat"
                          control={control}
                          rules={{
                            required: "Output format is required.",
                          }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              {...field}
                              options={outputFormats}
                              onChange={(e: { value: any }) => {
                                setValue("outputFormat", e.value);
                              }}
                              className={`${!!errors.inputFormat ? "p-invalid" : ""
                                } input-bordered`}
                              optionLabel="output_format.name"
                              filter
                              showClear
                              filterBy="name"
                              placeholder="Select a output format"
                              style={{ width: "400px" }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <p className="mb-2 mt-2 font-bold text-sm">Type</p>
                      <Controller
                        name="mode"
                        control={control}
                        render={({ field }) => (
                          <>
                            <RadioButton
                              id="im"
                              name="mode"
                              value="Import"
                              onChange={field.onChange}
                              checked={field.value === "Import"}
                            />
                            <label className="mx-2" htmlFor="im">
                              Import
                            </label>

                            <RadioButton
                              id="ex"
                              name="mode"
                              value="Export"
                              onChange={field.onChange}
                              checked={field.value === "Export"}
                            />
                            <label className="mx-2" htmlFor="ex">
                              Export
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <p className="mb-2 mt-2 font-bold text-17 ">Files Upload</p>
                    <FileUpload
                      ref={fileUploadRef}
                      customUpload
                      multiple
                      auto
                      accept="image/*,application/pdf"
                      maxFileSize={maxUpload}
                      uploadHandler={customUploader}
                      chooseLabel="Browse"
                      chooseOptions={chooseOptions}
                      headerTemplate={headerTemplate}
                      emptyTemplate={emptyTemplate}
                      itemTemplate={(file: any, props) => {
                        return (
                          <div
                            className="flex align-items-center flex-wrap"
                            style={{ color: "#A098AE" }}
                          >
                            <div
                              className="flex align-items-center"
                              style={{ width: "40%" }}
                            >
                              {file.type !== "application/pdf" && (
                                <img
                                  alt={file.name}
                                  role="presentation"
                                  src={file.objectURL}
                                  width={100}
                                />
                              )}
                              {file.type === "application/pdf" && (
                                <i className="pi pi-file text-2xl"></i>
                              )}
                              <span className="flex flex-column text-left ml-3">
                                {file.name}
                                <small>{new Date().toLocaleDateString()}</small>
                              </span>
                            </div>
                            <Tag
                              value={props.formatSize}
                              className="px-3 py-2 "
                              style={{ color: "#A098AE", background: "white" }}
                            />
                            <div
                              className="align-items-center justify-content-center "
                              style={{ width: "3.5rem", paddingLeft: "7.5rem" }}
                            >
                              <img
                                className="transition-duration-200 cursor-pointer  hover:bg-red-500"
                                src={require("../../assets/Group 32.png")}
                                style={{ borderRadius: "20px", width: "30px" }}
                                alt="bin"
                                onClick={(e) => {
                                  setTotalSize(totalSize - file.size);
                                  props.onRemove(e);
                                }}
                              />
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>

                {(errors.name ||
                  errors.mode ||
                  errors.inputFormat ||
                  errors.outputFormat ||
                  errors.ship_type) && (
                    <div className="pl-2 mt-2">
                      <p className="p-error font-bold m-0">Hint</p>
                      <p className="m-0 p-error">
                        {errors.name ? (
                          <small>{errors.name.message}</small>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="m-0 p-error">
                        {errors.mode ? (
                          <small>{errors.mode.message}</small>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="m-0 p-error">
                        {errors.inputFormat ? (
                          <small>{errors.inputFormat.message}</small>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="m-0 p-error">
                        {errors.outputFormat ? (
                          <small>{errors.outputFormat.message}</small>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="m-0 p-error">
                        {errors.ship_type ? (
                          <small>{errors.ship_type.message}</small>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  )}

                <div className="">
                  <Button
                    label="Submit"
                    type="submit"
                    className="p-button p-button-secondary mt-3 button-style"
                  />
                </div>
              </form>
            </div>
          </>
        )}

        {uploadMode === "document-ai-invoice" && (
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "80%", margin: "1rem 0" }}>
              <p className="mb-2 mt-2 font-bold text-sm">Name</p>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required.",
                }}
                render={({ field }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={`${!!errors.name ? "p-invalid" : ""
                      } input-bordered`}
                    style={{ width: "400px" }}
                  />
                )}
              />
            </div>

            <div style={{ width: "80%", marginTop: "1rem" }}>
              <p className="mb-2 mt-2 font-bold text-sm">Files Upload</p>
              <FileUpload
                ref={fileUploadRef}
                customUpload
                multiple
                auto
                accept="image/*,application/pdf"
                maxFileSize={maxUpload}
                uploadHandler={customUploader}
                chooseLabel="Browse"
                chooseOptions={chooseOptions}
                headerTemplate={headerTemplate}
                emptyTemplate={emptyTemplate}
                itemTemplate={(file: any, props) => {
                  return (
                    <div
                      className="flex align-items-center flex-wrap"
                      style={{ color: "#A098AE" }}
                    >
                      <div
                        className="flex align-items-center"
                        style={{ width: "40%" }}
                      >
                        {file.type !== "application/pdf" && (
                          <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                          />
                        )}
                        {file.type === "application/pdf" && (
                          <i className="pi pi-file text-2xl"></i>
                        )}
                        <span className="flex flex-column text-left ml-3">
                          {file.name}
                          <small>{new Date().toLocaleDateString()}</small>
                        </span>
                      </div>
                      <Tag
                        value={props.formatSize}
                        className="px-3 py-2 "
                        style={{ color: "#A098AE", background: "white" }}
                      />
                      <div
                        className="align-items-center justify-content-center "
                        style={{
                          width: "3.5rem",
                          paddingLeft: "7.5rem",
                        }}
                      >
                        <img
                          className="transition-duration-200 cursor-pointer  hover:bg-red-500"
                          src={require("../../assets/Group 32.png")}
                          style={{ borderRadius: "20px", width: "30px" }}
                          alt="bin"
                          onClick={(e) => {
                            setTotalSize(totalSize - file.size);
                            props.onRemove(e);
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </div>
            <div style={{ width: "80%", margin: "1rem 0" }}>
              <Divider />
            </div>

            <div style={{ width: "80%", margin: "1rem 0" }}>
              <div
                className="mb-4 mt-2 font-bold text-sm"
                style={{ display: "flex", alignItems: "center" }}
              >
                Choose your pre-defined fields{" "}
                <Button
                  className="p-button-sm ml-4"
                  label="Refresh"
                  style={{ fontSize: "10px", borderRadius: "18px" }}
                  onClick={() => {
                    refresh();
                  }}
                />
              </div>

              <TabView
                activeIndex={activeDocAIIndex}
                onTabChange={(e: { index: SetStateAction<number> }) =>
                  setActiveDocAIIndex(e.index)
                }
                className="doc_ai_type_selected"
                scrollable
              >
                {docAiTypes.map((item) => (
                  <TabPanel header={item.name} key={item.id} />
                ))}
              </TabView>

              {activeDocAIIndex < 0 && (
                <div
                  className="text-center mt-3"
                  style={{ border: "1px solid #eee", padding: "3rem" }}
                >
                  Please select the document type above
                </div>
              )}

              {activeDocAIIndex >= 0 && (
                <>
                  {choseDocTypeFields.id === undefined && (
                    <>
                      <DataTable
                        value={fieldsDocAiInvoiceFields}
                        scrollable
                        scrollHeight="300px"
                        style={{ width: "100%" }}
                        responsiveLayout="scroll"
                      >
                        <Column
                          field="name"
                          header="Name"
                          style={{ maxWidth: "200px", flexFlow: "wrap" }}
                        ></Column>

                        <Column
                          field="Description"
                          header="Columns"
                          style={{ width: "600px", flexFlow: "wrap" }}
                          body={(item) => (
                            <>
                              {item.fields
                                ?.split(",")
                                .map((item: any, index: number) => {
                                  let split = item.split(":");
                                  return (
                                    <Tag
                                      style={{
                                        background: "#fff",
                                        color: "#222",
                                        fontWeight: "normal",
                                        border: "1px solid #777",
                                        marginLeft: index > 0 ? "0.1rem" : 0,
                                      }}
                                    >
                                      {split[1].trim() === "" ? (
                                        <>&nbsp;</>
                                      ) : (
                                        <>
                                          <b>{split[1]}</b> ({split[0]})
                                        </>
                                      )}
                                    </Tag>
                                  );
                                })}
                            </>
                          )}
                        ></Column>

                        <Column
                          field="Choose"
                          header=""
                          style={{ minWidth: "110px", maxWidth: "100px" }}
                          body={(item) => (
                            <Button
                              className="p-button-sm"
                              key={item.Name}
                              style={{ borderRadius: "18px" }}
                              onClick={() => {
                                setChoseDocTypeFields(item);
                              }}
                            >
                              Choose
                            </Button>
                          )}
                        ></Column>
                      </DataTable>
                    </>
                  )}

                  {!!choseDocTypeFields.id && (
                    <>
                      <div style={{ width: "80%", marginTop: "1rem" }}>
                        <Divider />
                      </div>
                      <div style={{ width: "80%", margin: "1rem 0" }}>
                        <Button
                          className="p-button-sm"
                          onClick={() => {
                            setChoseDocTypeFields({});
                          }}
                        >
                          Change pre-defined fields
                        </Button>

                        <div
                          className="mb-3 mt-4 font-bold text-sm"
                          key={`upload-column-doc-ai-preview`}
                        >
                          Pre-defined name:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {choseDocTypeFields.name}
                          </span>
                        </div>

                        <div
                          className="mb-4 mt-2 font-bold text-sm"
                          key={`upload-column-doc-ai-preview`}
                        >
                          Column header preview
                        </div>
                        <div
                          style={{
                            display: "flex",
                            overflowX: "scroll",
                            marginBottom: "1rem",
                          }}
                          key={`upload-column-doc-ai-preview-children`}
                        >
                          {choseDocTypeFields.fields
                            ?.split(",")
                            .map((item: any, index: number) => {
                              let split = item.split(":");
                              return (
                                <Tag
                                  style={{
                                    background: "#fff",
                                    color: "#222",
                                    fontWeight: "normal",
                                    border: "1px solid #777",
                                    marginLeft: index > 0 ? "0.1rem" : 0,
                                  }}
                                >
                                  {split[1].trim() === "" ? (
                                    <>&nbsp;</>
                                  ) : (
                                    <>
                                      <b>{split[1]}</b> ({split[0]})
                                    </>
                                  )}
                                </Tag>
                              );
                            })}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 0 1rem 0",
                        }}
                      >
                        <Button
                          label="Submit"
                          className="p-button p-button-secondary mt-3 button-style-without-margin"
                          onClick={() => {
                            submitDocumentAIInvoice();
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default UserUploadDialog;
