import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory, } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { useToast } from "../../utils/Properties"
import "./style.css"

interface props {
    data: any
    setData: Dispatch<SetStateAction<any>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    onSubmit: (data: any) => void;
    meessage?: any
    customTitle?: any
}

const CustomEnableDialog: FC<props> = ({ data, setData, visible, setVisible, refresh, onSubmit, meessage, customTitle }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Delete Confirmation"
    const [successToast, errorToast, ,] = useToast();
    // const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsDirectory)

    const defaultValues = {
        name: '',
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm({ defaultValues });


    return (
        <Dialog closable={false} draggable={false}
            visible={visible}
            className="custom-dialog p-0 m-0 w-36rem "
            showHeader={false}
            onHide={() => {
                setVisible(false)
                setData({})
                reset()
            }}
        >
            <div className="flex">
                <div className="w-full text-v3 font-bold py-2 px-4 ">
                    <div className="grid grid-nogutter add-branch" style={{ marginTop: '1rem' }}>
                        <div className="col-10 hidden md:flex relative" style={{ color: '#D74343' }}>
                            {customTitle ? customTitle.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10 flex md:hidden relative" style={{ top: '4px', color: '#D74343' }}>
                            {customTitle ? customTitle.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-500 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    setData({})
                                    reset()
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}
                            ></Badge>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-fluid">

                <div className="text-v2 py-2 px-4">

                    <span
                        className="flex align-items-center "
                    >
                        <i className="pi pi-info-circle gable-text-dark-blue" style={{ fontSize: '2rem', marginRight: '1rem' }} />
                        {meessage}
                    </span>

                </div>
                <div className="flex py-2 px-4">
                    <div style={{ marginLeft: '3rem', marginRight: '3rem' }}>
                        <Button
                            label="No"
                            className="p-button mb-3 transition-duration-300 hover:bg-red-500 custom-border-button-v2"
                            style={{ borderRadius: '30px', width: '194px', background: '#292666' }}
                            onClick={() => {
                                setVisible(false)
                                setData({})
                                reset()
                            }}

                        /></div>
                    <div><Button
                        label="Yes"
                        type="submit"
                        className="p-button mb-3 transition-duration-300 hover:bg-indigo-700 custom-border-button"
                        style={{ borderRadius: '30px', width: '194px', background: '#292666' }}
                        onClick={() => {
                            onSubmit(data)
                        }}
                    /></div>



                </div>

            </div>
        </Dialog>
    )
}

export default CustomEnableDialog;