import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory, ModelsUpload } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { useToast } from "../../utils/Properties"
import "./style.css"

interface props {
    data: ModelsUpload
    setData: Dispatch<SetStateAction<ModelsUpload>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
}

const FailedCaseDialog: FC<props> = ({ data,setData,  visible, setVisible }) => {
    const apiInstance = useSecureApiConnector()
    const title = `warning message of ${data.name}`
    const [successToast, errorToast, ,] = useToast();

    const defaultValues = {
        name: '',
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm({ defaultValues });

    return (
        <Dialog closable={false} draggable={false}
            visible={visible}
            className="custom-dialog p-0 m-0 w-23rem md:w-30rem"
            showHeader={false}
            onHide={() => {
                setVisible(false)
                setData({})
                reset()
            }}
        >
            <div className="flex">
                <div className="w-full text-v3 font-bold py-2 px-4 ">
                    <div className="grid grid-nogutter add-branch" style={{ marginTop: '1rem' }}>
                        <div className="col-10 hidden md:flex relative" style={{ color: '#D74343' }}>
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10 flex md:hidden relative" style={{ top: '4px', color: '#D74343' }}>
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-500 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    setData({})
                                    reset()
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}
                            ></Badge>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-fluid">

                <div className="text-v2 py-2 px-4">

                    <div
                        className="flex align-items-center pb-3"
                    >
                        <i className="pi pi-info-circle gable-text-dark-blue" style={{ fontSize: '2rem', marginRight: '1rem' }} />
                        <div style={{whiteSpace: "pre-line"}}>{data.remark}</div>
                    </div>

                </div>
            </div>
        </Dialog>
    )
}

export default FailedCaseDialog;