import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./global.css";
import "@mdi/font/css/materialdesignicons.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import ApiProvider from "./utils/ApiConnector";
import AppPropertiesProvider from "./utils/Properties";
import SignIn from "./views/SignIn";
import Layout from "./components/Layout";
import Dashboard from "./views/Dashboard";
import DirectoriesManagement from "./views/OrgManagement/Directories";
import ResetPasswd from "./views/ResetPassword";
import Status403 from "./views/StatusPage/403";
import Status404 from "./views/StatusPage/404";
import Loading from "./views/Landing";
import UsersManagement from "./views/OrgManagement/Users";
import AdminPageMontorDashboard from "./views/AdminPageMonitor";
import AdminPageMonthlyMontorDashboard from "./views/AdminPageMonthlyMonitor";
import Maintane from "./components/MaintenancePage/maintane";
import Support from "./views/Support/Support";
import AdminTemplatManagement from "./views/AdminTemplatManagement/AdminTemplatManagement";
import ReportDashboard from "./views/ReportDashboard";
import PredefinedDocumentAI from "./views/OrgManagement/PredefinedDocumentAI";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CookiesProvider>
    <ApiProvider>
      <BrowserRouter>
        <AppPropertiesProvider>
          <Routes>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/Maintenance" element={<Maintane />} />
            <Route path="/reset-password" element={<ResetPasswd />} />
            <Route path="/forbidden" element={<Status403 />} />
            <Route path="/not-found" element={<Status404 />} />
            <Route path="/" element={<Loading />} />
            <Route element={<Layout />}>
              <Route path="/report-dashboard" element={<ReportDashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/branch-management"
                element={<DirectoriesManagement />}
              />
              <Route path="/user-management" element={<UsersManagement />} />
              <Route
                path="/admin-page-monitor"
                element={<AdminPageMontorDashboard />}
              />
              <Route
                path="/admin-page-monthly-monitor"
                element={<AdminPageMonthlyMontorDashboard />}
              />
              <Route
                path="/predefined-document-ai"
                element={<PredefinedDocumentAI />}
              />

              <Route path="/support" element={<Support />} />
              <Route
                path="/admin-template-management"
                element={<AdminTemplatManagement />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </Routes>
        </AppPropertiesProvider>
      </BrowserRouter>
    </ApiProvider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
