import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ModelsDirectory,
  ModelsResultCsvFile,
  ModelsShipType,
  ModelsUpload,
} from "../../api";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import { useClipboard } from "../../utils/CopyToClipboard";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload, FileUploadHandlerParam } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFileWithType } from "../../utils/File";

interface props {
  data: ModelsUpload;
  setData: Dispatch<SetStateAction<ModelsUpload>>;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}

const UserDownloadSourceDialog: FC<props> = ({
  data,
  setData,
  visible,
  setVisible,
  refresh,
}) => {
  const apiInstance = useSecureApiConnector();
  const title = `Download Sources, upload name : ${data.name?.length! > 10 ? data.name?.slice(0, 10) + "..." : data.name}`;
  const [successToast, errorToast, , warnToast] = useToast();
  const saveAsWithType = useFileWithType();
  const setPageWaiting = usePageWaiting();

  const [allFile, setAllFile] = useState([]);
  
  const downloadFile = () => {
    setPageWaiting(true);
    apiInstance.uploads
      .downloadSources(data.id!, allFile)
      .then((res) => {
        saveAsWithType(
          `sources-${data.name}_${data.ship_type!.name}_${data.mode!.name}_${data.in_format!.name
          }_${data.out_format!.name}.zip`,
          res.data,
          "application/zip"
        );
      })
      .catch(() => {
        errorToast("download file failed");
      })
      .finally(() => {
        setPageWaiting(false);
      });
  };

  return (
    <>
      <Dialog
        closable={false}
        draggable={false}
        visible={visible}
        className="custom-dialog p-0 m-0 w-50rem md:w-30rem"
        showHeader={false}
        onHide={() => {
          setVisible(false);
          setAllFile([]);
          setData({});
        }}
      >
        <div className="w-full py-2 px-4">
          <div
            className="grid grid-nogutter"
            style={{
              border: "white",
              borderBottom: "#A098AE",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
          >
            <div className="col">
              <div className="md:flex text-v3 font-bold relative">
                {title.toLocaleUpperCase()}
              </div>
              <div
                className="flex md:hidden text-v3 font-bold relative"
                style={{ top: "4px" }}
              >
                {title.toLocaleUpperCase()}
              </div>
            </div>
            <div className="col-1 text-right" style={{ marginLeft: "1.5rem" }}>
              <Badge
                value="X"
                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                // severity="danger"
                style={{
                  fontSize: "0.5rem",
                  background: "#A098AE",
                  minWidth: "1rem",
                  height: "1rem",
                  lineHeight: "1rem",
                }}
                onClick={() => {
                  setVisible(false);
                  setAllFile([]);
                  setData({});
                }}
              ></Badge>
            </div>
          </div>
        </div>
        <div className="p-3">
          <DataTable
            className="mx-3 "
            value={data.files}
            selectionMode="checkbox"
            selection={allFile}
            onSelectionChange={(e) => setAllFile(e.value)}
            dataKey="id"
            responsiveLayout="stack"
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3em" }}
            ></Column>
            <Column
              className="text-v2 "
              field="name"
              header="File Name"
            ></Column>
          </DataTable>

          <div className="mb-3 mt-3 text-center">
            <Button
              label="Download"
              className="w-10rem download-file"
              disabled={allFile.length === 0}
              onClick={() => {
                downloadFile();
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserDownloadSourceDialog;
