export const DOCUMENT_AI_INVOICE_FIELDS = [
  {
    Name: "EmptySpace",
    Type: "empty-space",
    Description: "Empty space for empty column",
    StandardizedOutput: "",
  },
  {
    Name: "CustomerName",
    Type: "string",
    Description: "Invoiced customer",
    StandardizedOutput: "G-Able Public Company Limited",
  },
  {
    Name: "CustomerId",
    Type: "string",
    Description: "Customer reference ID",
    StandardizedOutput: "CID-12345",
  },
  {
    Name: "PurchaseOrder",
    Type: "string",
    Description: "Purchase order reference number",
    StandardizedOutput: "PO-3333",
  },
  {
    Name: "InvoiceId",
    Type: "string",
    Description: "ID for this specific invoice (often Invoice Number)",
    StandardizedOutput: "INV-100",
  },
  {
    Name: "InvoiceDate",
    Type: "date",
    Description: "date the invoice was issued",
    StandardizedOutput: "mm-dd-yyyy",
  },
  {
    Name: "DueDate",
    Type: "date",
    Description: "date payment for this invoice is due",
    StandardizedOutput: "mm-dd-yyyy",
  },
  {
    Name: "VendorName",
    Type: "string",
    Description: "Vendor who created this invoice",
    StandardizedOutput: "CONTOSO LTD.",
  },
  {
    Name: "VendorAddress",
    Type: "address",
    Description: "Vendor mailing address",
    StandardizedOutput: "123 456th St, New York, NY 10001",
  },
  {
    Name: "VendorAddressRecipient",
    Type: "string",
    Description: "Name associated with the VendorAddress",
    StandardizedOutput: "Contoso Headquarters",
  },
  {
    Name: "CustomerAddress",
    Type: "address",
    Description: "Mailing address for the Customer",
    StandardizedOutput: "123 Other St, Redmond WA, 98052",
  },
  {
    Name: "CustomerAddressRecipient",
    Type: "string",
    Description: "Name associated with the CustomerAddress",
    StandardizedOutput: "G-Able Public Company Limited",
  },
  {
    Name: "BillingAddress",
    Type: "address",
    Description: "Explicit billing address for the customer",
    StandardizedOutput: "123 Bill St, Redmond WA, 98052",
  },
  {
    Name: "BillingAddressRecipient",
    Type: "string",
    Description: "Name associated with the BillingAddress",
    StandardizedOutput: "G-Able Services",
  },
  {
    Name: "ShippingAddress",
    Type: "address",
    Description: "Explicit shipping address for the customer",
    StandardizedOutput: "123 Ship St, Redmond WA, 98052",
  },
  {
    Name: "ShippingAddressRecipient",
    Type: "string",
    Description: "Name associated with the ShippingAddress",
    StandardizedOutput: "Microsoft Delivery",
  },
  {
    Name: "SubTotal",
    Type: "currency",
    Description: "Subtotal field identified on this invoice",
    StandardizedOutput: "$100.00",
  },
  {
    Name: "TotalDiscount",
    Type: "currency",
    Description: "The total discount applied to an invoice",
    StandardizedOutput: "$5.00",
  },
  {
    Name: "TotalTax",
    Type: "currency",
    Description: "Total tax field identified on this invoice",
    StandardizedOutput: "$10.00",
  },
  {
    Name: "InvoiceTotal",
    Type: "currency",
    Description: "Total new charges associated with this invoice",
    StandardizedOutput: "$10.00",
  },
  {
    Name: "AmountDue",
    Type: "currency",
    Description: "Total Amount Due to the vendor",
    StandardizedOutput: "$610",
  },
  {
    Name: "PreviousUnpaidBalance",
    Type: "currency",
    Description: "Explicit previously unpaid balance",
    StandardizedOutput: "$500.00",
  },
  {
    Name: "RemittanceAddress",
    Type: "address",
    Description: "Explicit remittance or payment address for the customer",
    StandardizedOutput: "123 Remit St New York, NY, 10001",
  },
  {
    Name: "RemittanceAddressRecipient",
    Type: "string",
    Description: "Name associated with the RemittanceAddress",
    StandardizedOutput: "Contoso Billing",
  },
  {
    Name: "ServiceAddress",
    Type: "address",
    Description:
      "Explicit service address or property address for the customer",
    StandardizedOutput: "123 Service St, Redmond WA, 98052",
  },
  {
    Name: "ServiceAddressRecipient",
    Type: "string",
    Description: "Name associated with the ServiceAddress",
    StandardizedOutput: "G-Able Services",
  },
  {
    Name: "ServiceStartDate",
    Type: "date",
    Description:
      "First date for the service period (for example, a utility bill service period)",
    StandardizedOutput: "mm-dd-yyyy",
  },
  {
    Name: "ServiceEndDate",
    Type: "date",
    Description:
      "End date for the service period (for example, a utility bill service period)",
    StandardizedOutput: "mm-dd-yyyy",
  },
  {
    Name: "VendorTaxId",
    Type: "string",
    Description: "The taxpayer number associated with the vendor",
    StandardizedOutput: "123456-7",
  },
  {
    Name: "CustomerTaxId",
    Type: "string",
    Description: "The taxpayer number associated with the customer",
    StandardizedOutput: "765432-1",
  },
  {
    Name: "PaymentTerm",
    Type: "string",
    Description: "The terms of payment for the invoice",
    StandardizedOutput: "Net90",
  },
  {
    Name: "KVKNumber",
    Type: "string",
    Description:
      "A unique identifier for businesses registered in the Netherlands (NL-only)",
    StandardizedOutput: "12345678",
  },
  {
    Name: "CurrencyCode",
    Type: "string",
    Description: "The currency code associated with the extracted amount",
    StandardizedOutput: "",
  },
  {
    Name: "PaymentDetails",
    Type: "array",
    Description:
      "An array that holds Payment Option details such as IBAN,SWIFT, BPayBillerCode(AU), BPayReference(AU)",
    StandardizedOutput: "",
  },
  {
    Name: "TaxDetails",
    Type: "array",
    Description: "An array that holds tax details like amount and rate",
    StandardizedOutput: "",
  },
  {
    Name: "TaxDetails",
    Type: "array",
    Description:
      "AN array that holds added tax information such as CGST, IGST, and SGST. This line item is currently only available for the Germany (de), Spain (es), Portugal (pt), and English Canada (en-CA) locales",
    StandardizedOutput: "",
  },
  {
    Name: "Amount",
    Type: "currency",
    Description: "The amount of the line item",
    StandardizedOutput: "$60.00",
  },
  {
    Name: "Date",
    Type: "date",
    Description:
      "Date corresponding to each line item. Often it's a date the line item was shipped",
    StandardizedOutput: "44289",
  },
  {
    Name: "Description",
    Type: "string",
    Description: "The text description for the invoice line item",
    StandardizedOutput: "Consulting service",
  },
  {
    Name: "Quantity",
    Type: "number",
    Description: "The quantity for this invoice line item",
    StandardizedOutput: "2",
  },
  {
    Name: "ProductCode",
    Type: "string",
    Description:
      "Product code, product number, or SKU associated with the specific line item",
    StandardizedOutput: "A123",
  },
  {
    Name: "Tax",
    Type: "currency",
    Description:
      "Tax associated with each line item. Possible values include tax amount and tax Y/N",
    StandardizedOutput: "$6.00",
  },
  {
    Name: "TaxRate",
    Type: "string",
    Description: "Tax Rate associated with each line item.",
    StandardizedOutput: "0.18",
  },
  {
    Name: "Unit",
    Type: "string",
    Description: "The unit of the line item, e.g, kg, lb etc.",
    StandardizedOutput: "Hours",
  },
  {
    Name: "UnitPrice",
    Type: "number",
    Description:
      "The net or gross price (depending on the gross invoice setting of the invoice) of one unit of this item",
    StandardizedOutput: "$30.00",
  },
];
