import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { ModelsDirectory } from "../../api";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import { useClipboard } from "../../utils/CopyToClipboard";
import { Tooltip } from "primereact/tooltip";
import { RadioButton } from "primereact/radiobutton";
import { DOCUMENT_AI_INVOICE_FIELDS } from "../../constants/upload";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { OverlayPanel } from "primereact/overlaypanel";

interface props {
  type_id: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}

const AddPredefinedDialog: FC<props> = ({
  visible,
  setVisible,
  refresh,
  type_id,
}) => {
  const apiInstance = useSecureApiConnector();
  const title = "Add Profile";
  const [successToast, errorToast, , warnToast] = useToast();
  const setPageWating = usePageWaiting();
  const [name, setName] = useState("");
  const clipboard = useClipboard();

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (name.trim() === "") {
      warnToast("Name is required");
    } else {
      let prepared_fields: string[] = [];
      selectedDocAiInvoiceFields.forEach((item) => {
        prepared_fields.push(
          `${item.Name}:${
            !!item.CustomName && item.CustomName !== ""
              ? item.CustomName
              : item.Name
          }`
        );
      });

      setPageWating(true);
      apiInstance.documentAi
        .create({
          name: name,
          type_id: type_id,
          fields: prepared_fields.join(","),
        })
        .then((res) => res.data)
        .then((data) => {
          successToast("Profile added");
          setVisible(false);
          refresh();
        })
        .catch(() => {
          errorToast("profile add failed");
        })
        .finally(() => {
          setPageWating(false);
        });
    }
  };
  const copy = (msg: string) => {
    clipboard(msg)
      .then(() => {
        successToast(`Copy ${msg} to clipboard`);
      })
      .catch(() => {
        errorToast(`Copy ${msg} to clipboard failed`);
      });
  };

  useEffect(() => {
    if (visible) {
    }
  }, [visible]);

  const [fieldsDocAiInvoiceFields, setFieldsDocAiInvoiceFields] = useState(
    DOCUMENT_AI_INVOICE_FIELDS
  );
  const [selectedDocAiInvoiceFields, setSelectedDocAiInvoiceFields] = useState(
    [] as any[]
  );

  const customEmptyOverlay = useRef<any>(null);
  const [customEmptyVal, setCustomEmptyVal] = useState("");
  const [selectedField, setSelectedField] = useState(
    {} as { event: any; data: any } | null
  );
  useEffect(() => {
    if (selectedField != null) {
      customEmptyOverlay.current?.toggle(selectedField.event);
      console.log(selectedField);
    }
  }, [selectedField]);

  return (
    <>
      <Dialog
        closable={false}
        draggable={false}
        visible={visible}
        className="custom-dialog p-0 m-0 w-23rem md:w-30rem dialog-width"
        showHeader={false}
        onHide={() => {
          setVisible(false);
        }}
      >
        <div
          className="w-full text-v3 font-bold py-2 px-4"
          style={{ marginTop: "2rem" }}
        >
          <div className="grid grid-nogutter add-branch">
            <div className="col-10 hidden md:flex relative">
              {title.toLocaleUpperCase()}
            </div>
            <div
              className="col-10  flex md:hidden relative"
              style={{ top: "4px" }}
            >
              {title.toLocaleUpperCase()}
            </div>
            <div className="col-2 text-right">
              <Badge
                value="X"
                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                severity="danger"
                onClick={() => {
                  setVisible(false);
                }}
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.5rem",
                  background: "#A098AE",
                }}
              ></Badge>
            </div>
          </div>
        </div>
        <div className="py-2 px-4 w-full text-v2 font-bold">
          <form onSubmit={onSubmit} className="p-fluid ">
            <div className="flex">
              <div className="flex-initial align-items-center justify-content-center pr-5">
                <p className="mb-2 mt-2 font-bold text-sm">
                  Name<span style={{ color: "red" }}>*</span>
                </p>

                <InputText
                  autoFocus
                  className={`input-border`}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ width: "100%", margin: "1rem 0" }}>
              <div className="mb-4 mt-2 font-bold text-sm">
                Choose your pre-defined fields
              </div>

              <DataTable
                value={fieldsDocAiInvoiceFields}
                scrollable
                scrollHeight="300px"
                style={{ width: "100%" }}
                responsiveLayout="scroll"
              >
                <Column
                  field="Name"
                  header="Name"
                  style={{ maxWidth: "200px", flexFlow: "wrap" }}
                ></Column>

                <Column
                  field="Description"
                  header="Description"
                  style={{ width: "200px", flexFlow: "wrap" }}
                ></Column>
                <Column
                  field="StandardizedOutput"
                  header="Example Data"
                  style={{ width: "200px", flexFlow: "wrap" }}
                ></Column>
                <Column
                  field="Choose"
                  header=""
                  style={{ minWidth: "110px", maxWidth: "100px" }}
                  body={(item) => (
                    <>
                      <Button
                        className="p-button-sm"
                        key={item.Name}
                        type="button"
                        onClick={(e) => {
                          // if (item.Type !== "empty-space") {
                          //   let arr = fieldsDocAiInvoiceFields.filter((e) => {
                          //     return e !== item;
                          //   });
                          //   setFieldsDocAiInvoiceFields(arr);
                          //   setSelectedDocAiInvoiceFields([
                          //     ...selectedDocAiInvoiceFields,
                          //     item,
                          //   ]);
                          // } else {
                          //   customEmptyOverlay.current?.toggle(e);
                          // }
                          setSelectedField({
                            data: item,
                            event: e,
                          });
                        }}
                      >
                        Choose
                      </Button>
                    </>
                  )}
                ></Column>
              </DataTable>

              <OverlayPanel
                ref={customEmptyOverlay}
                showCloseIcon
                id="overlay_panel"
                style={{ width: "280px" }}
                onHide={() => {
                  setSelectedField(null);
                }}
              >
                <div>Custom header</div>
                <div className="mt-4">
                  <div className="mb-2 mt-2 font-bold text-sm">
                    Empty space header value
                    <div className="mt-2">Descriptions:</div>
                    <div style={{ fontWeight: "normal" }}>
                      1) If you enter some value to this below, it's mean the
                      value of csv header
                    </div>
                    <div style={{ fontWeight: "normal" }}>
                      2) If you not enter any value, the csv header of column
                      going to be empty or default.
                    </div>
                  </div>
                  <InputText
                    value={customEmptyVal}
                    onChange={(e) => {
                      setCustomEmptyVal(e.currentTarget.value);
                    }}
                    className={`input-bordered`}
                    style={{ maxWidth: "100%" }}
                  />
                  <Button
                    className="mt-3"
                    type="button"
                    onClick={(e) => {
                      let allow = true;
                      if (customEmptyVal !== "EmptySpace") {
                        let findDup = DOCUMENT_AI_INVOICE_FIELDS.findIndex(
                          (e) => e.Name === customEmptyVal
                        );
                        if (findDup !== -1) {
                          allow = false;
                          warnToast(
                            "Cannot enter empty space with constant value"
                          );
                        }
                      }

                      if (allow) {
                        let tmp = Object.assign({}, selectedField?.data);
                        if (customEmptyVal.trim() !== "") {
                          tmp.CustomName = customEmptyVal;
                        } else {
                          if (selectedField?.data.Type === "empty-space") {
                            tmp.CustomName = " ";
                          }
                        }

                        if (selectedField?.data.Type !== "empty-space") {
                          let arr = fieldsDocAiInvoiceFields.filter((e) => {
                            return e !== selectedField?.data;
                          });
                          setFieldsDocAiInvoiceFields(arr);
                        }

                        setSelectedDocAiInvoiceFields([
                          ...selectedDocAiInvoiceFields,
                          tmp,
                        ]);

                        setCustomEmptyVal("");
                        customEmptyOverlay.current?.toggle(e);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </OverlayPanel>
            </div>
            <div style={{ width: "100%", marginTop: "1rem" }}>
              <Divider />
            </div>
            <div style={{ width: "100%", margin: "1rem 0" }}>
              <div
                className="mb-4 mt-2 font-bold text-sm"
                key={`upload-column-doc-ai-preview`}
              >
                Column header preview
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  marginBottom: "1rem",
                }}
                key={`upload-column-doc-ai-preview-children`}
              >
                {selectedDocAiInvoiceFields.map((item, index) => (
                  <div
                    style={{
                      border: "1px solid #555",
                      borderLeft: index > 0 ? "0" : "1px solid #555",
                      padding: "0.5rem 1rem",
                      display: "flex",
                      fontSize: "14px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={`field-${index}`}
                  >
                    <div>
                      {!!item.CustomName ? item.CustomName : item.Name}{" "}
                      {item.Type !== "empty-space" && (
                        <span style={{ fontWeight: "normal" }}>
                          ({item.Name})
                        </span>
                      )}
                    </div>
                    <Badge
                      className="transition-duration-300 cursor-pointer hover:bg-red-400 ml-2"
                      severity="danger"
                      value={
                        <>
                          <i className="mdi mdi-delete"></i>
                        </>
                      }
                      onClick={() => {
                        var array = [...selectedDocAiInvoiceFields]; // make a separate copy of the array
                        array.splice(index, 1);
                        setSelectedDocAiInvoiceFields(array);

                        if (item.Type !== "empty-space") {
                          setFieldsDocAiInvoiceFields([
                            ...fieldsDocAiInvoiceFields,
                            item,
                          ]);
                        }
                      }}
                    ></Badge>
                  </div>
                ))}
              </div>
            </div>

            <Button
              label="Submit"
              type="submit"
              className="p-button mt-3 transition-duration-300 hover:bg-indigo-700"
              style={{
                borderRadius: "30px",
                width: "194px",
                marginLeft: "43rem",
                background: "#292666",
                marginBottom: "2rem",
              }}
            />
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default AddPredefinedDialog;
