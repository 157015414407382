import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ModelsDirectory,
  ModelsResultCsvFile,
  ModelsShipType,
  ModelsUpload,
} from "../../api";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import { useClipboard } from "../../utils/CopyToClipboard";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload, FileUploadHandlerParam } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFileWithType } from "../../utils/File";
import { Checkbox } from 'primereact/checkbox';

interface props {
  data: ModelsUpload;
  setData: Dispatch<SetStateAction<ModelsUpload>>;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}

const UserConfirmTriggerDialog: FC<props> = ({ data, setData, visible, setVisible, refresh, }) => {
  const title = `Confirm trigger upload name : ${data.name}`;
  const [successToast, errorToast, , warnToast] = useToast();
  const apiInstance = useSecureApiConnector();
  const setPageWaiting = usePageWaiting();
  const [checked, setChecked] = useState<boolean>(false);

  const setTriggerStatus = async () => {
    data.result_flag = checked
    setPageWaiting(true);
    await apiInstance.uploads.update(data).then(() => { 
      console.log("Update Successful");
    })
    .catch( (reason) =>{
      console.error(reason);
    } )

    apiInstance.uploads
      .statusTrigger(data.id!)
      .then(() => {
        successToast(`${data.name} status triggered`);
        setVisible(false);
        setData({});
        refresh();
      })
      .catch(() => {
        errorToast(`${data.name} status trigger failed`);
      })
      .finally(() => {
        setPageWaiting(false);
      });
  };

  const resetState = () => {
    setPageWaiting(true);
    apiInstance.uploads
      .resetState(data.id!)
      .then(() => {
        successToast(`${data.name} status has been reset`);
        setVisible(false);
        setData({});
        refresh();
      })
      .catch(() => {
        errorToast(`${data.name} status trigger has been reset`);
      })
      .finally(() => {
        setPageWaiting(false);
      });
  }

  useEffect(()=>{
    if(data.result_flag !== null){
      setChecked(data.result_flag!)
    }
  },[data])

  return (
    <>
      <Dialog
        closable={false}
        draggable={false}
        visible={visible}
        className="custom-dialog p-0 m-0 w-50rem md:w-30rem"
        showHeader={false}
        onHide={() => {
          setChecked(false)
          setVisible(false);
          setData({});
        }}
      >
        <div className="w-full py-2 px-4">
          <div
            className="grid grid-nogutter"
            style={{
              border: "white",
              borderBottom: "#A098AE",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
          >
            <div className="hidden md:flex text-v3 font-bold relative">
              {title.toLocaleUpperCase()}
            </div>
            <div
              className="flex md:hidden text-v3 font-bold relative"
              style={{ top: "4px" }}
            >
              {title.toLocaleUpperCase()}
            </div>
            <div className="col-2 text-right">
              <Badge
                value="X"
                className="transition-duration-300 cursor-pointer hover:bg-red-400 mb-1"
                // severity="danger"
                onClick={() => {
                  setVisible(false);
                  setData({});
                  setChecked(false)
                }}
                style={{
                  fontSize: "0.5rem",
                  background: "#A098AE",
                  minWidth: "1rem",
                  height: "1rem",
                  lineHeight: "1rem",
                }}
              ></Badge>
            </div>
          </div>
        </div>
        <div className="p-3">
          {data.status === "pending" && (
            <>
              <DataTable
                className="mx-3"
                value={data.files}
                dataKey="id"
                responsiveLayout="stack"
              >
                <Column
                  field="name"
                  header="File Name"
                  className="text-v2"
                ></Column>
              </DataTable>

              <div className="field-checkbox mt-3 ml-4">
                <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
                <label htmlFor="binary">When finished, send result to email</label>
              </div>

              <div className="mt-5 mb-3 text-center">
                <Button
                  label="Confirm Start"
                  disabled={!data.files}
                  className="p-button mb-3 transition-duration-300 hover:bg-indigo-700 custom-border-button"
                  style={{
                    borderRadius: "30px",
                    width: "194px",
                    background: "#292666",
                  }}
                  onClick={() => {
                    setTriggerStatus();
                  }}
                />
              </div>
            </>
          )}
          {data.status === "failed" && (
            <>
              <div className="text-center text-xl">Are you sure you reset the status to pending??</div>

              <div className="mt-5 mb-3 text-center">
                <Button
                  label="Reset status"
                  disabled={!data.files}
                  className="p-button mb-3 transition-duration-300 hover:bg-red-700 custom-border-button"
                  style={{
                    borderRadius: "30px",
                    width: "194px",
                    background: "#292666",
                  }}
                  onClick={() => {
                    resetState();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default UserConfirmTriggerDialog;
