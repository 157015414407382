import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ModelsUser } from '../../api';
import AddUserDialog from '../../components/OrgManagement/AddUserDialog';
import EditUserDialog from '../../components/OrgManagement/EditUserDialog';
import SearchDialog from '../../components/SearchDialog';
import { useSecureApiConnector } from '../../utils/ApiConnector';
import { useToast, useIssue } from '../../utils/Properties';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import { useClipboard } from '../../utils/CopyToClipboard';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Isolation from "../../assets/Isolation_Mode.png";
import { Card } from 'primereact/card';
import CustomDeleteDialog from '../../components/OrgManagement/CustomDeleteDialog';

const UsersManagement = () => {

    const properties = {
        title: "User Management"
    }
    const menu = useRef({} as any);
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dialogAdd, setDialogAdd] = useState(false)
    const [dialogEdit, setDialogEdit] = useState(false)
    const [dialogSearch, setSearchDialog] = useState(false)
    const [objectState, setObjectState] = useState({} as ModelsUser)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUser)
    const [objectResetPasswdState, setObjectResetPasswdState] = useState({} as ModelsUser)
    const [successToast, errorToast, ,] = useToast();
    const [initData, setInitData] = useState([] as ModelsUser[])
    const [data, setData] = useState([] as ModelsUser[])
    const [totalRecords, setTotalRecords] = useState(0)
    const [filter, setFilter] = useState("")
    const apiInstance = useSecureApiConnector()
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });
    const [tempPasswdDialog, setTempPasswdDialog] = useState(false)
    const [tempPasswd, setTempPasswd] = useState("")
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [dialogDelete2, setDeleteDialog2] = useState(false)
    const navigate = useNavigate()
    const issue = useIssue()
    const clipboard = useClipboard()

    const items = [
        {
            label: 'Operation',
            className: 'gable-text-gray',
            items: [
                {
                    label: 'Add User',
                    icon: 'mdi mdi-plus-circle gable-text-orange',
                    command: () => {
                        setDialogAdd(true)
                    }
                },
            ]
        },
    ];

    const fetchData = () => {
        apiInstance.users.getUserOffset(lazyParams.first, lazyParams.rows)
            .then(res => res.data)
            .then(data => {
                setInitData(data.record_list)
                setData(data.record_list)
                setTotalRecords(data.record_count!)
                setLoading(false)
            })
            .catch(() => {
                navigate("/forbidden")
            })
    }
    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }
    const acceptDelete = () => {
        apiInstance.users.deleteUser(objectDeleteState.id!)
            .then(() => {
                successToast("user deleted")
                setObjectDeleteState({})
                refresh()
            })
            .catch(() => {
                errorToast("user delete failed")
                setObjectDeleteState({})
            })
    }
    const acceptResetPasswd = () => {

        apiInstance.users.resetPasswordUserByAdministrator({
            u_id: objectResetPasswdState.id!
        })
            .then(res => res.data)
            .then((data) => {
                successToast("password has been reset")
                setTempPasswd(data.temporary_password!)
                setTempPasswdDialog(true)
            })
            .catch(() => {
                errorToast("password reset failed")
                setObjectResetPasswdState({})
            })
    }
    const cfDelete = () => {
        confirmDialog({
            message: <>Confirm to delete <b>{objectDeleteState.first_name} {objectDeleteState.last_name}</b> account? </>,
            closable: false,
            draggable: false,
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            position: 'center',
            acceptClassName: 'p-button-danger',
            accept: acceptDelete,
            reject: () => {
                setObjectDeleteState({})
            }
        });
    }
    const cfResetPasswd = () => {
        confirmDialog({
            message: <>Do you want to reset password of {objectDeleteState.first_name} {objectDeleteState.last_name}?</>,
            closable: false,
            draggable: false,
            header: 'Reset Password Confirmation',
            icon: 'pi pi-info-circle',
            position: 'center',
            acceptClassName: 'p-button-danger',
            accept: acceptResetPasswd,
            reject: () => {
                setObjectResetPasswdState({})
            }
        });
    }
    const copy = (msg: string) => {
        clipboard(msg)
            .then(() => {
                successToast(`Copy ${msg} to clipboard`)
            })
            .catch(() => {
                errorToast(`Copy ${msg} to clipboard failed`)
            })
    }
    const onFieldFilter = (event: any) => {
        event['first'] = 0;
        setLazyParams(event);
    }
    const onSubmit = (data: any) => {
        apiInstance.users.deleteUser(objectDeleteState.id!)
            .then(() => {
                successToast("user deleted")
                setObjectDeleteState({})
                setDeleteDialog(false)
                refresh()
            })
            .catch(() => {
                errorToast("user delete failed")
                setObjectDeleteState({})
            })
    }
    const onSubmit2 = (data: any) => {

        apiInstance.users.resetPasswordUserByAdministrator({
            u_id: objectResetPasswdState.id!
        })
            .then(res => res.data)
            .then((data) => {
                successToast("password has been reset")
                setTempPasswd(data.temporary_password!)
                setDeleteDialog2(false)
                setTempPasswdDialog(true)
            })
            .catch(() => {
                errorToast("password reset failed")
                setObjectResetPasswdState({})
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [triggerFetch])
    useEffect(() => {
        refresh()
    }, [lazyParams])
    useEffect(() => {
        if (objectState.id !== undefined) {
            setDialogEdit(true)
        }
    }, [objectState])
    useEffect(() => {

        if (filter === "" && data !== initData) {
            setData(initData)
        } else {
            setLoading(true)
            apiInstance.users.getUserSearch({
                search: filter,
                offset: lazyParams.first,
                limit: lazyParams.rows
            })
                .then(res => res.data)
                .then(data => {
                    setTotalRecords(data.record_count!)
                    setData(data.record_list)
                    setLoading(false)
                })
                .catch(() => {
                    setData([])
                    setLoading(false)
                })
        }
    }, [filter])

    return (
        <>
            <AddUserDialog visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} />
            <EditUserDialog data={objectState} setData={setObjectState} visible={dialogEdit} setVisible={setDialogEdit} refresh={refresh} />
            <ConfirmDialog />
            <SearchDialog visible={dialogSearch} setVisible={setSearchDialog} filter={filter} setFilter={setFilter} />
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmit} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{objectDeleteState.first_name} {objectDeleteState.last_name}</b> &nbsp;account? </>} />
            <CustomDeleteDialog data={objectResetPasswdState} setData={setObjectResetPasswdState} visible={dialogDelete2} setVisible={setDeleteDialog2} refresh={refresh}
                onSubmit={onSubmit2} meessage={<>Do you want to reset password of &nbsp;{objectResetPasswdState.first_name} {objectResetPasswdState.last_name}&nbsp;?</>} />
            <Dialog closable={false} draggable={false}
                visible={tempPasswdDialog}
                className="custom-dialog p-0 m-0 w-23rem md:w-30rem"
                showHeader={false}
                onHide={() => {
                    setTempPasswd('')
                    setTempPasswdDialog(false)
                    setObjectResetPasswdState({})
                    refresh()
                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4">
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            {"temporary password".toLocaleUpperCase()}
                        </div>
                        <div className="col-10 flex md:hidden relative" style={{ top: '4px' }}>
                            {"temporary password".toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setTempPasswd('')
                                    setTempPasswdDialog(false)
                                    setObjectResetPasswdState({})
                                    refresh()
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}
                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="p-5 text-center">
                    <span className="p-3 text-4xl border-1 border-blue-50 border-round-2xl gable-text-gray bg-blue-50">{tempPasswd}</span>

                    <Tooltip target=".copy-btn" />
                    <span
                        onClick={() => {
                            copy(tempPasswd)
                        }}
                        className='ml-2 copy-btn text-lg border-circle gable-text-orange hover:bg-orange-400 hover:text-white transition-duration-300 cursor-pointer border-1 px-1'
                        data-pr-tooltip="Copy to clipboard">
                        <i className="mdi mdi-clipboard-text"></i>
                    </span>
                </div>
            </Dialog>
            <div
                className="p-menuitem px-3 mx-0 "
                style={{
                    height: "60px", marginBottom: '-1rem'
                }}
            >
                <div className="p-menuitem-icon">
                    <img src={Isolation} alt="UserManagement" />
                </div>
                <div className="p-menuitem-text text-v1 font-bold">{properties.title}</div>
            </div>
            <div className="grid grid-nogutter my-0 lg:my-3">
                <div className="col-1 hidden lg:flex"></div>
                <div className="col">
                    <div className='grid grid-nogutter'>
                        <Card style={{ width: '1466px', padding: '0' }}>
                            <div className="col">
                                <div className='w-full relative' style={{ height: "48px" }}>
                                    <Button
                                        icon="mdi mdi-plus-circle-outline"
                                        // onClick={(event: any) => menu.current.toggle(event)}
                                        onClick={() => setDialogAdd(true)}
                                        style={{ left: '0rem', top: '5px' }}
                                        className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                                        aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add User</Button>
                                    <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px' }}>
                                        <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                            onChange={(e) => { setFilter(e.target.value) }} placeholder="Keyword Search" />
                                        <i className="pi pi-search gable-text-dark-blue" />

                                    </span>
                                    <Button
                                        icon="mdi mdi-magnify"
                                        style={{ right: '1rem', top: '5px' }}
                                        className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                        onClick={() => { setSearchDialog(true) }}
                                    />
                                </div>
                                <Menu model={items} popup ref={menu} id="popup_menu" />
                                <DataTable value={data}
                                    className="text-v2"
                                    lazy
                                    loading={loading}
                                    responsiveLayout="stack"
                                    paginator first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    totalRecords={totalRecords}
                                    onPage={(e: any) => {
                                        setLazyParams(e)
                                    }}
                                >
                                    <Column field="name" header="Name" body={(e) => (
                                        <>
                                            {e.first_name} {e.last_name}
                                        </>
                                    )}></Column>
                                    <Column header="Branch" body={(item: ModelsUser) => (<>
                                        {item.directory ? item.directory.name : 'Not Assigned'}
                                    </>)}></Column>
                                    <Column field="email" header="Email"></Column>

                                    <Column field="personal_id" header="Personal ID"></Column>
                                    <Column field="tel_number" header="Tel Number"></Column>
                                    <Column header="Status" body={(item: ModelsUser) => (<>
                                        <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.enable ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                                    </>)}></Column>
                                    <Column className='text-right' body={(item) => (
                                        <span className="p-buttonset flex">
                                            <div
                                                className="align-items-center justify-content-center  "
                                                style={{ width: "3.5rem" }}
                                            >
                                                <img
                                                    className={`${issue.id === item.id ? '' : 'transition-duration-200 cursor-pointer  hover:bg-red-500'}`}
                                                    src={require("../../assets/Group 43 (1).png")}
                                                    style={{ borderRadius: '20px' }}
                                                    alt="reset"
                                                    onClick={() => {
                                                        if (issue.id !== item.id) {

                                                            setObjectResetPasswdState(item)
                                                            setDeleteDialog2(true)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="align-items-center justify-content-center "
                                                style={{ width: "3.5rem" }}
                                            >
                                                <img
                                                    className='transition-duration-200 hover:bg-indigo-700 cursor-pointer'
                                                    src={require("../../assets/Group 36.png")}
                                                    alt="pencil"
                                                    style={{ borderRadius: '20px', width: '27px' }}
                                                    onClick={() => {
                                                        setObjectState(item)
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="align-items-center justify-content-center "
                                                style={{ width: "3.5rem" }}
                                            >
                                                <img
                                                    className={`${issue.id === item.id ? '' : 'transition-duration-200 cursor-pointer  hover:bg-red-500'}`}
                                                    src={require("../../assets/Group 32.png")}
                                                    style={{ borderRadius: '20px', width: '27px' }}
                                                    alt="bin"
                                                    onClick={() => {
                                                        if (issue.id !== item.id) {
                                                            setObjectDeleteState(item)
                                                            setDeleteDialog(true)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </span>

                                    )}></Column>
                                </DataTable>

                                {/* <DataTable value={data} paginator className="p-datatable-customers" showGridlines rows={10}
                                dataKey="id" filters={fieldFilters} filterDisplay="menu" responsiveLayout="scroll"
                                emptyMessage="No customers found.">
                                <Column field="email" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />

                            </DataTable> */}
                            </div>
                        </Card>

                    </div>
                </div>
                <div className="col-1 hidden lg:flex"></div>
            </div>
        </>
    )
}

export default UsersManagement;