import { Stream } from "stream";

const getCookie = (cookieName: string) => {
  let cookie = {} as any;
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};

type APIRequestParams<T> = {
  directory_id?: string;
  tenant_id?: string;
  data?: T;
};

export type GinH = Record<string, any>;

export interface GormDeletedAt {
  time?: string;

  /** Valid is true if Time is not NULL */
  valid?: boolean;
}
export interface ModelsAssets {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  updated_at?: string;
  value?: string;
}

export interface ModelTenant {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  updated_at?: string;
  name?: string;
  customer_name?: string;
  customer_address?: string;
  customer_telnumber?: string;
  customer_email?: string;
  directories?: ModelsDirectory[];
  docoment_ai_flag?: boolean;
}

export interface ModelsDirectory {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  processes?: ModelsProcess[];
  r_groups?: ModelsRGroup[];
  updated_at?: string;
  users?: ModelsUser[];
  tenant?: ModelTenant;
}

export interface ModelsIAMDirectory {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  directory_id?: string;
  role?: ModelsRole;
  role_id?: string;
  updated_at?: string;
  user?: ModelsUser;
  user_id?: string;
}

export interface ModelsIAMGlobal {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  role?: ModelsRole;
  role_id?: string;
  updated_at?: string;
  user?: ModelsUser;
  user_id?: string;
}

export interface ModelsIAMProcess {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  process?: ModelsProcess;
  process_id?: string;
  role?: ModelsRole;
  role_id?: string;
  updated_at?: string;
  user?: ModelsUser;
  user_id?: string;
}

export interface ModelsJobSchedule {
  at_time?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  deploy_mode?: string;
  enable?: boolean;
  every?: ModelsTimeTriggerEvery;
  every_id?: string;
  every_interval?: number;
  id?: string;
  mode?: ModelsTimeTriggerMode;
  mode_id?: string;
  on_date?: string;
  process?: ModelsProcessVersion;
  process_id?: string;
  rgroup?: ModelsRGroup;
  rgroup_id?: string;
  robots?: ModelsRobot[];
  updated_at?: string;
  uuid?: string;
}

export interface Level {
  name: string;
  priority: number;
}

export interface ModelsProcess {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  directory_id?: string;
  id?: string;
  name?: string;
  updated_at?: string;
  vault_hub?: ModelsVaultHub;
  vault_hub_id?: string;
  versions?: ModelsProcessVersion[];
}

export interface ModelsProcessAsset {
  asset?: ModelsAssets;
  asset_id?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  pv?: ModelsProcessVersion;
  pv_id?: string;
  updated_at?: string;
}

export interface ModelsProcessVersion {
  asset_variables?: ModelsProcessAsset[];
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  process?: ModelsProcess;
  process_id?: string;
  updated_at?: string;
  uuid?: string;
  version?: string;
}

export interface ModelsRGroup {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  directory_id?: string;
  id?: string;
  job_schedules?: ModelsJobSchedule[];
  name?: string;
  robots?: ModelsRobot[];
  updated_at?: string;
}

export interface ModelsRobot {
  allow_access?: boolean;
  secret?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  ingroup?: ModelsRGroup;
  ingroup_id?: string;
  ip_address?: string;
  job_schedules?: ModelsJobSchedule[];
  name?: string;
  updated_at?: string;
  directory_id?: number;
}

export interface ModelsRole {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  iam_directories?: ModelsIAMDirectory[];
  iam_processes?: ModelsIAMProcess[];
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface ModelsTimeTriggerEvery {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  updated_at?: string;
  value?: number;
}

export interface ModelsTimeTriggerMode {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface ModelsUAuthentication {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  passwd_checksum?: string;
  updated_at?: string;
  user?: ModelsUser;
}

export interface ModelsUser {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  role?: ModelsRole;
  directory_id?: string;
  email?: string;
  enable?: boolean;
  first_name?: string;
  id?: string;
  last_name?: string;
  tel_number?: string;
  personal_id?: string;
  need_chpasswd?: boolean;
  temporary_password?: string;
  uauth?: ModelsUAuthentication;
  uauth_id?: string;
  updated_at?: string;
  block_count?: number;
}

export interface ModelsVaultHub {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  customer_secret?: string;
  name?: string;
  processes?: ModelsProcess[];
  retention_day?: number;
}

export interface TypesCredentialParams {
  email?: string;
  password?: string;
  directory_activated_id?: string;
}

export interface TypesDeploy2Robot {
  client_id?: string;
  event?: string;
  proc?: TypesDeployProcType;
}

export interface TypesDeployProcType {
  id?: string;
  mode?: string;
  p_name?: string;
  p_version?: string;
  pv_uuid?: string;
  type?: string;
}

export interface TypesForgetPasswordResponse {
  email?: string;
}

export interface TypesProcessQueue {
  id?: string;
  mode?: string;
  process?: ModelsProcessVersion;
  rg?: ModelsRGroup;
  robots?: ModelsRobot[];
  status?: string;
}

export interface ModelsSupport {
  timestamp?: string;
  id?: string;
  topic?: string;
  type?: string;
  level?: number;
  summary?: string;
  status?: string;
  updated_at?: string;
  created_at?: string;
}

export interface TypesProcessSchedule {
  mode?: string;
  process?: ModelsProcessVersion;
  rg?: ModelsRGroup;
  robots?: ModelsRobot[];
  trig_at?: string;
  trig_every?: string;
  trig_every_interval?: number;
  trig_mode?: string;
  trig_on?: string;
}

export interface TypesRobotAllocationParams {
  g_id?: string;
  robots?: ModelsRobot[];
}

export interface TypesToggleSchedultStatus {
  id?: string;
}

export interface TypesTokenResponse {
  access_token?: string;
  access_token_expires?: string;
  need_chpasswd?: boolean;
  default_directory_id?: string;
  default_tenant_id?: string;
}

export interface TypesUserChangePasswdParams {
  new_password?: string;
  old_password?: string;
  u_id?: string;
}

export interface TypesUserForgetPasswdParams {
  email?: string;
}

export interface TypesUserResetPasswdParams {
  new_password?: string;
  old_password?: string;
  secret?: string;
  u_id?: string;
}

export interface UtilsArrayResponse {
  record_count?: number;
  record_list?: any;
}

export interface DocumentAIType {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}

export interface PredefinedDocumentAI {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  fields?: string;

  tenant?: ModelTenant;
  tenant_id?: string;

  type?: DocumentAIType;
  type_id?: string;
}

export interface ModelsUploadFile {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  file_type?: string;
}

export interface ModelsResultCsvFile {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  invoice_no?: string;
  company?: string;
  file_type?: string;
}

export interface ModelsShipType {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}

export interface ModelsUploadMode {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}

export interface ModelsInputFormat {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  enable?: boolean;
}

export interface ModelsOutputFormat {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}

export interface ModelsUpload {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;

  name?: string;
  remark?: string;
  status?: string;
  page_count?: number;
  invoice_count?: number;
  files?: ModelsUploadFile[];
  csv_files?: ModelsResultCsvFile[];
  user?: ModelsUser;
  directory?: ModelsDirectory;
  ship_type?: ModelsShipType;
  mode?: ModelsUploadMode;
  in_format?: ModelsInputFormat;
  out_format?: ModelsOutputFormat;
  result_flag?: boolean;

  doc_mode?: string;
  predefined_document_ai?: PredefinedDocumentAI;
}

export interface UserTotalPageDetail {
  month?: string;
  total?: number;
}

export interface TypeUploadFileResponse {
  have_duplicated?: boolean;
  msg?: string;
  file_name?: string[];
}

export interface WebProperty {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  value: string;
  started_at?: string;
  expired_at?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
          .then((data) => {
            if (r.ok) {
              r.data = data;
            } else {
              r.error = data;
            }
            return r;
          })
          .catch((e) => {
            r.error = e;
            return r;
          });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title G-RPA Orchestrator
 * @version 0.1.0
 * @license © 2022 G-Able Pub Co., Ltd. (https://www.g-able.com)
 * @baseUrl /api
 * @contact
 *
 * This is a G-RPA Orchestrator API
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  authentication = {
    /**
     * @description Check credential for get access and refresh token
     *
     * @tags Authentication
     * @name CheckCredential
     * @summary Check credential
     * @request POST:/authentication/check-credential
     */
    checkCredential: (
      params: TypesCredentialParams,
      requestParams: RequestParams = {}
    ) =>
      this.request<TypesTokenResponse, GinH>({
        path: `/authentication/check-credential`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Revoke token
     *
     * @tags Authentication
     * @name GetCredential
     * @summary Revoke token
     * @request GET:/authentication/get-credential
     * @secure
     */
    getCredential: (params: RequestParams = {}) =>
      this.request<GinH, GinH>({
        path: `/authentication/get-credential`,
        method: "GET",
        cache: "no-cache",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  directories = {
    /**
     * @description Create directory
     *
     * @tags Directories
     * @name CreateDirectory
     * @summary Create directory
     * @request POST:/directories/add
     * @secure
     */
    createDirectory: (Directory: ModelsDirectory, params: RequestParams = {}) =>
      this.request<ModelsDirectory, GinH>({
        path: `/directories/add`,
        method: "POST",
        body: Directory,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete directory
     *
     * @tags Directories
     * @name DeleteDirectory
     * @summary Delete directory
     * @request DELETE:/directories/delete/{directory_id}
     * @secure
     */
    deleteDirectory: (directoryId: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/directories/delete`,
        method: "DELETE",
        query: {
          directory_id: directoryId,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update directory
     *
     * @tags Directories
     * @name UpdateDirectory
     * @summary Update directory
     * @request PUT:/directories/edit
     * @secure
     */
    updateDirectory: (Directory: ModelsDirectory, params: RequestParams = {}) =>
      this.request<ModelsDirectory, GinH>({
        path: `/directories/edit`,
        method: "PUT",
        body: Directory,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get directory directory
     *
     * @tags Directories
     * @name GetDirectoryAll
     * @summary Get directory
     * @request GET:/directories/get
     * @secure
     */
    getDirectoryAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/directories/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get directory directory with offset
     *
     * @tags Directories
     * @name GetDirectoryOffset
     * @summary Get directory with offset
     * @request GET:/directories/get-with-offset/{offset}/{limit}
     * @secure
     */
    getDirectoryOffset: (
      offset: number,
      limit: number,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/directories/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get directory directory
     *
     * @tags Directories
     * @name GetDirectoryById
     * @summary Get directory by id
     * @request GET:/directories/get/{directory_id}
     * @secure
     */
    getDirectoryById: (directoryId: string, params: RequestParams = {}) =>
      this.request<ModelsDirectory, GinH>({
        path: `/directories/get`,
        method: "GET",
        query: {
          directory_id: directoryId,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Search directory directory
     *
     * @tags Directories
     * @name GetDirectorySearch
     * @summary Search directory
     * @request GET:/directories/search
     * @secure
     */
    getDirectorySearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/directories/search`,
        method: "GET",
        query: {
          ...query,
        },
        secure: true,
        format: "json",
        ...params,
      }),
  };
  roles = {
    /**
     * @description Get all roles
     *
     * @tags Roles
     * @name GetAllRoles
     * @summary Get all roles
     * @request GET:/roles/get-all
     * @secure
     */
    getAllRoles: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/roles/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getUserRoles: (params: RequestParams = {}) =>
      this.request<ModelsRole, GinH>({
        path: `/roles/get-user-roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  monitor = {
    /**
     * @description Get all roles
     *
     * @tags Roles
     * @name GetAllRoles
     * @summary Get all roles
     * @request GET:/roles/get-all
     * @secure
     */
    getSummary: (
      startDate: string,
      endDate: string,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/monitor/get-summary`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          StartDate: startDate,
          EndDate: endDate,
        },
        ...params,
      }),
  };
  users = {
    /**
     * @description Create user
     *
     * @tags Users
     * @name CreateUser
     * @summary Create user
     * @request POST:/users/add
     * @secure
     */
    createUser: (user: ModelsUser, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/add`,
        method: "POST",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Bring Your Own Password of user
     *
     * @tags Users
     * @name BringYourOwnPasswordUser
     * @summary Bring Your Own Password of user
     * @request POST:/users/bring-your-own-password
     * @secure
     */
    bringYourOwnPasswordUser: (
      params: TypesUserChangePasswdParams,
      requestParams: RequestParams = {}
    ) =>
      this.request<ModelsUser, GinH>({
        path: `/users/bring-your-own-password`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Change password of user
     *
     * @tags Users
     * @name ChangePasswordUser
     * @summary Change password of user
     * @request POST:/users/change-password
     * @secure
     */
    changePasswordUser: (
      params: TypesUserChangePasswdParams,
      requestParams: RequestParams = {}
    ) =>
      this.request<ModelsUser, GinH>({
        path: `/users/change-password`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Check reset secret
     *
     * @tags Users
     * @name CheckResetSecret
     * @summary Check reset secret
     * @request GET:/users/check-reset-secret/{secret}
     * @secure
     */
    checkResetSecret: (secret: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/users/check-reset-secret/${secret}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Delete user
     *
     * @tags Users
     * @name DeleteUser
     * @summary Delete user
     * @request DELETE:/users/delete/{id}
     * @secure
     */
    deleteUser: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/users/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),

    /**
     * @description Update user
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update user
     * @request PUT:/users/edit
     * @secure
     */
    updateUser: (user: ModelsUser, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/edit`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    blockUser: (user: ModelsUser, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/block-user`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    updateBlockUser: (user: ModelsUser, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/upadte-block-user`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Forget password of user
     *
     * @tags Users
     * @name ForgetPasswordUser
     * @summary Forget password of user
     * @request POST:/users/forget-password
     */
    forgetPasswordUser: (
      params: TypesUserForgetPasswdParams,
      requestParams: RequestParams = {}
    ) =>
      this.request<TypesForgetPasswordResponse, GinH>({
        path: `/users/forget-password`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description get-all-user
     *
     * @tags Users
     * @name GetAllUser
     * @summary get-all-user
     * @request GET:/users/get-all
     * @secure
     */
    getAllUser: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    getAllUserByTenantID: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-all-by-tennant`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get user with offset
     *
     * @tags Users
     * @name GetUserOffset
     * @summary Get user with offset
     * @request GET:/users/get-with-offset/{offset}/{limit}
     * @secure
     */
    getUserOffset: (
      offset: number,
      limit: number,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-with-offset`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit,
        },
        format: "json",
        ...params,
      }),

    /**
     * @description Get user directory
     *
     * @tags Users
     * @name GetUserById
     * @summary Get user by id
     * @request GET:/users/get/{id}
     * @secure
     */
    getUserById: (id: string, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/get`,
        method: "GET",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),

    /**
     * @description Reset password of user
     *
     * @tags Users
     * @name ResetPasswordUser
     * @summary Reset password of user
     * @request POST:/users/reset-password
     */
    resetPasswordUser: (
      params: TypesUserResetPasswdParams,
      requestParams: RequestParams = {}
    ) =>
      this.request<ModelsUser, GinH>({
        path: `/users/reset-password`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Reset password of user by administrator
     *
     * @tags Users
     * @name ResetPasswordUserByAdministrator
     * @summary Reset password of user by administrator
     * @request POST:/users/reset-password-by-administrator
     * @secure
     */
    resetPasswordUserByAdministrator: (
      params: TypesUserResetPasswdParams,
      requestParams: RequestParams = {}
    ) =>
      this.request<ModelsUser, GinH>({
        path: `/users/reset-password-by-administrator`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Search user
     *
     * @tags Users
     * @name GetUserSearch
     * @summary Search user
     * @request GET:/users/search
     * @secure
     */
    getUserSearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/search`,
        method: "GET",
        query: {
          ...query,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getPageMonitor: (
      offset: number,
      limit: number,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-page-monitor`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit,
        },
        format: "json",
        ...params,
      }),
    getPageMonitorByYear: (
      offset: number,
      limit: number,
      year: number,
      search?: string,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-page-monitor-by-year`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit,
          filter: year,
          search: !!search ? search : "",
        },
        format: "json",
        ...params,
      }),
    getPageMonitorSearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/search-page-monitor`,
        method: "GET",
        secure: true,
        query: query,
        format: "json",
        ...params,
      }),
    getPageMonthlyMonitor: (
      offset: number,
      limit: number,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-page-monthly-monitor`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit,
        },
        format: "json",
        ...params,
      }),
    getPageMonthlyMonitorSearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/search-page-monthly-monitor`,
        method: "GET",
        secure: true,
        query: query,
        format: "json",
        ...params,
      }),
    support: (upload: ModelsUpload, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/support`,
        method: "POST",
        body: upload,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  uploads = {
    create: (
      name: string,
      inputFormat: ModelsInputFormat,
      outputFormat: ModelsOutputFormat,
      shipType: ModelsShipType,
      mode: string,
      allFile: File[],
      params: RequestParams = {}
    ) =>
      this.request<TypeUploadFileResponse, GinH>({
        path: `/uploads/create`,
        method: "POST",
        secure: true,
        type: ContentType.FormData,
        format: "json",
        body: {
          name: name,
          input_format_id: inputFormat.id,
          output_format_id: outputFormat.id,
          ship_type_id: shipType.id,
          mode: mode,
          ...allFile,
        },
        ...params,
      }),
    create_with_doc_mode: (
      name: string,
      mode: string,
      predefined_document_ai_id: string,
      allFile: File[],
      params: RequestParams = {}
    ) =>
      this.request<TypeUploadFileResponse, GinH>({
        path: `/uploads/create`,
        method: "POST",
        secure: true,
        type: ContentType.FormData,
        format: "json",
        body: {
          name: name,
          doc_mode: mode,
          predefined_document_ai_id: predefined_document_ai_id,
          ...allFile,
        },
        ...params,
      }),
    addFile: (uploadId: string, allFile: File[], params: RequestParams = {}) =>
      this.request<TypeUploadFileResponse, GinH>({
        path: `/uploads/add-file`,
        method: "PUT",
        secure: true,
        type: ContentType.FormData,
        format: "json",
        body: {
          upload_id: uploadId,
          ...allFile,
        },
        ...params,
      }),
    deleteFile: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/uploads/delete-file`,
        method: "DELETE",
        secure: true,
        query: {
          upload_file_id: id,
        },
        format: "json",
        ...params,
      }),
    statusTrigger: (uploadId: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/uploads/status-trigger`,
        method: "PATCH",
        body: {
          upload_id: uploadId,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    resetState: (uploadId: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/uploads/reset-state`,
        method: "PATCH",
        body: {
          upload_id: uploadId,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    downloadFile: (
      uploadId: string,
      csvFiles: ModelsResultCsvFile[],
      params: RequestParams = {}
    ) =>
      this.request<ArrayBuffer, GinH>({
        path: `/uploads/download-file`,
        method: "POST",
        body: {
          upload_id: uploadId,
          csv_files: csvFiles,
        },
        secure: true,
        format: "arrayBuffer",
        ...params,
      }),
    downloadZipFile: (
      uploadId: string,
      csvFiles: ModelsUploadFile[],
      params: RequestParams = {}
    ) =>
      this.request<ArrayBuffer, GinH>({
        path: `/uploads/download-zip-file`,
        method: "POST",
        body: {
          upload_id: uploadId,
          csv_files: csvFiles,
        },
        secure: true,
        format: "arrayBuffer",
        ...params,
      }),
    downloadSources: (
      uploadId: string,
      sourcesFiles: ModelsUploadFile[],
      params: RequestParams = {}
    ) =>
      this.request<ArrayBuffer, GinH>({
        path: `/uploads/download-sources`,
        method: "POST",
        body: {
          upload_id: uploadId,
          source_files: sourcesFiles,
        },
        secure: true,
        format: "arrayBuffer",
        ...params,
      }),
    // updateDirectory: (Directory: ModelsDirectory, params: RequestParams = {}) =>
    //   this.request<ModelsDirectory, GinH>({
    //     path: `/directories/edit`,
    //     method: "PUT",
    //     body: Directory,
    //     secure: true,
    //     type: ContentType.Json,
    //     format: "json",
    //     ...params,
    //   }),

    getAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/uploads/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/uploads/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    getOffsetLikeYear: (
      offset: number,
      limit: number,
      year: number,
      user_ud: string,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/uploads/get-with-offset-like-year`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
          filter: year,
          user_id: user_ud,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    getSearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/uploads/search`,
        method: "GET",
        query: {
          ...query,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getMetric: (params: RequestParams = {}) =>
      this.request<
        {
          this_month: number;
          total: number;
          total_user?: number;
        },
        GinH
      >({
        path: `/uploads/get-metric`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    update: (upload: ModelsUpload, params: RequestParams = {}) =>
      this.request<ModelsUpload, GinH>({
        path: `/uploads/update`,
        method: "PUT",
        body: upload,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  documentAi = {
    getType: (params: RequestParams = {}) =>
      this.request<DocumentAIType[], GinH>({
        path: `/document-ai/get-type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getPredefined: (
      offset: number,
      limit: number,
      type_id: string,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/document-ai/get-predefined`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit,
          type_id: type_id,
        },
        format: "json",
        ...params,
      }),
    create: (data: PredefinedDocumentAI, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/document-ai/add-predefined`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deletePredefined: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/document-ai/delete-predefined`,
        method: "DELETE",
        query: {
          id: id,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    // getType(params: RequestParams = {}) => this.request<UtilsArrayResponse, GinH>({
    //   path: `/facilities/get-all-input-format`,
    //   method: "GET",
    //   secure: true,
    //   format: "json",
    //   ...params,
    // }),
  };

  facilities = {
    getAllInputFormat: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-input-format`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getInputFormatByShipType: (
      shipTypeId: string,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-input-format-by-ship-type`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          ship_type_id: shipTypeId,
        },
        ...params,
      }),

    getAllInputFormatByShipType: (
      shipTypeId: string,
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-input-format-by-shiptype`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          ship_type_id: shipTypeId,
        },
        ...params,
      }),
    getAllOutputFormat: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-output-format`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getAllShipType: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-ship-type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  webProperty = {
    getWebPropertyByID: (id: string, params: RequestParams = {}) =>
      this.request<WebProperty, GinH>({
        path: `/web-property/get-property`,
        method: "GET",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),
  };
  support = {
    create: (
      cc_mail: string,
      support: ModelsSupport,
      upload_id: string,
      allFile: File[],
      params: RequestParams = {}
    ) =>
      this.request<TypeUploadFileResponse, GinH>({
        path: `/support/create`,
        method: "POST",
        secure: true,
        type: ContentType.FormData,
        format: "json",
        body: {
          topic: support.topic,
          type: support.type,
          level: support.level,
          summary: support.summary,
          open: "Open",
          upload_id: upload_id,
          ...allFile,
        },
        query: {
          cc_mail: cc_mail,
        },
        ...params,
      }),
    getAll: (user_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          user_id: user_id,
        },
        ...params,
      }),
    getUpload: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/get-upload`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    getSearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/search`,
        method: "GET",
        query: {
          ...query,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/get-with-offset-user`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),
  };

  inputFormat = {
    getAllInputTemp: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getAllWithoutRbac: (tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/get-all-without-rbac`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenant_id,
        },
        format: "json",
        ...params,
      }),
    createTemplate: (user: ModelsInputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/input-format/add`,
        method: "POST",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    editTemplate: (user: ModelsInputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/input-format/edit`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    updateEnable: (user: ModelsInputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/input-format/update`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteTemplate: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/input-format/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getSearch: (
      query: { search: string; offset: number; limit: number },
      params: RequestParams = {}
    ) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/search`,
        method: "GET",
        query: {
          ...query,
        },
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
