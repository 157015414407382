import { Skeleton } from 'primereact/skeleton';
import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSecureApiConnector } from "../utils/ApiConnector";

const Loading = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const apiInstance = useSecureApiConnector();

    const getCredential = () => {
        apiInstance.authentication.getCredential()
            .then(res => res.data)
            .then(() => {
                navigate("/dashboard")

            })
            .catch(() => {
                navigate("/sign-in")
            })
    }

    useEffect(() => {
        if (location.pathname === "/") {
            getCredential()
        }
    }, [location.pathname])

    return (
        <div className="col p-8 h-screen text-center flex align-items-center justify-content-center">
            <section style={{ width: '80%' }}>
                <div className="border-round border-1 surface-border p-4 surface-card">
                    <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height=".5rem"></Skeleton>
                        </div>
                    </div>
                    <Skeleton width="100%" height="150px" className="text-2xl text-400 text-center flex align-items-center justify-content-center">Loading</Skeleton>
                </div>
            </section>
        </div>
    );
};

export default Loading;
