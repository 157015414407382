import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import React from "react";
import "./styleLayout.css";


const Layout = () => {

    const screenWidth = window.innerWidth;

    return (
        <div className={`p-0 mx-auto ${screenWidth >= 1920 ? "" : "layout-margin-left"}`} style={{
            minWidth: `${screenWidth >= 1920 ? "1600px" : "1600px"}`, width: `${screenWidth >= 1920 ? "1600px" : "1600px"}`,
            height: "auto", transform: `${screenWidth >= 1920 ? "scale(1)" : screenWidth >= 1680 ? "scale(0.95)" :
                screenWidth >= 1600 ? "scale(0.9)" : screenWidth >= 1366 ? "scale(0.76)" : screenWidth >= 1280 ? "scale(0.7)" :
                    screenWidth >= 1024 ? "scale(0.55)" : "scale(0.4)"}`,
            transformOrigin: `${screenWidth >= 1920 ? "" : "top left"}`,
            marginLeft: `${screenWidth >= 1920 ? "" : "4.5rem !important"}`
        }}>
            <Navbar />
            <div className="p-0">
                <Outlet />
            </div>
            <div className="mt-5 mb-3 text-center text-sm gable-text-gray">
                &copy; {new Date().getFullYear()} G-ABLE PUBLIC COMPANY LIMITED.
            </div>

        </div >
    )
}
export default Layout;