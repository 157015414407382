import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ModelsUpload, ModelsUser } from '../api';
import AddUserDialog from '../components/OrgManagement/AddUserDialog';
import EditUserDialog from '../components/OrgManagement/EditUserDialog';
import SearchDialog from '../components/SearchDialog';
import { useSecureApiConnector } from '../utils/ApiConnector';
import { useToast, useIssue, usePageWaiting } from '../utils/Properties';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import { useClipboard } from '../utils/CopyToClipboard';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Chip } from 'primereact/chip';
import UserDownloadCsvDialog from '../components/Dashboard/UserDownloadCSVDialog';
import { useFileWithType } from '../utils/File';
import { Card } from 'primereact/card';

const AdminPageMonthlyMontorDashboard = () => {
    const setPageWaiting = usePageWaiting();
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dialogDownloadCsv, setDialogDownloadCsv] = useState(false)
    const [dialogSearch, setSearchDialog] = useState(false)
    const [objectAddFileState, setObjectAddFileState] = useState({} as ModelsUpload)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUpload)
    const [objectDownloadCsvState, setObjectDownloadCsvState] = useState({} as ModelsUpload)
    const [objectConfirmTriggerState, setConfirmTriggerState] = useState({} as ModelsUpload)
    const [successToast, errorToast, ,] = useToast();
    const [initData, setInitData] = useState([] as ModelsUpload[])
    const [data, setData] = useState([] as ModelsUpload[])
    const [totalRecords, setTotalRecords] = useState(0)
    const [filter, setFilter] = useState("")
    const apiInstance = useSecureApiConnector()
    const navigate = useNavigate()
    const clipboard = useClipboard()
    const saveAsWithType = useFileWithType()

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const [metric, setMetric] = useState({} as {
        this_month: number;
        total: number;
        total_user?: number
    })

    const fetchData = () => {

        apiInstance.users.getPageMonthlyMonitor(lazyParams.first, lazyParams.rows)
            .then(res => res.data)
            .then(data => {
                setInitData(data.record_list)
                setData(data.record_list)
                setTotalRecords(data.record_count!)
                setLoading(false)
            })
            .catch(() => {
                navigate("/forbidden")
            })
    }
    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }
    const copy = (msg: string) => {
        clipboard(msg)
            .then(() => {
                successToast(`Copy ${msg} to clipboard`)
            })
            .catch(() => {
                errorToast(`Copy ${msg} to clipboard failed`)
            })
    }
    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
            ];

            return (
                <Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </Fragment>
            );
        },
        'CurrentPageReport': (options: any) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };
    const downloadSource = (data: ModelsUpload) => {
        setPageWaiting(true)
        apiInstance.uploads.downloadZipFile(data.id!, data.files!)
            .then(res => {
                saveAsWithType(`sources-${data.name!}.zip`, res.data, "application/zip")
            })
            .catch(() => {
                errorToast("download file failed")
            })
            .finally(() => {
                setPageWaiting(false)
            })

    }

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [triggerFetch])
    useEffect(() => {
        refresh()
    }, [lazyParams])
    useEffect(() => {
        if (objectDownloadCsvState.id !== undefined) {
            setDialogDownloadCsv(true)
        }
    }, [objectDownloadCsvState])
    useEffect(() => {
        if (filter === "" && data !== initData) {
            setData(initData)
        } else {
            setLoading(true)
            apiInstance.users.getPageMonthlyMonitorSearch({
                search: filter,
                offset: lazyParams.first,
                limit: lazyParams.rows
            })
                .then(res => res.data)
                .then(data => {
                    setTotalRecords(data.record_count!)
                    setData(data.record_list)
                    setLoading(false)
                })
                .catch(() => {
                    setData([])
                    setLoading(false)
                })
        }
    }, [filter])

    return (
        <>
            <UserDownloadCsvDialog data={objectDownloadCsvState} setData={setObjectDownloadCsvState} visible={dialogDownloadCsv} setVisible={setDialogDownloadCsv} refresh={refresh} />

            <ConfirmDialog />
            <SearchDialog visible={dialogSearch} setVisible={setSearchDialog} filter={filter} setFilter={setFilter} />


            <div className="grid grid-nogutter my-0 lg:my-3">
                <div className="col-1 hidden lg:flex"></div>
                <div className="col">
                    <div className='grid grid-nogutter'>
                        <div className="col">
                            <div
                                className="p-menuitem px-3 mx-0 "
                                style={{
                                    height: "60px", marginBottom: '-1rem'
                                }}
                            >
                                <div className="p-menuitem-icon">
                                    <img src={require("../assets/Group 93.png")}
                                        alt="filedock" style={{ width: '36px', height: '36px' }} />
                                </div>
                                <div className="p-menuitem-text text-v1 font-bold">Page Monthly Monitor</div>
                            </div>                           

                            <Card>
                                <div className='w-full relative' style={{ height: "48px" }}>

                                    <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '0', top: '5px' }}>
                                        <InputText
                                            className="w-25rem custom-search-box text-v3"
                                            value={filter} onChange={(e) => { setFilter(e.target.value) }}
                                            placeholder="  Keyword Search..." />
                                        <i className="pi pi-search gable-text-dark-blue" />
                                    </span>
                                    <Button
                                        icon="mdi mdi-magnify"
                                        style={{ right: '1rem', top: '5px' }}
                                        className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                        onClick={() => { setSearchDialog(true) }}
                                    />
                                </div>
                                <DataTable value={data}
                                    className="text-v2"
                                    lazy
                                    loading={loading}
                                    responsiveLayout="scroll"
                                    paginator first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    totalRecords={totalRecords}
                                    onPage={(e: any) => {
                                        setLazyParams(e)
                                    }}
                                    paginatorTemplate={paginatorTemplate}
                                    paginatorClassName="justify-content-end"
                                >
                                    <Column header='Branch' field='directory.name'></Column>
                                    <Column header='Name' field='name' body={(item) => (
                                        <>
                                            {item.first_name} {item.last_name}
                                        </>
                                    )}></Column>
                                    <Column header='Tel Number' field='tel_number'></Column>
                                    <Column field="page_count" header="Page Count"></Column>


                                </DataTable>
                            </Card>


                        </div>
                    </div>
                </div>
                <div className="col-1 hidden lg:flex"></div>
            </div>
        </>
    )
}

export default AdminPageMonthlyMontorDashboard;