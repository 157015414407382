import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const Status404 = () => {

    const navigate = useNavigate();

    return (

        <div className="col p-8 h-screen text-center flex align-items-center justify-content-center">
            <section style={{ maxWidth: '400px' }}>
                <i className="mdi mdi-alert-octagon text-red-500 block" style={{ fontSize: '120pt' }}></i>
                <span className="text-4xl md:text-6xl gable-text-orange font-bold mb-1">
                    G-ABLE <span className="gable-text-gray">| G-RPA</span>
                </span>

                <p className="text-center p-3 border-round-2xl bg-blue-50 text-2xl md:text-3xl gable-text-gray"> 404 Not Found </p>

                <Button className="p-button-text" onClick={() => { navigate("/dashboard") }} label="Go to dashboard" />
            </section>
        </div>
    )
}
export default Status404;