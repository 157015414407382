import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { useSecureApiConnector } from '../../utils/ApiConnector';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AddDirectoryDialog from '../../components/OrgManagement/AddDirectoryDialog';
import EditDirectoryDialog from '../../components/OrgManagement/EditDirectoryDialog';
import { useToast } from '../../utils/Properties';
import SearchDialog from '../../components/SearchDialog';
import { ModelsDirectory } from '../../api';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import FoldersGroup from "../../assets/Folders_group_light.png";
import CustomDeleteDialog from '../../components/OrgManagement/CustomDeleteDialog';

const DirectoriesManagement = () => {
    const properties = {
        title: "Branch Management"
    }
    const menu = useRef({} as any);
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dialogAdd, setDialogAdd] = useState(false)
    const [dialogEdit, setDialogEdit] = useState(false)
    const [dialogSearch, setSearchDialog] = useState(false)
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [objectState, setObjectState] = useState({} as ModelsDirectory)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsDirectory)
    const [successToast, errorToast, ,] = useToast();
    const [initData, setInitData] = useState([] as ModelsDirectory[])
    const [data, setData] = useState([] as ExtendModelsDirectory[])
    const [totalRecords, setTotalRecords] = useState(0)
    const [filter, setFilter] = useState("")
    const apiInstance = useSecureApiConnector()
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const navigate = useNavigate()

    interface ExtendModelsDirectory extends ModelsDirectory {
        page_count?: number;
    }

    const items = [
        {
            label: 'Operation',
            className: 'gable-text-gray',
            items: [
                {
                    label: 'Add Branch',
                    icon: 'mdi mdi-plus-circle gable-text-orange',
                    command: () => {
                        setDialogAdd(true)
                    }
                },
            ]
        },
    ];

    const fetchData = () => {
        apiInstance.directories.getDirectoryOffset(lazyParams.first, lazyParams.rows)
            .then(res => res.data)
            .then(data => {
                setInitData(data.record_list)
                setData(data.record_list)
                setTotalRecords(data.record_count!)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                // navigate("/forbidden")
            })
    }
    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }
    const acceptDelete = () => {
        apiInstance.directories.deleteDirectory(objectDeleteState.id!)
            .then(() => {
                successToast("Branch deleted")
                setObjectDeleteState({})
                refresh()
            })
            .catch(() => {
                errorToast("Branch delete failed")
                setObjectDeleteState({})
            })
    }
    const cfDelete = () => {
        confirmDialog({
            message: <>
                Confirm to delete <b>{objectDeleteState.name}</b> branch

            </>,
            closable: false,
            draggable: true,
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            position: 'center',
            acceptClassName: 'p-button-danger ',
            accept: acceptDelete,
            reject: () => {
                setObjectDeleteState({})
            },
        });
    }
    const onSubmit =(data:any)=>{
        apiInstance.directories.deleteDirectory(data.id!)
            .then(() => {
                successToast("Branch deleted")
                setObjectDeleteState({})
                setDeleteDialog(false)
                refresh()
            })
            .catch(() => {
                errorToast("Branch delete failed")
                setObjectDeleteState({})
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [triggerFetch])
    useEffect(() => {
        refresh()
    }, [lazyParams])
    useEffect(() => {
        if (objectState.id !== undefined) {
            setDialogEdit(true)
        }
    }, [objectState])
    useEffect(() => {
        if (filter === "" && data !== initData) {
            setData(initData)
        } else {
            setLoading(true)
            apiInstance.directories.getDirectorySearch({
                search: filter,
                offset: lazyParams.first,
                limit: lazyParams.rows
            })
                .then(res => res.data)
                .then(data => {
                    setTotalRecords(data.record_count!)
                    setData(data.record_list)
                    setLoading(false)
                })
                .catch(() => {
                    setData([])
                    setLoading(false)
                })
        }
    }, [filter])

    return (
        <>
            <AddDirectoryDialog visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} />
            <SearchDialog visible={dialogSearch} setVisible={setSearchDialog} filter={filter} setFilter={setFilter} />
            <EditDirectoryDialog data={objectState} setData={setObjectState} visible={dialogEdit} setVisible={setDialogEdit} refresh={refresh} />
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh} 
            onSubmit={onSubmit} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{objectDeleteState.name} </b> &nbsp;branch? </>}/>
            <ConfirmDialog className='test' />
            <div
                className="p-menuitem px-3 mx-0 "
                style={{
                    height: "60px", marginBottom: '-1rem'
                }}
            >
                <div className="p-menuitem-icon">
                    <img src={FoldersGroup} alt="Folders" />
                </div>
                <div className="p-menuitem-text text-v1 font-bold">{properties.title}</div>
            </div>
            <div className="grid grid-nogutter my-0 lg:my-3">
                <div className="col-1 hidden lg:flex"></div>
                <div className="col">
                    <div className='grid grid-nogutter'>

                        <Card style={{ width: '1466px', padding: '0' }}>
                            <div className="col" style={{ paddingTop: '0' }}>
                                <div className='w-full relative' style={{ height: "48px" }}>
                                    {/* <span className='text-v1 font-bold relative flex justify-content-center' style={{ top: '10px' }}>{properties.title}</span> */}
                                    <Button
                                        icon="mdi mdi-plus-circle-outline"
                                        // onClick={(event: any) => menu.current.toggle(event)}
                                        onClick={() => setDialogAdd(true)}
                                        style={{ left: '0rem', top: '5px' }}
                                        className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                                        aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add Branch</Button>
                                    <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px', width: '331px' }}>
                                        <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                            onChange={(e) => { setFilter(e.target.value) }} placeholder="     Keyword Search..." />
                                        <i className="pi pi-search gable-text-dark-blue" />
                                    </span>
                                    <Button
                                        icon="mdi mdi-magnify"
                                        style={{ right: '1rem', top: '5px' }}
                                        className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                        onClick={() => { setSearchDialog(true) }}
                                    />
                                </div>
                                <Menu model={items} popup ref={menu} id="popup_menu" />

                                <DataTable value={data}
                                    className="text-v2"
                                    lazy
                                    loading={loading}
                                    responsiveLayout="stack"
                                    paginator first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    totalRecords={totalRecords}
                                    onPage={(e: any) => {
                                        setLazyParams(e)
                                    }}
                                >
                                    <Column field="name" header="Group Name" style={{ width: '60%' }}></Column>
                                    <Column field="page_count" header="Total page usage" style={{ width: '28%' }}></Column>
                                    <Column body={(item) => (
                                        <span className="p-buttonset flex">
                                            <div
                                                className="align-items-center justify-content-center "
                                                style={{ width: "3.5rem" }}
                                            >
                                                <img
                                                    className='transition-duration-200 hover:bg-indigo-700 cursor-pointer'
                                                    src={require("../../assets/Group 36.png")}
                                                    alt="pencil"
                                                    style={{ borderRadius: '20px' }}
                                                    onClick={() => {
                                                        setObjectState(item)
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="align-items-center justify-content-center "
                                                style={{ width: "3.5rem" }}
                                            >
                                                <img
                                                    className='transition-duration-200 hover:bg-red-500 cursor-pointer'
                                                    src={require("../../assets/Group 32.png")}
                                                    style={{ borderRadius: '20px' }}
                                                    alt="bin"
                                                    onClick={() => {
                                                        setObjectDeleteState(item)
                                                        setDeleteDialog(true)
                                                    }}
                                                />
                                            </div>
                                            {/* <Button
                                                icon="mdi mdi-pencil"
                                                className="text-white p-button-warning p-button-sm"
                                                aria-label="Edit"
                                                tooltip='Edit User'
                                                onClick={() => {
                                                    setObjectState(item)
                                                }}
                                            /> */}
                                            {/* <Button
                                                icon="mdi mdi-delete"
                                                className="p-button-danger p-button-sm"
                                                aria-label="Delete"
                                                tooltip='Delete User'
                                                // disabled={item.id}
                                                onClick={() => {
                                                    setObjectDeleteState(item)
                                                }}
                                            /> */}
                                        </span>
                                    )}></Column>
                                </DataTable>
                            </div>
                        </Card>

                    </div>
                </div>
                <div className="col-1 hidden lg:flex"></div>
            </div>
        </>
    )
}

export default DirectoriesManagement;