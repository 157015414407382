import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { useToast } from "../../utils/Properties"
import "./style.css"

interface props {
    data: ModelsDirectory
    setData: Dispatch<SetStateAction<ModelsDirectory>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
}

const EditDirectoryDialog: FC<props> = ({ data, setData, visible, setVisible, refresh }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Edit Branch"
    const [successToast, errorToast, ,] = useToast();

    const defaultValues = {
        name: '',
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm({ defaultValues });

    const onSubmit = (submitData: { name: string }) => {
        apiInstance.directories.updateDirectory({
            ...data,
            name: submitData.name
        })
            .then(() => {
                successToast("Branch updated")
                setData({})
                setVisible(false)
                reset()
                refresh()
            })
            .catch(() => {
                errorToast("Branch update failed")
            })
    };

    useEffect(() => {
        setValue("name", data.name!)
    }, [data])

    return (
        <Dialog closable={false} draggable={false}
            visible={visible}
            className="custom-dialog p-0 m-0 w-23rem md:w-30rem"
            showHeader={false}
            onHide={() => {
                setVisible(false)
                setData({})
                reset()
            }}
        >
            <div className="w-full text-v3 font-bold py-2 px-4">
                <div className="grid grid-nogutter add-branch">
                    <div className="col-10 hidden md:flex relative">
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-10 flex md:hidden relative" style={{ top: '4px' }}>
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-2 text-right">
                        <Badge value="X"
                            className="transition-duration-300 cursor-pointer hover:bg-indigo-700 mt-1"
                            severity="danger"
                            onClick={() => {
                                setVisible(false)
                                setData({})
                                reset()
                            }}
                            style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}
                        ></Badge>
                    </div>
                </div>
            </div>
            <div className="py-2 px-4 w-full text-v2 font-bold">

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

                    <p className="mb-2 mt-0 ">Branch Name</p>
                    <Controller name="name" control={control} rules={{
                        required: 'Name is required.',
                        pattern: {
                            value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Ex/am/ple ,EX/AM/PLE1, ตัวอย่าง1'
                        }
                    }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field}
                            style={{ borderRadius: '30px', borderColor: '#A098AE', color: '#A098AE', marginBottom: '1rem' }}
                            autoFocus
                            className={!!errors.name ? 'p-invalid' : ''}
                        />
                    )} />

                    {(errors.name) &&
                        <div className="pl-2 mt-2">
                            <p className="p-error font-bold m-0">Hint</p>
                            <p className="m-0 p-error">{errors.name ? <small>{errors.name.message}</small> : <></>}</p>
                        </div>
                    }

                    <Button
                        label="Submit"
                        type="submit"
                        className="p-button mt-3 transition-duration-300 hover:bg-indigo-700"
                        style={{ borderRadius: '30px', width: '194px', marginLeft: '11.2rem', background: '#292666' }}

                    />
                </form>
            </div>
        </Dialog>
    )
}

export default EditDirectoryDialog;