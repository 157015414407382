import { useEffect, useRef, useState } from "react";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ModelsUpload, ModelsUser } from "../../api";

import SearchDialog from "../../components/SearchDialog";
import {
  useApiConnector,
  useSecureApiConnector,
} from "../../utils/ApiConnector";
import { useToast, useIssue, usePageWaiting } from "../../utils/Properties";

import { useClipboard } from "../../utils/CopyToClipboard";
import { useNavigate } from "react-router-dom";
import UserDownloadCsvDialog from "../../components/Dashboard/UserDownloadCSVDialog";
import { useFileWithType } from "../../utils/File";
import { Card } from "primereact/card";
import { Calendar, CalendarValueType } from "primereact/calendar";
import TotalDetailDialog from "../../components/Dashboard/TotalDetailDialog";
import {
  ChartHoverCard,
  DataVizPalette,
  DonutChart,
  IChartDataPoint,
  IChartProps,
  IVSChartDataPoint,
  IVerticalBarChartDataPoint,
  IVerticalStackedChartProps,
  PieChart,
  VerticalBarChart,
  VerticalStackedBarChart,
  getColorFromToken,
} from "@fluentui/react-charting";
import "./style.css";
import moment from "moment";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const ReportDashboard = () => {
  const _colors = [
    DataVizPalette.color1,
    DataVizPalette.color2,
    DataVizPalette.color3,
    DataVizPalette.color4,
    DataVizPalette.color5,
    DataVizPalette.color6,
    DataVizPalette.color7,
    DataVizPalette.color8,
    DataVizPalette.color9,
    DataVizPalette.color10,
    DataVizPalette.color11,
    DataVizPalette.color12,
    DataVizPalette.color13,
    DataVizPalette.color14,
    DataVizPalette.color15,
    DataVizPalette.color16,
    DataVizPalette.color17,
    DataVizPalette.color18,
    DataVizPalette.color19,
    DataVizPalette.color20,
    DataVizPalette.color21,
    DataVizPalette.color22,
    DataVizPalette.color23,
    DataVizPalette.color24,
    DataVizPalette.color25,
    DataVizPalette.color26,
    DataVizPalette.color27,
    DataVizPalette.color28,
    DataVizPalette.color29,
    DataVizPalette.color30,
    DataVizPalette.color31,
    DataVizPalette.color32,
    DataVizPalette.color33,
    DataVizPalette.color34,
    DataVizPalette.color35,
    DataVizPalette.color36,
    DataVizPalette.color37,
    DataVizPalette.color38,
    DataVizPalette.color39,
    DataVizPalette.color40,
  ];

  const randomColor = (skip: number[]): [number, string] => {
    let n = Math.floor(Math.random() * 41);

    while (!!skip.find((e) => e === n)) {
      n = Math.floor(Math.random() * 41);
    }

    return [n, getColorFromToken(_colors[n])];
  };

  const [pieData, setPieData] = useState([] as IChartDataPoint[]);
  const [stackData, setStackData] = useState(
    [] as IVerticalBarChartDataPoint[]
  );
  const [dataFound, setDataFound] = useState(false);
  const [analysedData, setAnalysedData] = useState<any>({});

  const apiInstance = useSecureApiConnector();
  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ] as CalendarValueType);
  const fetchData = () => {
    if (!!(dateRange as Date[])[0] && !!(dateRange as Date[])[1]) {
      setDataLoading(true);
      setDataFound(false);
      apiInstance.monitor
        .getSummary(
          moment((dateRange as Date[])[0]).format("YYYY-MM-DD"),
          moment((dateRange as Date[])[1]).format("YYYY-MM-DD")
        )
        .then((res) => res.data)
        .then((respData: any) => {
          if (!!respData.users_usage) {
            getPieData(respData.users_usage);
          }

          if (!!respData.templates_usage) {
            setDataFound(true);
            getStackData(respData.templates_usage);
          }

          setAnalysedData(respData);

          // setDataLoading(false);
        })
        .finally(() => {
          setDataLoading(false);
        })
        .catch((e) => {
          console.log(e);

          // navigate("/forbidden");
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, [dateRange]);

  const getPieData = (users_usage: any) => {
    let tmp = [] as IChartDataPoint[];
    let uniqueColor: number[] = [];
    users_usage.sort((a: any, b: any) => b.page_count - a.page_count);
    users_usage.forEach((e: any, i: number) => {
      let [n, color] = randomColor(uniqueColor);
      uniqueColor.push(n);

      tmp.push({
        legend: e.user_name,
        data: e.percent,
        color: color,
        yAxisCalloutData: `${e.page_count}`,
      });
    });

    setPieData(tmp);
  };

  const getStackData = (template_usage: any) => {
    let tmp = [] as IVerticalBarChartDataPoint[];
    template_usage.sort((a: any, b: any) => b.page_count - a.page_count);

    let uniqueColor: number[] = [];

    template_usage.forEach((e: any, i: number) => {
      let [n, color] = randomColor(uniqueColor);
      uniqueColor.push(n);

      tmp.push({
        x: e.template_name,
        y: e.page_count,
        color: color,
        legend: e.template_name,
      });

      // tmp.push({
      //   legend: e.user_name,
      //   data: 40,
      //   color: _colors[i],
      //   xAxisCalloutData: '2020/04/30',
      //   yAxisCalloutData: '61%',
      //   callOutAccessibilityData: { ariaLabel: 'Bar series 1-1 of 4, 2020/04/30 40%' },
      // } as IVSChartDataPoint);

      // console.log(e.page_count);
    });

    setStackData(tmp);
  };

  const [dataLoading, setDataLoading] = useState(false);

  return (
    <>
      <ConfirmDialog />

      <div className="grid grid-nogutter my-0 lg:my-3">
        <div className="col-1 hidden lg:flex"></div>
        <div className="col">
          <div className="grid grid-nogutter">
            <div className="col">
              <Card>
                <div className="w-full relative" style={{ height: "48px" }}>
                  <span
                    className="p-input-icon-right"
                    style={{ right: "0", top: "5px" }}
                  >
                    <span style={{ marginRight: "1rem" }}>Date Range:</span>
                    <Calendar
                      id="range"
                      value={dateRange}
                      onChange={(e) => {
                        console.log(e.value);

                        setDateRange(e.value);
                      }}
                      selectionMode="range"
                      style={{ width: "300px" }}
                      readOnlyInput
                    />
                  </span>
                </div>

                {dataLoading && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "10rem 0",
                      fontSize: "18pt",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div className="flex mb-3">
                        <Skeleton
                          shape="circle"
                          size="4rem"
                          className="mr-2"
                        ></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <div className="mt-2">Report Generating</div>
                    </div>
                  </div>
                )}

                {!dataLoading && (
                  <>
                    {dataFound && (
                      <div style={{ marginTop: "3rem" }}>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ width: "400px" }}
                            className="text-center"
                          >
                            <div
                              style={{ margin: "1rem 0", fontWeight: "bold" }}
                            >
                              Sum of quantity by user name
                            </div>
                            <DonutChart
                              width={400}
                              height={300}
                              data={
                                {
                                  chartData: pieData,
                                } as IChartProps
                              }
                              innerRadius={0}
                              hideLabels={false}
                              showLabelsInPercent={true}
                              onRenderCalloutPerDataPoint={(props: any) =>
                                props ? (
                                  <>
                                    <ChartHoverCard
                                      XValue={props.legend}
                                      Legend={"Percent"}
                                      YValue={`${(props.data as number).toFixed(
                                        1
                                      )} %`}
                                      color={getColorFromToken(
                                        DataVizPalette.highSuccess
                                      )}
                                    />
                                    <ChartHoverCard
                                      Legend={"Page count"}
                                      YValue={props.yAxisCalloutData}
                                      color={getColorFromToken(
                                        DataVizPalette.success
                                      )}
                                    />
                                  </>
                                ) : null
                              }
                            />
                          </div>
                          <div
                            style={{
                              width: "960px",
                              marginLeft: "4rem",
                              height: "400px",
                            }}
                            className="text-center"
                          >
                            <div
                              style={{ margin: "1rem 0", fontWeight: "bold" }}
                            >
                              Sum of quantity by input format
                            </div>
                            <VerticalBarChart
                              xAxisOuterPadding={1}
                              data={stackData}
                              width={960}
                              lineOptions={undefined}
                              legendProps={{
                                allowFocusOnLegends: true,
                              }}
                              onRenderCalloutPerDataPoint={(props: any) =>
                                props ? (
                                  <>
                                    <ChartHoverCard
                                      XValue={props.x}
                                      Legend={"Page count"}
                                      YValue={props.y}
                                      color={getColorFromToken(
                                        DataVizPalette.success
                                      )}
                                    />
                                  </>
                                ) : null
                              }
                              wrapXAxisLables={true}
                              enableReflow={true}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: "3rem", padding: "0 1rem" }}>
                          <div style={{ margin: "1rem 0", fontWeight: "bold" }}>
                            Template usage time (Unit: seconds)
                          </div>
                          <DataTable
                            value={analysedData.templates_usage}
                            scrollable
                            scrollHeight="flex"
                          >
                            <Column
                              field="template_name"
                              header="Name"
                            ></Column>
                            <Column
                              field="page_count"
                              header="Total page"
                              body={(item) =>
                                parseFloat(item.page_count).toLocaleString()
                              }
                            ></Column>
                            <Column
                              field="min_time"
                              header="Min"
                              body={(item) =>
                                parseFloat(item.min_time).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              }
                            ></Column>
                            <Column
                              field="max_time"
                              header="Max"
                              body={(item) =>
                                parseFloat(item.max_time).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              }
                            ></Column>
                            <Column
                              field="average_time"
                              header="Average"
                              body={(item) =>
                                parseFloat(item.average_time).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              }
                            ></Column>
                            <Column
                              field="percentile_90_time"
                              header="90th percentile"
                              body={(item) =>
                                parseFloat(
                                  item.percentile_90_time
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              }
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    )}

                    {!dataFound && (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          margin: "10rem 0",
                          fontSize: "18pt",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className="text-center">
                          <i className="mdi mdi-database-outline"></i> Data not
                          found
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
        <div className="col-1 hidden lg:flex"></div>
      </div>
    </>
  );
};

export default ReportDashboard;
