
// import { useCookies } from "react-cookie";
import "./styleMaintanace.css"
import { useApiConnector, useSecureApiConnector } from "../../utils/ApiConnector";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";


const Maintane = () => {
    // const [cookies, setCookie, removeCookie] = useCookies(["api-token", "token-expires", "need-chpasswd", "directory-id", "tenant-id",]);
    const apiInstance = useSecureApiConnector();
    const navigate = useNavigate()

    const pageStyles: React.CSSProperties = {
        background: '#EFE7C9',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    };

    const maintanaceTime = () => {
        apiInstance.webProperty.getWebPropertyByID("9a6f65d6-8dc7-40ca-b4e0-93515e9f6ed9")
            .then(res => res.data)
            .then(res => {
                if (res.value !== "maintanacing") {
                    navigate("/dashboard")
                }
            })
            .catch(() => {
                navigate("/dashboard")
            })
    }

    useEffect(() => {
        maintanaceTime()


    })


    return (
        <>
            <div style={pageStyles}>
                <div className="centered-content maintanaceText">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 202.24" className="maintenance-icon">
                        <path fill="#fff" d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z" />
                        <path fill="#fff" d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z" />
                    </svg>

                    <h1>We&rsquo;ll be back soon!</h1>
                    <div>
                        <p>Sorry for the inconvenience. We&rsquo;re performing some maintenance at the moment. </p>
                        <p>&mdash; The G-ABLE | Logistic Automation Team</p>
                    </div>
                    {/* <a href="sign-in" className="login-link" onClick={revokeAuthorized}>Go back to login page</a> */}
                </div>
            </div>

        </>
    );
};

export default Maintane;