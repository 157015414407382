import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ModelsDirectory,
  ModelsResultCsvFile,
  ModelsShipType,
  ModelsUpload,
} from "../../api";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import { useClipboard } from "../../utils/CopyToClipboard";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload, FileUploadHandlerParam } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFileWithType } from "../../utils/File";
import React from "react";

interface props {
  data: ModelsUpload;
  setData: Dispatch<SetStateAction<ModelsUpload>>;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}

const UserDownloadCsvDialog: FC<props> = ({
  data,
  setData,
  visible,
  setVisible,
  refresh,
}) => {
  const apiInstance = useSecureApiConnector();
  const title = `Download CSV, upload name : ${data.name?.length! > 10 ? data.name?.slice(0, 10) + "..." : data.name
    }`;
  const [successToast, errorToast, , warnToast] = useToast();
  const saveAsWithType = useFileWithType();
  const setPageWaiting = usePageWaiting();
  const clipboard = useClipboard();
  const fileUploadRef = useRef(null);
  const [allFile, setAllFile] = useState([]);
  const [downloadMode, setDownloadMode] = useState("separate");

  const defaultValues = {
    name: "",
    ship_type: null,
    format: null,
    mode: "Import",
  };

  const copy = (msg: string) => {
    clipboard(msg)
      .then(() => {
        successToast(`Copy ${msg} to clipboard`);
      })
      .catch(() => {
        errorToast(`Copy ${msg} to clipboard failed`);
      });
  };
  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
      </div>
    );
  };
  const downloadFile = () => {
    setPageWaiting(true);
    if (downloadMode === "separate") {
      apiInstance.uploads
        .downloadZipFile(data.id!, allFile)
        .then((res) => {
          saveAsWithType(
            `results-${data.name}_${data.ship_type!.name}_${data.mode!.name}_${data.in_format!.name
            }_${data.out_format!.name}.zip`,
            res.data,
            "application/zip"
          );
        })
        .catch(() => {
          errorToast("download file failed");
        })
        .finally(() => {
          setPageWaiting(false);
        });
    } else {
      apiInstance.uploads
        .downloadFile(data.id!, allFile)
        .then((res) => {
          saveAsWithType(
            `results-${data.name}_${data.ship_type!.name}_${data.mode!.name}_${data.in_format!.name
            }_${data.out_format!.name}.csv`,
            res.data,
            "text/csv"
          );
        })
        .catch(() => {
          errorToast("download file failed");
        })
        .finally(() => {
          setPageWaiting(false);
        });
    }
  };
  const countryBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div className="flex">
          <div>
            <img
              alt="flag"
              src={require("../../assets/File_dock (1).png")}
              className={`flag flag-${String(rowData.name).split(".")[0]}`}
              width={30}
            />
          </div>
          <div className="flex align-items-center justify-content-center">
            <span className="text-v5">
              {String(rowData.name).split(".")[0]}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <Dialog
        closable={false}
        draggable={false}
        visible={visible}
        className="custom-dialog p-0 m-0 md:w-30rem "
        showHeader={false}
        onHide={() => {
          setVisible(false);
          setAllFile([]);
          setData({});
        }}
      >
        <div className="w-full py-2 px-4">
          <div
            className="grid grid-nogutter "
            style={{
              border: "white",
              borderBottom: "#A098AE",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
          >
            <div className="col">
              <div className=" md:flex  relative text-v3 font-bold">
                {title.toLocaleUpperCase()}
              </div>
              <div
                className=" flex md:hidden relative text-v3 font-bold"
                style={{ top: "4px" }}
              >
                {title.toLocaleUpperCase()}
              </div>
            </div>
            <div className="col-1 text-right">
              <Badge
                value="X"
                className=" transition-duration-300 cursor-pointer hover:bg-red-400 mb-1"
                // severity="danger"
                style={{
                  fontSize: "0.5rem",
                  background: "#A098AE",
                  minWidth: "1rem",
                  height: "1rem",
                  lineHeight: "1rem",
                }}
                onClick={() => {
                  setVisible(false);
                  setAllFile([]);
                  setData({});
                }}
              ></Badge>
            </div>
          </div>
        </div>
        <div className="p-3">
          <DataTable
            className="mx-3 customcheckbox"
            value={data.csv_files}
            selectionMode="checkbox"
            selection={allFile}
            onSelectionChange={(e) => setAllFile(e.value)}
            dataKey="id"
            responsiveLayout="stack"
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3em" }}
            ></Column>
            <Column
              className="text-v2"
              field="company"
              header="File Name"
              body={countryBodyTemplate}
            ></Column>
          </DataTable>

          <div className="my-5 text-center">
            <RadioButton
              id="sep"
              name="mode"
              value="separate"
              className=""
              onChange={(e) => {
                setDownloadMode(e.value);
              }}
              checked={downloadMode === "separate"}
            />
            <label className="mx-2" htmlFor="im">
              Separate
            </label>

            <RadioButton
              id="combine"
              name="mode"
              value="combine"
              onChange={(e) => {
                setDownloadMode(e.value);
              }}
              checked={downloadMode === "combine"}
            />
            <label className="mx-2" htmlFor="ex">
              Combine File
            </label>
          </div>
          <div className="mb-3 text-center">
            <Button
              label="Download"
              className="w-10rem download-file"
              disabled={allFile.length === 0}
              onClick={() => {
                downloadFile();
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserDownloadCsvDialog;
