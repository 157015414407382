import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usePageWaiting, useToast } from "../utils/Properties";
import { Controller, useForm } from "react-hook-form";
import { useApiConnector, useSecureApiConnector } from "../utils/ApiConnector";
import { useCookies } from "react-cookie";
import { isStrongPassword, StrongPasswordValidator } from "../utils/StrongPasswordVelidator";
import { Divider } from "primereact/divider";

const ResetPassword = () => {
    const [needChPasswd, setNeedChPasswd] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["api-token", "need-chpasswd"])
    const [successToast, errorToast, ,] = useToast();
    const setPageWaiting = usePageWaiting();
    const apiSecureInstance = useSecureApiConnector();
    const apiUnsecureInstance = useApiConnector();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [foundSecret, setFoundSecret] = useState(false)
    const secret = searchParams.get("secret")

    const defaultValues = {
        password: '',
        passwordAgain: '',
    }
    const { control, watch, formState: { errors }, handleSubmit, getValues, reset } = useForm({ defaultValues });

    const chpasswd = (data: any) => {
        setPageWaiting(true)
        apiSecureInstance.users.resetPasswordUser({
            secret: secret!,
            new_password: data.password,
        })
            .then(() => {
                successToast('password changed');
                removeCookie("api-token")
                removeCookie("need-chpasswd")
                setNeedChPasswd(false)
                reset();
                setPageWaiting(false)
                navigate("/sign-in")
            })
            .catch(() => {
                errorToast('change password failed');
                setPageWaiting(true)
            })

    };

    useEffect(() => {
        if (secret) {
            apiUnsecureInstance.users.checkResetSecret(secret)
                .then(() => {
                    setFoundSecret(true)
                })
        }
    }, [secret])

    

    return (
        <>
            <div className="grid grid-nogutter p-0 h-screen bg-bluegray-50">
                <div className="col-7 overflow-hidden p-0 hidden lg:flex">

                    <img
                        src={require("../assets/bg-01.jpeg")}
                        className="relative ml-auto block w-full h-full"
                        style={{
                            padding: 0,
                            clipPath: "polygon(0 0, 55% 0, 100% 100%, 45% 100%)",
                            objectFit: 'cover'
                        }}
                    />
                </div>
                <div className="col p-8 text-left flex align-items-center justify-content-center">
                    <section style={{ maxWidth: '400px' }}>
                        <span className="block text-6xl gable-text-orange font-bold mb-1">
                            G-RPA Portal
                        </span>

                        {!foundSecret &&
                            <>
                                <p className="text-center p-3 border-round-2xl bg-blue-50 text-3xl gable-text-gray"> Not Authorized! </p>
                            </>
                        }
                        {foundSecret &&
                            <>
                                <p className="mt-0 mb-4 gable-text-gray line-height-3">

                                    Bring Your New Password

                                </p>
                                <form onSubmit={handleSubmit(chpasswd)} className="p-fluid" style={{ maxWidth: '400px' }} >

                                    <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                        <div className="p-inputgroup m-2">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span>
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password"
                                                feedback={false}
                                                className={!!errors.password ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />

                                    <Controller name="passwordAgain" control={control} rules={{
                                        required: 'Password Agian is required.',
                                        validate: (passwd) => (getValues("password") == passwd)
                                    }} render={({ field, fieldState }) => (
                                        <div className="p-inputgroup m-2">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span>
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password Again"
                                                feedback={false}
                                                className={!!errors.passwordAgain ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />


                                    <Button
                                        label="Set Password"
                                        type="submit"
                                        className="p-button m-2"
                                        disabled={!(isStrongPassword(watch().password) && watch().password == watch().passwordAgain)}
                                    />


                                </form>
                                <StrongPasswordValidator password={getValues("password")} />
                                <Divider />
                            </>
                        }

                        {(errors.password || errors.passwordAgain) &&
                            <div className="pl-2">
                                <p className="p-error font-bold">Hint</p>
                                <p className="m-0 p-error">{errors.password ? <small>{errors.password.message}</small> : <></>}</p>
                                <p className="m-0 p-error">{errors.passwordAgain ? <small>{errors.passwordAgain.message ? errors.passwordAgain.message : "Password not match"}</small> : <></>}</p>
                            </div>
                        }


                    </section>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
