import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { Tag } from "primereact/tag"
import { ModelsDirectory, ModelsShipType, ModelsUpload } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting, useToast } from "../../utils/Properties"
import "./style.css"
import { FileUpload, FileUploadHandlerParam, FileUploadRemoveParams } from "primereact/fileupload"

interface props {
    data: ModelsUpload
    setData: Dispatch<SetStateAction<ModelsUpload>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
}

const UserAddFileDialog: FC<props> = ({ data, setData, visible, setVisible, refresh }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Add file"
    const [successToast, errorToast, , warnToast] = useToast();
    const setPageWaiting = usePageWaiting()
    const chooseOptions = { icon: '', iconOnly: false, className: 'custom-choose-btn p-button-rounded p-button-outlined browse-custom' };
    const fileUploadRef = useRef(null);
    const [allFile, setAllFile] = useState([] as File[])

    // MB
    const mb = 1000000
    const maxUpload = 20 * mb
    const [totalSize, setTotalSize] = useState(0)
    const getSize = (s: number) => (s / mb)

    const addFileSubmit = () => {
        if (totalSize === 0) {
            warnToast("Please upload file")
        } else {
            if (totalSize > maxUpload) {
                errorToast("File upload size exceded")
            } else {
                setPageWaiting(true)
                apiInstance.uploads.addFile(data.id!, allFile)
                    .then(res => res.data)
                    .then(res => {
                        if (!!res.have_duplicated && res.have_duplicated) {
                            if (res.file_name) {
                                res.file_name.forEach(e => {
                                    errorToast(`duplicated filename ${e}`, 15000)
                                })
                            }
                        } else {
                            successToast("File uploaded")
                        }
                        setVisible(false)
                        setAllFile([])
                        setData({})
                        setTotalSize(0)
                        refresh()
                    })
                    .catch(() => {
                        errorToast("File upload failed")
                    })
                    .finally(() => {
                        setPageWaiting(false)
                    })
            }
        }
    }
    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const formatedValue = fileUploadRef && fileUploadRef.current ? (fileUploadRef.current as any).formatSize(totalSize) : '0 B';
        return (
            <div className={`${className} header-file-upload`} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                <div className="w-full srelative font-bold text-right"><span className={getSize(totalSize) < getSize(maxUpload) ? "text-green-700" : "text-red-600"}>{formatedValue}</span>/{getSize(maxUpload)}MB</div>
            </div>
        );
    }
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" >
                <i className="pi pi-file mt-3 p-5" style={{ 'fontSize': '2.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop Here</span>
            </div>
        )
    }
    const customUploader = (e: FileUploadHandlerParam) => {
        setAllFile(e.files)

        let total = 0
        e.files.forEach(f => {
            total += f.size
        })
        setTotalSize(total)
    }

    return (
        <>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-50rem md:w-30rem"
                showHeader={false}
                onHide={() => {
                    setVisible(false)
                    setAllFile([])
                    setData({})
                    setTotalSize(0)
                }}
            >
                <div className="w-full py-2 px-4">
                    <div className="grid grid-nogutter" style={{ border: 'white', borderBottom: '#A098AE', borderWidth: '2px', borderStyle: 'solid' }}>
                        <div className="text-v3 font-bold md:flex relative">
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="text-v3 font-bold flex md:hidden relative" style={{ top: '4px' }}>
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right" style={{ marginLeft: '22rem' }}>
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                // severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    setAllFile([])
                                    setData({})
                                    setTotalSize(0)
                                }}
                                style={{ fontSize: '0.5rem', background: '#A098AE', minWidth: '1rem', height: '1rem', lineHeight: '1rem' }}
                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="w-full py-2 px-4 ">
                    <p className="mb-2 mt-2 font-bold text-17 ">Upload</p>
                    <FileUpload
                        ref={fileUploadRef}
                        customUpload multiple
                        auto
                        accept="image/*,application/pdf"
                        maxFileSize={(maxUpload)}
                        uploadHandler={customUploader}
                        chooseLabel="Browse"
                        chooseOptions={chooseOptions}
                        headerTemplate={headerTemplate} emptyTemplate={emptyTemplate}
                        itemTemplate={(file: any, props) => {
                            return (
                                <>
                                    <div className="flex align-items-center flex-wrap" style={{ color: '#A098AE' }}>
                                        <div className="flex align-items-center" style={{ width: '40%' }}>
                                            {file.type !== "application/pdf" &&
                                                <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                                            }
                                            {file.type === "application/pdf" &&
                                                <i className="pi pi-file text-2xl"></i>
                                            }
                                            <span className="flex flex-column text-left ml-3" >
                                                {file.name}
                                                <small>{new Date().toLocaleDateString()}</small>
                                            </span>
                                        </div>
                                        <Tag value={props.formatSize} className="px-3 py-2 " style={{ color: '#A098AE', background: 'white' }} />
                                        <div
                                            className="align-items-center justify-content-center "
                                            style={{ width: "3.5rem", paddingLeft: '7.5rem' }}
                                        >
                                            <img
                                                className="transition-duration-200 cursor-pointer  hover:bg-red-500"
                                                src={require("../../assets/Group 32.png")}
                                                style={{ borderRadius: '20px', width: '30px' }}
                                                alt="bin"
                                                onClick={(e) => {
                                                    setTotalSize(totalSize - file.size);
                                                    props.onRemove(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        }
                    />


                    <div className=" text-center">
                        <Button
                            label="Submit"
                            type="button"
                            style={{
                                width: '200px', borderRadius: '14px', borderColor: '#292666', background: '#292666'
                            }}
                            className="p-button p-button-secondary mt-3"
                            onClick={() => {
                                addFileSubmit()
                            }}
                        />

                    </div>

                </div>
            </Dialog>
        </>
    )
}

export default UserAddFileDialog;