import { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import "../../global.css";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { useCookies } from "react-cookie";
import {
  useCheckAdmin,
  useDirectory,
  useIssue,
  usePageWaiting,
  useToast,
} from "../../utils/Properties";
import { Chip } from "primereact/chip";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import {
  isStrongPassword,
  StrongPasswordValidator,
} from "../../utils/StrongPasswordVelidator";
import { Controller, useForm } from "react-hook-form";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { ModelsDirectory, ModelsRole } from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import logo from "../../assets/logo.png";
import FoldersGroup from "../../assets/Folders_group_light.png";
import Isolation from "../../assets/Isolation_Mode.png";
import { useFileWithType } from "../../utils/File";

const getCookie = (cookieName: string) => {
  let cookie = {} as any;
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};

const Navbar = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "api-token",
    "token-expires",
    "need-chpasswd",
    "directory-id",
    "tenant-id",
  ]);
  const navigate = useNavigate();
  const apiInstance = useSecureApiConnector();
  const issue = useIssue();
  const [menu, setMenu] = useState<any[]>([]);
  const checkAdmin = useCheckAdmin();
  const location = useLocation();
  const [showMessage, setShowMessage] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleChangePasswd, setVisibleChangePasswd] = useState(false);
  const [successToast, errorToast, ,] = useToast();
  const setPageWaiting = usePageWaiting();
  const saveAsWithType = useFileWithType();

  const [arrayBuffer, setArrayBuffer] = useState(null);

  // Base64 string
  function isValidBase64(str: any) {
    const base64Pattern =
      /^(?:[A-Za-z0-9+/]{4})*?(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64Pattern.test(str);
  }

  function base64ToArrayBuffer(base64: any) {
    // Validate the base64 string
    if (!isValidBase64(base64)) {
      console.error("Invalid base64 string");
      return null;
    }

    // Clean the base64 string
    base64 = base64.replace(/[^A-Za-z0-9+/=]/g, ""); // Remove non-base64 characters
    while (base64.length % 4 !== 0) {
      base64 += "="; // Add padding if necessary
    }

    try {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    } catch (error) {
      console.error("Failed to convert base64 to ArrayBuffer", error);
      return null;
    }
  }

  const decodeBase64ToImageFile = (base64String: string, fileName: string) => {
    const binaryString = atob(base64String.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const imageBlob = new Blob([bytes], { type: "application/pdf" });
    return new File([imageBlob], fileName, { type: "application/pdf" });
  };

  const downloadFile = () => {
    setPageWaiting(true);
    apiInstance.webProperty
      .getWebPropertyByID("01fcb564-8e6d-41ac-98d3-cccac9a2c07d")
      .then((res) => res.data)
      .then(async (res) => {
        const buffer = decodeBase64ToImageFile(
          res.value.split("|")[1],
          res.value.split("|")[0]
        );

        saveAsWithType(
          `user_manual.pdf`,
          await buffer.arrayBuffer(),
          "application/pdf"
        );
      })
      .catch((e) => {
        console.log(e);

        errorToast("download file failed");
      })
      .finally(() => {
        setPageWaiting(false);
      });
  };

  const items = [
    {
      label: "Branch Management",
      template: (
        <div
          className="p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100"
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/branch-management"
                ? "4px solid #F79433"
                : "",
          }}
          onClick={() => {
            navigate("/branch-management");
          }}
        >
          <div className="p-menuitem-icon">
            <img src={FoldersGroup} alt="Folders" />
          </div>
          <div className="p-menuitem-text">Branch Management</div>
        </div>
      ),
      url: "/branch-management",
    },
    {
      label: "User Management",
      template: (
        <div
          className="p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100 "
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/user-management"
                ? "4px solid #F79433"
                : "",
          }}
          onClick={() => {
            navigate("/user-management");
          }}
        >
          <div className="p-menuitem-icon">
            <img src={Isolation} alt="UserManagement" />
          </div>
          <div className="p-menuitem-text">User Management</div>
        </div>
      ),
      url: "/user-management",
    },
    {
      label: "Dashboard",
      template: (
        <div
          className="p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100 "
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/report-dashboard"
                ? "4px solid #F79433"
                : "",
          }}
          onClick={() => {
            navigate("/report-dashboard");
          }}
        >
          <div className="p-menuitem-icon">
            <i className="mdi mdi-view-dashboard"></i>
          </div>
          <div className="p-menuitem-text">Report Dashboard</div>
        </div>
      ),

      url: "/report-dashboard",
    },
    {
      label: "Support",
      template: (
        <div
          className="p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100 "
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/support" ? "4px solid #F79433" : "",
          }}
          onClick={() => {
            navigate("/support");
          }}
        >
          <div className="p-menuitem-icon">
            <i className="mdi mdi-book-multiple"></i>
          </div>
          <div className="p-menuitem-text">Support</div>
        </div>
      ),

      url: "/support",
    },
    {
      label: "Template Management",
      template: (
        <div
          className="p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100 "
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/admin-template-management"
                ? "4px solid #F79433"
                : "",
          }}
          onClick={() => {
            navigate("/admin-template-management");
          }}
        >
          <div className="p-menuitem-icon">
            <i className="mdi mdi-book-multiple"></i>
          </div>
          <div className="p-menuitem-text">Template Enablement</div>
        </div>
      ),

      url: "/admin-template-management",
    },
    {
      label: "Pre-Defined Doc AI fields",
      template: (
        <div

          className={`p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100 ${issue.directory?.tenant?.docoment_ai_flag ? "" : "bg-gray-100"}`}
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/predefined-document-ai"
                ? "4px solid #F79433"
                : "",
          }}
          onClick={() => {
            if (issue.directory?.tenant?.docoment_ai_flag) {
              navigate("/predefined-document-ai");

            }
          }}
        >
          <div className="p-menuitem-icon">
            <i className="mdi mdi-file-document-multiple"></i>
          </div>
          <div className="p-menuitem-text">Pre-Defined Doc AI fields</div>
        </div>
      ),

      url: "/predefined-document-ai",
    },
  ];
  const itemUser = [
    {
      label: "Support",
      template: (
        <div
          className="p-menuitem transition-duration-200 cursor-pointer px-3 mx-0 hover:bg-gray-100 "
          style={{
            height: "60px",
            borderBottom:
              location.pathname === "/support" ? "4px solid #F79433" : "",
          }}
          onClick={() => {
            navigate("/support");
          }}
        >
          <div className="p-menuitem-icon">
            <i className="mdi mdi-book-multiple"></i>
          </div>
          <div className="p-menuitem-text">Support</div>
        </div>
      ),

      url: "/support",
    },
  ];
  const defaultValues = {
    oldPassword: "",
    password: "",
    passwordAgain: "",
  };
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  function capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  const end = () => (
    <>
      <div className="flex">
        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-question-circle"
            className="p-button-rounded p-button-text "
            tooltip="User Manual"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            aria-label="Submit"
            style={{ color: "#323130" }}
            onClick={downloadFile}
          />
        </div>
        <div
          className="cursor-pointer text-v2 mx-0 p-menuitem px-3 hover:bg-gray-100"
          onClick={() => setVisibleRight(true)}
          style={{ height: "60px", borderRadius: "30px" }}
        >
          <div>
            <div
              className={`flex align-items-center justify-content-center ${showMessage ? "line-button" : ""
                }`}
              style={{ width: "2.5rem", height: "4rem" }}
            >
              <img src={require("../../assets/User_cicrle.png")} alt="avatar" />
            </div>
          </div>
          <span className="text-center ">
            {issue.first_name ? capitalizeFirstLetter(issue.first_name!) : `GA`}
          </span>
        </div>
      </div>
    </>
  );
  const start = () => (
    <>
      <img
        className="cursor-pointer mr-4"
        src={logo}
        alt="Logo"
        style={{ marginLeft: "-1rem" }}
        onClick={() => navigate("/dashboard")}
      />
    </>
  );
  const revokeAuthorized = () => {
    removeCookie("api-token");
    removeCookie("token-expires");
    removeCookie("need-chpasswd");
    navigate("/sign-in");
  };
  const chpasswd = (data: any) => {
    apiInstance.users
      .changePasswordUser({
        u_id: issue.id,
        new_password: data.password,
        old_password: data.oldPassword,
      })
      .then(() => {
        successToast("password changed");
        reset();
        setVisibleChangePasswd(false);
      })
      .catch(() => {
        errorToast("change password failed");
      });
  };

  return (
    <>
      <Dialog
        showHeader={false}
        closable={false}
        visible={visibleChangePasswd}
        onHide={() => {
          setVisibleChangePasswd(false);
          reset();
        }}
        className="custom-dialog "
      >
        <>
          <div className="w-full py-2 px-4">
            <div
              className="grid grid-nogutter"
              style={{
                border: "white",
                borderBottom: "#A098AE",
                borderWidth: "2px",
                borderStyle: "solid",
              }}
            >
              <div className="text-v3 font-bold md:flex relative">
                {"Change password".toUpperCase()}
              </div>
              <div
                className="text-v3 font-bold flex md:hidden relative"
                style={{ top: "4px" }}
              >
                {"Change password".toUpperCase()}
              </div>
              <div className="col-2 text-right">
                <Badge
                  value="X"
                  className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                  severity="danger"
                  onClick={() => {
                    setVisibleChangePasswd(false);
                    reset();
                  }}
                  style={{
                    fontSize: "0.5rem",
                    background: "#A098AE",
                    minWidth: "1rem",
                    height: "1rem",
                    lineHeight: "1rem",
                    marginLeft: "20rem",
                  }}
                ></Badge>
              </div>
            </div>
          </div>
          <div className="p-0">
            <form
              onSubmit={handleSubmit(chpasswd)}
              className="p-fluid p-3 text-v3"
              style={{ maxWidth: "500px", marginLeft: "2rem" }}
            >
              <Controller
                name="oldPassword"
                control={control}
                rules={{ required: "Current password is required." }}
                render={({ field, fieldState }) => (
                  <div className="m-2">
                    {/* <span className="p-inputgroup-addon">
                      <i className="pi pi-key" />
                    </span> */}
                    <Password
                      id={field.name}
                      {...field}
                      placeholder="Current Password"
                      feedback={false}
                      className={!!errors.oldPassword ? "p-invalid" : ""}
                      toggleMask
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                )}
              />

              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required.",
                  validate: (passwd) => watch().oldPassword !== passwd,
                }}
                render={({ field, fieldState }) => (
                  <div className="m-2">
                    <Password
                      id={field.name}
                      {...field}
                      placeholder="Password"
                      feedback={false}
                      className={!!errors.password ? "p-invalid" : ""}
                      toggleMask
                    />
                  </div>
                )}
              />

              <Controller
                name="passwordAgain"
                control={control}
                rules={{
                  required: "Password Agian is required.",
                  validate: (passwd) => watch().password == passwd,
                }}
                render={({ field, fieldState }) => (
                  <div className=" m-2">
                    <Password
                      id={field.name}
                      {...field}
                      placeholder="Password Again"
                      feedback={false}
                      className={!!errors.passwordAgain ? "p-invalid" : ""}
                      toggleMask
                    />
                  </div>
                )}
              />

              <Button
                label="Set Password"
                type="submit"
                className="p-button m-2"
                style={{
                  background: "#292666",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                }}
                disabled={
                  !(
                    isStrongPassword(watch().password) &&
                    !!watch().oldPassword &&
                    watch().password == watch().passwordAgain
                  )
                }
              />
            </form>
            <div className="p-3">
              <StrongPasswordValidator password={getValues("password")} />

              {(errors.oldPassword ||
                errors.password ||
                errors.passwordAgain) && (
                  <>
                    <Divider />
                    <div className="pl-2">
                      <p className="p-error font-bold">Hint</p>
                      <p className="m-0 p-error">
                        {errors.oldPassword ? (
                          <small>{errors.oldPassword.message}</small>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="m-0 p-error">
                        {errors.password ? (
                          <small>
                            {errors.password.message
                              ? errors.password.message
                              : "New password cannot be the current password"}
                          </small>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="m-0 p-error">
                        {errors.passwordAgain ? (
                          <small>
                            {errors.passwordAgain.message
                              ? errors.passwordAgain.message
                              : "Password not match"}
                          </small>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </>
                )}
            </div>
          </div>
        </>
      </Dialog>

      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
        className="text-v3"
      >
        <div className="text-center">
          <Avatar
            label={
              issue && issue.first_name && issue.last_name
                ? `${issue.first_name![0].toLocaleUpperCase()}${issue.last_name![0].toLocaleUpperCase()}`
                : `GA`
            }
            className="mb-3 gable-bg-orange text-white"
            size="xlarge"
            shape="circle"
          />
          <p className="m-0">
            {issue.first_name} {issue.last_name}
          </p>
          <p className="m-0 text-sm">{issue.email}</p>
        </div>
        <Divider align="left" type="dashed" className="mt-4 mb-0">
          <span className="text-sm">Informations</span>
        </Divider>
        <div className="mt-3 pl-4 text-sm">
          <p className="mx-0 mb-1 mt-0 gable-text-gray">
            <b>User Id:</b> {!!issue.id ? issue.id : "xxxx-xxxx-xxxx-xxxx-xxxx"}
          </p>
          <p className="mx-0 mb-1 mt-0 gable-text-gray">
            <b>Organization:</b>{" "}
            {!!issue.directory?.tenant?.name
              ? issue.directory?.tenant?.name
              : "Default"}
          </p>
          <p className="mx-0 mb-1 mt-0 gable-text-gray">
            <b>Branch:</b>{" "}
            {!!issue.directory?.name ? issue.directory?.name : "Default"}
          </p>
          <Divider />
        </div>

        <Divider align="left" type="dashed" className="mt-4 mb-0">
          <span className="text-sm">Preferences</span>
        </Divider>
        <div className="mt-4 text-center">
          <span className="p-buttonset">
            <Button
              label="Change Password"
              className=""
              onClick={() => {
                setVisibleChangePasswd(true);
              }}
              style={{
                background: "#292666",
                fontFamily: "Roboto",
                fontSize: "14px",
                borderRadius: "20px",
              }}
            />
            <Button
              label="Sign Out"
              className="text-xs p-button-danger"
              icon="pi pi-sign-out"
              onClick={() => {
                revokeAuthorized();
              }}
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                borderRadius: "20px",
                width: "141px",
              }}
            />
          </span>
        </div>
      </Sidebar>

      <Menubar
        className="py-0 px-3 mt-3 h-4rem sticky top-0 text-v2 font-bold "
        start={start}
        model={checkAdmin ? items : itemUser}
        hidden={false}
        end={end}
        style={{ minWidth: "1490px", zIndex: 100 }}
      />
    </>
  );
};

export default Navbar;
