import { useContext, createContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApiConnector, useSecureApiConnector } from "./ApiConnector";
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ModelsDirectory, ModelsRole, ModelsUser } from "../api";
import { useCookies } from "react-cookie";
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';

type ContextProps = {
    issue: ModelsUser
    directory: ModelsDirectory
    isAdmin: boolean
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>
    setDirectory: React.Dispatch<React.SetStateAction<ModelsDirectory>>
    toast: [(msg: string, timeout?: number) => void, (msg: string, timeout?: number) => void, (msg: string, timeout?: number) => void, (msg: string, timeout?: number) => void]
    isAuthorized: boolean
}

export const AppPropertiesContext = createContext<ContextProps | null>(null);

interface AppProperties {
    children: any
}

const AppPropertiesProvider: React.FC<AppProperties> = ({ children }) => {
    const [, , removeCookie] = useCookies(["api-token"])
    const [issue, setIssue] = useState({} as ModelsUser)
    const [directory, setDirectory] = useState({} as ModelsDirectory)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const [pageWaiting, setPageWaiting] = useState(false)
    const apiInstance = useSecureApiConnector();
    const toast = useRef({} as any)
    const [isAdmin, setIsAdmin] = useState(false)
    const [currentTime, setCurrentTime] = useState(new Date());
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayImage, setDisplayImage] = useState("");

    const successToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'success', summary: 'Success', detail: msg });
    }
    const errorToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'error', summary: 'Error', detail: msg });
    }
    const warnToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'warn', summary: 'Warning', detail: msg });
    }
    const infoToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'info', summary: 'Information', detail: msg });
    }

    // const announcementToast = (msg: string, timeout?: number) => {
    //     toast.current.show({ life: timeout ? timeout : 3000, severity: 'info', summary: 'Information', detail: msg });
    // }

    const clear = () => {
        toast.current.clear();
    }


    const maintanaceTime = () => {
        apiInstance.webProperty.getWebPropertyByID("9a6f65d6-8dc7-40ca-b4e0-93515e9f6ed9")
            .then(res => res.data)
            .then(res => {
                if (res.value === "maintanacing") {
                    navigate("/Maintenance")

                }
            })
    }

    const announcement = () => {

        apiInstance.webProperty.getWebPropertyByID("67358b76-f49d-4189-9239-91d1ebceec2a")
            .then(res => res.data)
            .then(res => {
                const b = new Date(res.started_at!);
                const c = new Date(res.expired_at!);
                const isInRange = currentTime >= b && currentTime <= c;
                setDisplayImage(res.value)
                // const timeDifference = c.getTime() - b.getTime();
                if (isInRange) {
                    if (location.pathname === "/dashboard") {
                        setDisplayBasic(true)
                    }
                }
            })
    }

    const getCredential = () => {
        apiInstance.authentication.getCredential()
            .then(res => res.data)
            .then(res => {
                setIssue(res)
                setIsAuthorized(true)
                if (location.pathname === "/sign-in") {
                    navigate("/dashboard")
                }

            })
            .catch(() => {
                removeCookie("api-token")
                setIsAuthorized(false)
                navigate("/sign-in")
            })
    }
    const checkAdmin = () => {
        setPageWaiting(true)
        apiInstance.roles.getUserRoles()
            .then(res => res.data)
            .then(data => {
                setIsAdmin(data.name === "administrator")
            })
            .catch(() => { })
            .finally(() => {
                setPageWaiting(false)
            })

    }


    useEffect(() => {
        if (location.pathname !== "/reset-password") {
            getCredential()
            checkAdmin()
            // maintanaceTime()
            if (location.pathname === "/dashboard") {
                setDisplayBasic(false)
                announcement()

            }
        }


    }, [location.pathname])

    return (

        <AppPropertiesContext.Provider value={{
            isAuthorized: isAuthorized, setWaiting: setPageWaiting, issue: issue, toast: [
                successToast, errorToast, infoToast, warnToast,
            ], directory: directory, setDirectory: setDirectory, isAdmin: isAdmin
        }}>
            <Toast ref={toast} style={{
                zIndex: "1000000 !importnant"
            }} />
            <Dialog header="Announce!" visible={displayBasic} style={{ width: '50vw' }} onHide={() => { setDisplayBasic(false) }} draggable={false} >
                <Image src={displayImage} alt="Image" width="100%" preview />
            </Dialog>
            {pageWaiting &&
                <div className="bg-gray-50 h-screen w-screen" style={{ position: 'fixed', zIndex: 10000, opacity: 0.8 }}>
                    <div className="flex align-items-center h-screen justify-content-center">
                        <span className="text-center">
                            <div>
                                <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                            <p className="text-2xl">Wait a minutes</p>
                        </span>
                    </div>


                </div>
            }
            {children}
        </AppPropertiesContext.Provider>
    );
}

export default AppPropertiesProvider;
export const useIssue = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.issue;
}

export const useDirectory = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return [appContext.directory, appContext.setDirectory] as [ModelsDirectory, React.Dispatch<React.SetStateAction<ModelsDirectory>>];
}

export const useToast = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.toast;
}

export const useIsAuthorized = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.isAuthorized;
}

export const usePageWaiting = (): React.Dispatch<React.SetStateAction<boolean>> => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.setWaiting;
}

export const useCheckAdmin = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.isAdmin;

}