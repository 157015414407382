import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Fragment, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ModelsDirectory, ModelsUpload, ModelsUser } from "../../api";
import AddUserDialog from "../OrgManagement/AddUserDialog";
import EditUserDialog from "../OrgManagement/EditUserDialog";
import SearchDialog from "../SearchDialog";
import { useSecureApiConnector } from "../../utils/ApiConnector";
import {
  useToast,
  useIssue,
  usePageWaiting,
  useCheckAdmin,
} from "../../utils/Properties";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { useClipboard } from "../../utils/CopyToClipboard";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { Chip } from "primereact/chip";
import UserDownloadCsvDialog from "./UserDownloadCSVDialog";
import { useFileWithType } from "../../utils/File";
import UserDownloadSourceDialog from "./UserDownloadSourceDialog";
import { Card } from "primereact/card";
import FailedCaseDialog from "./FailedCaseDialog";
import CustomDeleteDialog from "../OrgManagement/CustomDeleteDialog";
import { Tag } from "primereact/tag";

const AdminDashboard = () => {
  const setPageWaiting = usePageWaiting();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogDownloadCsv, setDialogDownloadCsv] = useState(false);
  const [dialogSearch, setSearchDialog] = useState(false);
  const [dialogFailState, setDialogFailState] = useState(false);
  const [objectFailState, setObjectFailState] = useState({} as ModelsUpload);
  const [objectDeleteState, setObjectDeleteState] = useState(
    {} as ModelsDirectory
  );
  const [objectDownloadCsvState, setObjectDownloadCsvState] = useState(
    {} as ModelsUpload
  );
  const [successToast, errorToast, ,] = useToast();
  const [initData, setInitData] = useState([] as ModelsUpload[]);
  const [data, setData] = useState([] as ModelsUpload[]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState("");
  const apiInstance = useSecureApiConnector();
  const location = useLocation();
  const [firstTrigger, setFirstTrigger] = useState(false);
  const isAdmin = useCheckAdmin();
  const navigate = useNavigate();
  const saveAsWithType = useFileWithType();
  const [objectDownloadSourceState, setObjectDownloadSourceState] = useState(
    {} as ModelsUpload
  );
  const [dialogDownloadSource, setDialogDownloadSource] = useState(false);
  const [dialogDelete, setDeleteDialog] = useState(false);
  const [uploadData, setUploadData] = useState({} as ModelsUpload);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [metric, setMetric] = useState(
    {} as {
      this_month: number;
      total: number;
      total_user?: number;
    }
  );

  const fetchData = () => {
    apiInstance.uploads
      .getMetric()
      .then((res) => res.data)
      .then((data) => {
        setMetric(data);
      })
      .catch(() => {
        navigate("/forbidden");
      });

    apiInstance.uploads
      .getOffset(lazyParams.first, lazyParams.rows)
      .then((res) => res.data)
      .then((data) => {
        setInitData(data.record_list);
        setData(data.record_list);
        setTotalRecords(data.record_count!);
        setLoading(false);
      })
      .catch(() => {
        navigate("/forbidden");
      });
  };
  const refresh = () => {
    setTriggerFetch(!triggerFetch);
  };
  const paginatorTemplate = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </Fragment>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };
  const downloadSource = (data: ModelsUpload) => {
    setPageWaiting(true);
    apiInstance.uploads
      .downloadZipFile(data.id!, data.files!)
      .then((res) => {
        saveAsWithType(
          `sources-${data.name!}.zip`,
          res.data,
          "application/zip"
        );
      })
      .catch(() => {
        errorToast("download file failed");
      })
      .finally(() => {
        setPageWaiting(false);
      });
  };
  const onSubmit = (data: any) => {
    apiInstance.users
      .support(uploadData)
      .then(() => {
        successToast("Email sended");
        setObjectDeleteState({});
        setDeleteDialog(false);
        refresh();
      })
      .catch(() => {
        errorToast("Email send failed");
        setObjectDeleteState({});
      });
  };

  useEffect(() => {
    if (!firstTrigger) {
      setLoading(true);
      setInterval(() => {
        if (location.pathname === "/dashboard" && isAdmin) {
          refresh();
        }
      }, 10000);
      setFirstTrigger(true);
    }
  }, [firstTrigger]);
  useEffect(() => {
    // setLoading(true);
    fetchData();
  }, [triggerFetch]);
  useEffect(() => {
    setLoading(true);
    refresh();
  }, [lazyParams]);
  useEffect(() => {
    if (objectDownloadCsvState.id !== undefined) {
      setDialogDownloadCsv(true);
    }
  }, [objectDownloadCsvState]);
  useEffect(() => {
    if (filter === "" && data !== initData) {
      setData(initData);
    } else {
      setLoading(true);

      apiInstance.uploads
        .getSearch({
          search: filter,
          offset: lazyParams.first,
          limit: lazyParams.rows,
        })
        .then((res) => res.data)
        .then((data) => {
          setTotalRecords(data.record_count!);
          setData(data.record_list);
          setLoading(false);
        })
        .catch(() => {
          setData([]);
          setLoading(false);
        });
    }
  }, [filter]);
  useEffect(() => {
    if (objectDownloadSourceState.id !== undefined) {
      setDialogDownloadSource(true);
    }
  }, [objectDownloadSourceState]);

  return (
    <>
      <CustomDeleteDialog
        data={objectDeleteState}
        setData={setObjectDeleteState}
        visible={dialogDelete}
        setVisible={setDeleteDialog}
        refresh={refresh}
        onSubmit={onSubmit}
        meessage={<>Confirm to notification support team? </>}
        titlename="Support Confirmation"
        data2={uploadData}
      />
      <UserDownloadCsvDialog
        data={objectDownloadCsvState}
        setData={setObjectDownloadCsvState}
        visible={dialogDownloadCsv}
        setVisible={setDialogDownloadCsv}
        refresh={refresh}
      />
      <UserDownloadSourceDialog
        data={objectDownloadSourceState}
        setData={setObjectDownloadSourceState}
        visible={dialogDownloadSource}
        setVisible={setDialogDownloadSource}
        refresh={refresh}
      />
      <FailedCaseDialog
        data={objectFailState}
        setData={setObjectFailState}
        visible={dialogFailState}
        setVisible={setDialogFailState}
      />

      <ConfirmDialog />
      <SearchDialog
        visible={dialogSearch}
        setVisible={setSearchDialog}
        filter={filter}
        setFilter={setFilter}
      />

      <div className="grid grid-nogutter">
        <div className="col">
          <div className="mt-2 mb-4 font-bold text-2xl text-v1">Summary</div>
          <div className="grid p-0" style={{ flexWrap: "inherit" }}>
            <div
              className="surface-0 p-3 custom-border "
              style={{ height: "96px", width: "488px" }}
            >
              <div className="flex mb-1">
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "3.5rem", height: "4rem" }}
                >
                  <img
                    src={require("../../assets/File_dock.png")}
                    alt="filedock"
                  />
                </div>
                <div>
                  <span className="block gable-text-dark-blue font-medium text-v4 font-bold">
                    TOTAL PAGES
                  </span>
                  <span className="gable-text-dark-blue font-score font-bold">
                    {!!metric.total ? metric.total : 0}
                  </span>
                </div>
                <div>
                  <Button
                    label="view"
                    className="p-button p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      marginTop: "2rem",
                      marginLeft: "14rem",
                    }}
                    onClick={() => {
                      navigate("/admin-page-monitor");
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="surface-0 p-3 custom-border mx-3"
              style={{ height: "96px", width: "488px" }}
            >
              <div className="flex mb-1">
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "3.5rem", height: "4rem" }}
                >
                  <img
                    src={require("../../assets/File_dock.png")}
                    alt="filedock"
                  />
                </div>
                <div>
                  <span className="block gable-text-dark-blue font-medium sm:text-sm text-lg font-bold">
                    TOTAL PAGES OF THIS MONTH
                  </span>
                  <span className="gable-text-dark-blue font-score font-bold">
                    {!!metric.this_month ? metric.this_month : 0}
                  </span>
                </div>
                <div>
                  <Button
                    label="view"
                    className="p-button  p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      marginTop: "2rem",
                      marginLeft: "7rem",
                    }}
                    onClick={() => {
                      navigate("/admin-page-monthly-monitor");
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="surface-0 p-3 custom-border"
              style={{ height: "96px", width: "488px" }}
            >
              <div className="flex  mb-1">
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "3.5rem", height: "4rem" }}
                >
                  <img
                    src={require("../../assets/Group 47.png")}
                    alt="filedock"
                  />
                </div>
                <div>
                  <span className="block gable-text-dark-blue font-medium text-v4 font-bold">
                    TOTAL USERS
                  </span>
                  <span className="gable-text-dark-blue font-score font-bold">
                    {!!metric.total_user ? metric.total_user : 0}
                  </span>
                </div>
                <div>
                  <Button
                    label="view"
                    className="p-button  p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      marginTop: "2rem",
                      marginLeft: "14rem",
                    }}
                    onClick={() => {
                      navigate("/user-management");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full relative mt-4" style={{ height: "48px" }}>
            <span
              className="absolute hidden md:flex  p-input-icon-right"
              style={{ right: "0", top: "5px" }}
            >
              <InputText
                className="w-25rem custom-search-box text-v3"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                placeholder="  Keyword Search..."
              />
              <i className="pi pi-search gable-text-dark-blue" />
            </span>
            <Button
              icon="mdi mdi-magnify"
              style={{ right: "1rem", top: "5px" }}
              className="absolute p-button-rounded p-button-secondary flex md:hidden "
              onClick={() => {
                setSearchDialog(true);
              }}
            />
          </div>
          <Card>
            <DataTable
              className="text-v2"
              value={data}
              lazy
              loading={loading}
              responsiveLayout="scroll"
              paginator
              first={lazyParams.first}
              rows={lazyParams.rows}
              totalRecords={totalRecords}
              onPage={(e: any) => {
                setLazyParams(e);
              }}
              paginatorTemplate={paginatorTemplate}
              paginatorClassName="justify-content-end"
            >
              <Column
                header="Timestamp"
                field="updated_at"
                style={{
                  minWidth: "200px",
                  height: "95px",
                }}
                body={(item) => (
                  <>{moment(item.updated_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                )}
              ></Column>
              <Column
                style={{
                  width: "170px",
                  height: "95px",
                }}
                header="Upload By"
                field="upload_by"
                body={(item: ModelsUpload) => (
                  <>
                    {item.user?.first_name} {item.user?.last_name}
                  </>
                )}
              ></Column>
              <Column
                header="Branch"
                field="user.directory.name"
                style={{
                  minWidth: "120px",
                  height: "95px",
                }}
              ></Column>
              <Column
                header="Name"
                field="name"
                style={{
                  width: "140px",
                  height: "95px",
                }}
              ></Column>
              <Column
                field="detail"
                header="Detail"
                body={(item: ModelsUpload) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <b>Document Type: </b>{" "}
                      <Tag style={{ marginLeft: "0.5rem" }}>
                        {!item.doc_mode ? "formatted" : item.doc_mode}
                      </Tag>
                    </div>
                    {!!item.doc_mode && item.doc_mode !== "" && (
                      <>
                        <div className="mt-2">
                          <b>Header Columns: </b>
                          <div>
                            {item.predefined_document_ai?.fields
                              ?.split(",")
                              .map((item: any, index: number) => {
                                let split = item.split(":");
                                return (
                                  <Tag
                                    style={{
                                      background: "#fff",
                                      color: "#222",
                                      fontWeight: "normal",
                                      border: "1px solid #777",
                                      marginLeft: index > 0 ? "0.1rem" : 0,
                                    }}
                                  >
                                    {split[1].trim() === "" ? (
                                      <>&nbsp;</>
                                    ) : (
                                      <>
                                        <b>{split[1]}</b> ({split[0]})
                                      </>
                                    )}
                                  </Tag>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    )}
                    {!item.doc_mode && (
                      <>
                        <div className="mt-2">
                          <b>Transport: </b> {item.ship_type?.name}
                        </div>
                        <div>
                          <b>Type: </b> {item.mode?.name}
                        </div>
                        <div>
                          <b>Input/Output: </b> {item.in_format?.name} /{" "}
                          {item.out_format?.name}
                        </div>
                      </>
                    )}
                  </>
                )}
                style={{
                  minWidth: "180px",
                  height: "55px",
                }}
              ></Column>
              <Column
                header="Status"
                alignHeader={"center"}
                style={{
                  minWidth: "205px",
                  height: "95px",
                }}
                body={(item) => (
                  <>
                    {item.status === "pending" && (
                      <div
                        className="status-pending text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-clock"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">Pending</span>
                      </div>
                    )}
                    {item.status === "robot_working" && (
                      <div
                        className="status-robot-working text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">Working</span>
                      </div>
                    )}
                    {item.status === "incompleted" && (
                      <div
                        className="status-incompleted text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-check"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">
                          Incompleted{" "}
                          {!!item.remark && (
                            <span
                              className="ml-2 bg-gray-50 px-2 cursor-pointer text-sm border-round-xl text-gray-500 transition-duration-200 hover:bg-red-100"
                              onClick={() => {
                                setObjectFailState(item);
                                setDialogFailState(true);
                              }}
                            >
                              More
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                    {item.status === "failed" && (
                      <div
                        className="status-failed text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-times-circle"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">
                          {!!item.remark ? "Failed " : "Process Failed"}
                          {!!item.remark && (
                            <span
                              style={{ marginLeft: "55px" }}
                              className="bg-gray-50 px-2 cursor-pointer text-sm border-round-xl text-gray-500 transition-duration-200 hover:bg-red-100"
                              onClick={() => {
                                setObjectFailState(item);
                                setDialogFailState(true);
                              }}
                            >
                              More
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                    {item.status === "completed" && (
                      <div
                        className="status-success-user text-center text-white px-3 py-1 status-border flex"
                        style={{
                          width: "205px",
                        }}
                      >
                        <div className="text-left">
                          <i
                            className="pi pi-check-circle"
                            style={{ fontSize: "0.75rem" }}
                          ></i>
                        </div>
                        <span className="margin-status">Completed</span>
                      </div>
                    )}
                  </>
                )}
              />
              <Column
                header="Download Operation"
                style={{
                  minWidth: "250px",
                }}
                body={(item: ModelsUpload) => (
                  <div>
                    {(item.status === "completed" ||
                      item.status === "incompleted" ||
                      (item.status === "failed" && !!item.remark)) && (
                        <div className="text-v2-14px">
                          {!!item.csv_files && (
                            <div className="text-left">
                              <div
                                className={`ml-2 transition-duration-200 px-3 py-1 inline-block text-center ${!!item.csv_files && item.csv_files.length > 0
                                    ? "download-border download-background cursor-pointer hover:bg-orange-100 "
                                    : "download-border download-background bg-gray-300 "
                                  }`}
                                style={{
                                  width: "152.5px",
                                }}
                                onClick={() => {
                                  if (
                                    !!item.csv_files &&
                                    item.csv_files.length > 0
                                  )
                                    setObjectDownloadCsvState(item);
                                }}
                              >
                                CSV (
                                {!!item.csv_files ? item.csv_files.length : 0}{" "}
                                {!!item.csv_files && item.csv_files.length > 1
                                  ? "files"
                                  : "file"}
                                )
                              </div>
                            </div>
                          )}

                          <div
                            className="text-left"
                            style={{
                              marginTop: !!item.csv_files ? "0.5rem" : "0",
                            }}
                          >
                            <div
                              className={`ml-2 transition-duration-200 px-3 py-1 inline-block text-center ${!!item.files && item.files.length > 0
                                  ? "download-border download-background cursor-pointer hover:bg-orange-100 "
                                  : "download-border download-background bg-gray-300 "
                                }`}
                              style={{
                                width: "152.5px",
                              }}
                              onClick={() => {
                                if (!!item.files && item.files!.length > 0)
                                  setObjectDownloadSourceState(item);
                              }}
                            >
                              Source ({!!item.files ? item.files.length : 0}{" "}
                              {!!item.files && item.files.length > 1
                                ? "files"
                                : "file"}
                              )
                            </div>
                          </div>

                          {/* <div className="text-left">
                            <div
                              className={`ml-2 transition-duration-200 px-3 py-1 inline-block text-center download-border download-background cursor-pointer hover:bg-orange-100`}
                              style={{
                                width: "152.5px",
                              }}
                              onClick={() => {
                                setUploadData(item)
                                setDeleteDialog(true)
                              }}
                            >
                              Need support
                            </div>
                          </div> */}
                        </div>
                      )}
                    {/* {(item.status === "failed") && (
                      <div className="text-left">
                        <div
                          className={`ml-2 transition-duration-200 ${!!item.remark ? "mt-1":""} px-3 py-1 inline-block text-center download-border download-background cursor-pointer hover:bg-orange-100`}
                          style={{
                            width: "152.5px",
                          }}
                          onClick={() => {
                            setUploadData(item)
                            setDeleteDialog(true)
                          }}
                        >
                          Need support
                        </div>
                      </div>
                    )} */}
                  </div>
                )}
              ></Column>
            </DataTable>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
