import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { ModelsUpload, ModelsUploadFile } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { useToast } from "../../utils/Properties"
import "./style.css"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { confirmDialog } from "primereact/confirmdialog"
import CustomDeleteDialog from "../OrgManagement/CustomDeleteDialog"

interface props {
    data: ModelsUpload
    setData: Dispatch<SetStateAction<ModelsUpload>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
}

const UserDeleteFileDialog: FC<props> = ({ data, setData, visible, setVisible, refresh }) => {
    const apiInstance = useSecureApiConnector()
    const title = `Attatched files, upload name : ${data.name}`
    const [successToast, errorToast, , warnToast] = useToast();
    const [tmpFile, setTmpFile] = useState([] as ModelsUploadFile[])
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUploadFile)
    const [objectDeleteIndex, setObjectDeleteIndex] = useState(-1)
    const [dialogDelete, setDeleteDialog] = useState(false)

    const acceptDelete = () => {
        apiInstance.uploads.deleteFile(objectDeleteState.id!)
            .then(() => {
                successToast("File deleted")
                setObjectDeleteState({})

                let tmp = tmpFile
                tmp.splice(objectDeleteIndex, 1);
                setTmpFile(tmp)

                setObjectDeleteIndex(-1)
            })
            .catch(() => {
                errorToast("File delete failed")
                setObjectDeleteState({})
                setObjectDeleteIndex(-1)
            })
    }
    const onSubmit = (data: any) => {
        apiInstance.uploads.deleteFile(objectDeleteState.id!)
            .then(() => {
                successToast("File deleted")
                setObjectDeleteState({})
                let tmp = tmpFile
                tmp.splice(objectDeleteIndex, 1);
                setTmpFile(tmp)
                setObjectDeleteIndex(-1)
                setDeleteDialog(false)
                refresh()
            })
            .catch(() => {
                errorToast("File delete failed")
                setObjectDeleteState({})
                setObjectDeleteIndex(-1)
            })
    }

    useEffect(() => {
        if (visible) {
            setTmpFile(data.files!)
            setObjectDeleteIndex(-1)
        }
    }, [visible])

    return (
        <>
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmit} meessage={<>Do you want to delete this record?</>} />
            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-50rem md:w-30rem"
                showHeader={false}
                onHide={() => {
                    setVisible(false)
                    setData({})
                    refresh()
                }}
            >
                <div className="w-full py-2 px-4">
                    <div className="grid grid-nogutter" style={{ border: 'white', borderBottom: '#A098AE', borderWidth: '2px', borderStyle: 'solid' }}>
                        <div className=" hidden md:flex text-v3 font-bold relative">
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="flex md:hidden text-v3 font-bold relative" style={{ top: '4px' }}>
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mb-1"
                                // severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    setData({})
                                    refresh()
                                }}
                                style={{ fontSize: '0.5rem', background: '#A098AE', minWidth: '1rem', height: '1rem', lineHeight: '1rem' }}

                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="p-3 ">
                    <DataTable className="mx-3" value={tmpFile} dataKey="id"
                        responsiveLayout="stack">
                        <Column field="name" header="Name" className="text-v2" style={{ width: '24rem' }}></Column>
                        <Column field="operation" header="Operation" body={(item, options) => (<>
                            <div
                                className="align-items-center justify-content-center "
                                style={{ width: "3.5rem", paddingLeft: '1.5rem' }}
                            >
                                <img
                                    className="transition-duration-200 cursor-pointer  hover:bg-red-500"
                                    src={require("../../assets/Group 32.png")}
                                    style={{ borderRadius: '20px', width: '27px' }}
                                    alt="bin"
                                    onClick={() => {
                                        setObjectDeleteState(item)
                                        setObjectDeleteIndex(options.rowIndex)
                                        setDeleteDialog(true)
                                    }}
                                />
                            </div>
                            {/* <Button className="p-button-sm p-button-danger" label="Delete" onClick={() => {
                                setObjectDeleteState(item)
                                setObjectDeleteIndex(options.rowIndex)
                            }} /> */}
                        </>)} className="text-v2"></Column>
                    </DataTable>
                </div>
            </Dialog>
        </>
    )
}

export default UserDeleteFileDialog;