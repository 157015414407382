import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from "react"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import "./style.css"
import { ModelsUpload, UserTotalPageDetail } from "../../api"
import { Card } from "primereact/card"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import moment from "moment"
import { useLocation } from "react-router-dom"
import { useCheckAdmin } from "../../utils/Properties"
import { Dropdown } from "primereact/dropdown"
import { Calendar, CalendarValueType } from "primereact/calendar";

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    title_name: string
    user_id: string
}

const TotalDetailDialog: FC<props> = ({ visible, setVisible, refresh, title_name, user_id }) => {
    const apiInstance = useSecureApiConnector()
    const title = title_name
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const isAdmin = useCheckAdmin();
    const [initData, setInitData] = useState([] as UserTotalPageDetail[]);
    const [data, setData] = useState([] as UserTotalPageDetail[]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [date10, setDate10] = useState<Date | Date[] | undefined>(undefined);
    const [triggerFetch, setTriggerFetch] = useState(false);

    const [selectedYear, setSelectedYear] = useState<CalendarValueType>(
        new Date()
    );
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 12,
        page: 1,
    });

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 12, value: 12 }
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };
    const convertYear = (date: any) => {
        const year = moment(date).year();
        console.log(year);
        return year;
    }
    const monthsOrder = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const sortedData = [...data].sort((a, b) => {
        return monthsOrder.indexOf(a.month!) - monthsOrder.indexOf(b.month!);
    });
    const getOffsetLikeYear = (year: number) => {
        setLoading(true);
        apiInstance.uploads
            .getOffsetLikeYear(lazyParams.first, lazyParams.rows, year, user_id)
            .then((res) => res.data)
            .then((data) => {
                setInitData(data.record_list);
                setData(data.record_list);
                setTotalRecords(data.record_count!);
                setLoading(false);
            })
            .catch(() => {
                // navigate("/forbidden")
            });
    }

    const refresh1 = () => {
        setTriggerFetch((triggerFetch) => !triggerFetch);
    };

    useEffect(() => {
        setLoading(true);
        getOffsetLikeYear(convertYear(selectedYear));
    }, [triggerFetch]);

    useEffect(() => {
        refresh1();
    }, [lazyParams, selectedYear]);

    useEffect(() => {

        if (visible) {
            setLoading(true);

            getOffsetLikeYear(convertYear(selectedYear))

            setInterval(() => {
                if (location.pathname === "/dashboard" && !isAdmin) {
                    refresh();
                }
            }, 10000);

        }
    }, [visible]);

    return (
        <>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-dialog-70 p-0 m-0 w-50rem md:w-30rem"
                showHeader={false}
                onHide={() => {
                    setVisible(false)
                    setData([])
                }}
            >
                <div className="w-full py-2 px-4">
                    <div className="grid grid-nogutter" style={{ border: 'white', borderBottom: '#A098AE', borderWidth: '2px', borderStyle: 'solid' }}>
                        <div className="text-v3 font-bold md:flex relative">
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="text-v3 font-bold flex md:hidden relative" style={{ top: '4px' }}>
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right" style={{ marginLeft: '42rem' }}>
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                // severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    setData([])
                                }}
                                style={{ fontSize: '1rem', background: '#A098AE', minWidth: '1.5rem', height: '1.5rem', lineHeight: '1.5rem' }}
                            ></Badge>
                        </div>
                    </div>
                </div>

                <div className="w-full py-2 px-4">
                    <Card>
                        <span className="p-float-label">
                            <Calendar id="yearpicker" value={date10} onChange={(e) => {
                                setSelectedYear(e.value);
                            }} view="year" dateFormat="yy" />
                            <label htmlFor="yearpicker">Select Year</label>
                        </span>
                        <DataTable
                            className="text-v2"
                            value={sortedData}
                            lazy
                            loading={loading}
                            responsiveLayout="scroll"
                            // paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            totalRecords={sortedData.length}
                            onPage={(e: any) => {
                                setLazyParams(e);
                            }}
                            // paginatorTemplate={paginatorTemplate}
                            paginatorClassName="justify-content-end"
                        >
                            <Column
                                header="Month"
                                field="month"
                                style={{
                                    minWidth: "48rem",
                                    height: "3rem",
                                }}
                            // body={(item) => (
                            //     <>{moment(item.month).format("YYYY-MM-DD, HH:mm:ss")}</>
                            // )}
                            ></Column>
                            <Column
                                style={{
                                    minWidth: "10rem",
                                    height: "3rem",
                                }}
                                header="Totals"
                                field="total"
                            ></Column>



                        </DataTable>
                    </Card>
                </div>
            </Dialog>
        </>
    )
}

export default TotalDetailDialog;