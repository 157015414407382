import { useCheckAdmin } from "../../utils/Properties";
import { useState, Fragment, useEffect, useRef } from "react";
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Level, ModelsSupport } from "../../api";
import { Dialog } from "primereact/dialog";
import { FileUpload, FileUploadHandlerParam } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import { InputTextarea } from 'primereact/inputtextarea';
import './style.css';
import { useSecureApiConnector } from "../../utils/ApiConnector";
import {
    useToast,
    useIssue,
    usePageWaiting,
} from "../../utils/Properties";
import SearchDialog from "../../components/SearchDialog";
import { useCookies } from "react-cookie";

const Support = () => {
    const properties = {
        title: "Support",
    };
    const [successToast, errorToast, warnToast,] = useToast();
    const issue = useIssue();
    const [loading, setLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [filter, setFilter] = useState("");
    const [cookies, ,] = useCookies(["api-token"]);

    const [showFlag, setShowFlag] = useState(false);
    const [mogData, setMogData] = useState([] as ModelsSupport[]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allFile, setAllFile] = useState([] as File[])
    const fileUploadRef = useRef(null);
    const chooseOptions = { icon: '', iconOnly: false, className: 'custom-choose-btn p-button-rounded p-button-outlined browse-custom' };
    const [value1, setValue1] = useState('');
    const [ccMail, setCcMail] = useState('');
    const [selectedIssueType, setSelectedIssueType] = useState<any>(null);
    const [selectedLevel, setSelectedLevel] = useState<any>(null);
    const [selectedTopic, setSelectedTopic] = useState<any>(null);
    const [selectedUpload, setSelectedUpload] = useState<any>(null);
    const apiInstance = useSecureApiConnector()
    const [topicList, setTopicList] = useState<any>(null);
    const [levelList, setLevelList] = useState<Level[]>([]);
    const [dialogSearch, setSearchDialog] = useState(false);
    const [uploadIssue, setUploadIssue] = useState<any>(null);
    const [initData, setInitData] = useState([] as ModelsSupport[]);
    const setPageWaiting = usePageWaiting()

    const issueType = [
        { name: 'Platform' },
        { name: 'Template' },
    ];

    const level = [
        { name: 'Critical', priority: 4 },
        { name: 'High', priority: 3 },
        { name: 'Medium', priority: 2 },
        { name: 'Low', priority: 1 },
    ];

    const topic = [
        { description: 'A complete system failure or outage', level: 'Critical', issueType: 'Platform' },
        { description: 'Take a long time to get result after run engine', level: 'High', issueType: 'Platform' },
        { description: 'No result data', level: 'High', issueType: 'Platform' },
        { description: 'Incorrect wording', level: 'Medium', issueType: 'Platform' },
        { description: 'Web display not show some data', level: 'Medium', issueType: 'Platform' },
        { description: 'Suggestion and other', level: 'Low', issueType: 'Platform' },
        { description: 'Headers correct but no result data in csv file,', level: 'High', issueType: 'Template' },
        { description: 'The template result field is invalid', level: 'Medium', issueType: 'Template' },
        { description: 'Incorrect wording', level: 'Medium', issueType: 'Template' },
        { description: 'Suggestion and other', level: 'Low', issueType: 'Template' },
    ];

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    // MB
    const mb = 1000000
    const maxUpload = 20 * mb
    const [totalSize, setTotalSize] = useState(0)
    const getSize = (s: number) => (s / mb)

    const customUploader = (e: FileUploadHandlerParam) => {
        setAllFile(e.files)

        let total = 0
        e.files.forEach(f => {
            total += f.size
        })
        setTotalSize(total)
    }
    const onIssueTypeChange = (e: { value: any }) => {
        filterTopic(e.value.name!, "")
        setSelectedIssueType(e.value);
        setSelectedLevel("")
        setSelectedTopic("")
        setTotalSize(0)


    }

    const onLevelChange = (e: { value: any }) => {
        setSelectedLevel(e.value);
    }

    const onUploadChange = (e: { value: any }) => {
        setSelectedUpload(e.value);
    }

    const onTopicChange = (e: { value: any }) => {
        setSelectedTopic(e.value);
        filterLevel(e.value)
        setSelectedLevel("")
        console.log(selectedIssueType);

    }

    const fetchData = () => {

        apiInstance.support.getOffset(lazyParams.first, lazyParams.rows).then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setInitData(data.record_list)
                setMogData(data.record_list)
            })
    }
    const fetchUploadData = () => {

        apiInstance.support.getUpload().then((res) => res.data)
            .then((data) => {
                setUploadIssue(data.record_list)
            })
    }

    useEffect(() => {
        if (cookies["api-token"]) fetchData()
        fetchUploadData()
        setLoading(false);

    }, [triggerFetch]);

    useEffect(() => {

        if (filter === " " && mogData !== initData) {
            setMogData(initData);
            setTotalRecords(initData.length)
        } else {
            setLoading(true);
            let sfilter = filter;
            if (moment(filter, "MMMM Do YYYY", true).isValid()) {
                sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
            }

            apiInstance.support
                .getSearch({
                    search: sfilter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {

                    setTotalRecords(data.record_count!);
                    setMogData(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setMogData([]);
                    setLoading(false);
                });
        }
    }, [filter]);

    useEffect(() => {
        setLoading(true);
        let sfilter = filter;

        if (moment(filter, "MMMM Do YYYY", true).isValid()) {
            sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        }
        apiInstance.support
            .getSearch({
                search: sfilter,
                offset: lazyParams.first,
                limit: lazyParams.rows,
            })
            .then((res) => res.data)
            .then((data) => {

                setTotalRecords(data.record_count!);
                setMogData(data.record_list);
                setLoading(false);
            })
            .catch(() => {
                setMogData([]);
                setLoading(false);
            });
    }, [lazyParams]);

    const refresh = () => {

        setTriggerFetch((triggerFetch) => !triggerFetch);
    };

    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const formatedValue = fileUploadRef && fileUploadRef.current ? (fileUploadRef.current as any).formatSize(totalSize) : '0 B';
        return (
            <div className={`${className} header-file-upload`} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                <div className="w-full srelative font-bold text-right"><span className={getSize(totalSize) < getSize(maxUpload) ? "text-green-700" : "text-red-600"}>{formatedValue}</span>/{getSize(maxUpload)}MB</div>
            </div>
        );
    }
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file mt-3 p-5" style={{ 'fontSize': '2.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop Here</span>
            </div>
        )
    }
    const convert = () => {
        var lvl = 0

        if (selectedIssueType.name === 'Template') {
            if (selectedLevel.level === 'High') {
                lvl = 0
            } else if (selectedLevel.level === 'Medium') {
                lvl = 1
            } else {
                lvl = 2
            }
        } else {
            if (selectedLevel.level === 'Critical') {
                lvl = 0
            } else if (selectedLevel.level === 'High') {
                lvl = 1
            } else if (selectedLevel.level === 'Medium') {
                lvl = 2
            } else {
                lvl = 3
            }
        }
        console.log(lvl);

        return lvl
    }
    const clearValue = () => {
        setSelectedIssueType("");
        setSelectedLevel("")
        setSelectedTopic("")
        setValue1("")
        setCcMail("")
        setSelectedUpload("")
        refresh()
    }
    const onSubmit = async () => {
        if (selectedIssueType === null || selectedIssueType === '') {
            warnToast("Please select issue type")

        } else if (selectedIssueType.name === "Template" && (selectedUpload === null || selectedUpload === '')) {
            warnToast("Please select upload issue")

        } else if (totalSize === 0) {
            warnToast("Please upload image")

        } else {
            if (totalSize > maxUpload) {
                errorToast("Image upload size exceded")

            } else {
                const sup: ModelsSupport = {
                    level: convert(),
                    summary: value1,
                    topic: selectedTopic.description,
                    type: selectedIssueType.name
                };
                var upload_id = null
                if (selectedUpload) {
                    upload_id = selectedUpload.id
                }
                setShowFlag(false)
                setPageWaiting(true)

                await apiInstance.support.create(ccMail, sup, upload_id, allFile)
                    .then(res => res.data)
                    .then(res => {
                        if (!!res.have_duplicated && res.have_duplicated) {
                            if (res.file_name) {
                                res.file_name.forEach(e => {
                                    errorToast(`duplicated filename ${e}`)
                                })
                            }
                        } else {
                            successToast("File uploaded")
                        }
                        setAllFile([])
                        setTotalSize(0)
                        setPageWaiting(false)

                    })
                    .catch(() => {
                        setPageWaiting(false)
                        errorToast("File upload failed")
                    })
                clearValue()
                fetchData()



            }
        }
    };

    const filterTopic = (issueType: string, lvl: string) => {

        if (issueType === 'Template') {
            setTopicList(topic.filter(item => item.issueType.includes(issueType)))

        } else {
            setTopicList(topic.filter(item => item.issueType.includes(issueType)))

        }

    }

    const filterLevel = (des: any) => {
        var priority = 0
        var lvlList: Level[] = [];

        if (des.level === "Critical") {
            priority = 4
        } else if (des.level === "High") {
            priority = 3
        } else if (des.level === "Medium") {
            priority = 2
        } else {
            priority = 1
        }
        level.forEach(lvl => {
            if (lvl.priority <= priority) {
                lvlList.push(lvl)
            }
        })
        setLevelList(lvlList)
    }

    const hide = () => {
        clearValue()
        setShowFlag(false)
    }

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },


    };

    const renderFooter = () => {
        return (
            <div>

                <Button label="No" icon="pi pi-times" onClick={() => { clearValue(); setShowFlag(false) }} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => {
                    onSubmit();
                }} autoFocus />

            </div>
        );
    }

    return (
        <>
            <SearchDialog
                visible={dialogSearch}
                setVisible={setSearchDialog}
                filter={filter}
                setFilter={setFilter}
            />
            <div className="grid grid-nogutter">
                <div className="col">
                    <div className="mt-2 mb-4 font-bold text-v1">
                        {properties.title}
                    </div>
                    <Divider />

                    <Card style={{ marginTop: "1rem" }}>

                        <div className="w-full relative flex" style={{ height: "48px" }}>
                            <Button label="New Request" onClick={() => setShowFlag(true)} className="p-button-text" style={{ marginLeft: "-0.5rem" }} />

                            <span
                                className="absolute hidden md:flex p-input-icon-right"
                                style={{ right: "1rem", top: "5px" }}
                            >
                                <InputText
                                    className="w-25rem custom-search-box text-v3"
                                    value={filter}
                                    onChange={(e) => {
                                        setFilter(e.target.value);
                                    }}
                                    placeholder="  Keyword Search..."
                                />
                                <i className="pi pi-search gable-text-dark-blue" />
                            </span>
                            <Button
                                icon="mdi mdi-magnify"
                                style={{ right: "1rem", top: "5px" }}
                                className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                onClick={() => {
                                    setSearchDialog(true);
                                }}
                            />
                        </div>

                        <DataTable
                            className="text-v2"
                            value={mogData}
                            lazy
                            loading={loading}
                            responsiveLayout="scroll"
                            paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            totalRecords={totalRecords}
                            onPage={(e: any) => {
                                setLazyParams(e);
                            }}
                            paginatorTemplate={paginatorTemplate}
                            paginatorClassName="justify-content-end"
                        >
                            <Column
                                header="Timestamp"
                                field="created_at"
                                style={{
                                    // minWidth: "300px",
                                    width: "10%",
                                    height: "55px",
                                }}
                                body={(item: ModelsSupport) => (
                                    <>{moment(item.created_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                                )}
                            ></Column>
                            <Column
                                header="ID"
                                field="id"
                                style={{
                                    // minWidth: "300px",
                                    width: "20%",
                                    height: "55px",
                                }}
                            ></Column>
                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "5%",

                                    height: "55px",
                                }}
                                header="Type"
                                field="type"

                            ></Column>
                            <Column
                                style={{
                                    width: "15%",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                    height: "55px",
                                }}
                                header="Topic"
                                field="topic"
                            ></Column>

                            <Column
                                style={{
                                    width: "10%",
                                    height: "55px",
                                }}
                                header="Level"
                                field="level"
                                body={(item: ModelsSupport) => (
                                    <>
                                        {item.type === 'Platform' ? (item.level === 0 ? "Critical" : item.level === 1 ? "High" : item.level === 2 ? "Medium" : "Low") : (item.level === 0 ? "High" : item.level === 1 ? "Medium" : "Low")}
                                    </>
                                )}
                            ></Column>

                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "12%",
                                    height: "55px",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                }}
                                header="Summary"
                                field="summary"


                            ></Column>
                            <Column
                                alignHeader={"center"}
                                header="Status"
                                field="status"
                                style={{
                                    // minWidth: "150px",
                                    width: "8%",

                                    height: "55px",
                                }}
                                body={(item: ModelsSupport) => (
                                    <>
                                        {item.status === "Open" && (
                                            <div
                                                className="status-pending margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-question-circle"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Open</span>
                                            </div>
                                        )}

                                        {item.status === "Acknowledge" && (
                                            <div
                                                className="status-acknowledge margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-eye"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Acknowledge</span>
                                            </div>
                                        )}

                                        {item.status === "Resolving" && (
                                            <div
                                                className="status-resolving margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-search"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Resolving</span>
                                            </div>
                                        )}

                                        {item.status === "Resolved" && (
                                            <div
                                                className="status-resolved margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-check"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Resolved</span>
                                            </div>
                                        )}

                                        {item.status === "Closed" && (
                                            <div
                                                className="status-success margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-star"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Closed</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            />

                        </DataTable>
                    </Card>
                </div>
            </div>

            {/* Dialog */}
            <Dialog header="Support" visible={showFlag} onHide={() => hide()} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter()}>
                <>
                    <h5 style={{ marginBottom: "0.5rem" }}>Issue Type</h5>
                    <Dropdown value={selectedIssueType} options={issueType} onChange={onIssueTypeChange} optionLabel="name" placeholder="Select a Issue Type" className="drop-down" />
                    {(selectedIssueType !== '' && selectedIssueType !== null) && (
                        <div>
                            <h5 style={{ marginBottom: "0.5rem" }}>Topic</h5>
                            <Dropdown value={selectedTopic} options={topicList} onChange={onTopicChange} optionLabel="description" placeholder="Select a Topic" className="drop-down" />
                        </div>
                    )}
                    {(selectedTopic !== '' && selectedTopic !== null) && (
                        <div>
                            <h5 style={{ marginBottom: "0.5rem" }}>Level</h5>
                            <Dropdown value={selectedLevel} options={levelList} onChange={onLevelChange} optionLabel="name" placeholder="Select a Level" className="drop-down" />
                        </div>
                    )}
                    {(selectedIssueType !== '' && selectedIssueType !== null && selectedIssueType.name! === 'Template') && (
                        <div>
                            <h5 style={{ marginBottom: "0.5rem" }}>Upload Issue</h5>
                            <Dropdown value={selectedUpload} options={uploadIssue} onChange={onUploadChange} optionLabel="name" filter showClear filterBy="name" placeholder="Select a upload issue" className="drop-down" />
                        </div>
                    )}

                    <InputTextarea placeholder="CC Email Ex alias1@domain.com,alias2@domain.com" value={ccMail} onChange={(e) => setCcMail(e.target.value)} rows={5} cols={30} style={{ width: "32rem", height: "3rem", marginTop: "1.5rem" }} />
                    <InputTextarea placeholder="Description..." value={value1} onChange={(e) => setValue1(e.target.value)} rows={5} cols={30} style={{ width: "32rem", height: "8rem", marginTop: "1.5rem" }} />

                    <Divider />
                    <h5 style={{ marginBottom: "1rem" }}>Reference Pictures</h5>
                    <FileUpload
                        ref={fileUploadRef}
                        customUpload multiple
                        auto
                        accept="image/*,application/pdf"
                        maxFileSize={(maxUpload)}
                        uploadHandler={customUploader}
                        chooseLabel="Choose"
                        chooseOptions={chooseOptions}
                        headerTemplate={headerTemplate} emptyTemplate={emptyTemplate}
                        itemTemplate={(file: any, props) => {

                            return (
                                <div className="flex align-items-center flex-wrap" style={{ color: '#A098AE' }}>
                                    <div className="flex align-items-center" style={{ width: '40%' }}>
                                        {file.type !== "application/pdf" &&
                                            <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                                        }
                                        {file.type === "application/pdf" &&
                                            <i className="pi pi-file text-2xl"></i>
                                        }
                                        <span className="flex flex-column text-left ml-3">
                                            {file.name}
                                            <small>{new Date().toLocaleDateString()}</small>
                                        </span>
                                    </div>
                                    <Tag value={props.formatSize} className="px-3 py-2 " style={{ color: '#A098AE', background: 'white' }} />
                                    <div
                                        className="align-items-center justify-content-center "
                                        style={{ width: "3.5rem", paddingLeft: '7.5rem' }}
                                    >
                                        <img
                                            className="transition-duration-200 cursor-pointer  hover:bg-red-500"
                                            src={require("../../assets/Group 32.png")}
                                            style={{ borderRadius: '20px', width: '30px' }}
                                            alt="bin"
                                            onClick={(e) => {
                                                setTotalSize(totalSize - file.size);
                                                props.onRemove(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        }}
                    />
                </>
            </Dialog>
        </>




    )
}
export default Support;
