import { useCheckAdmin } from '../utils/Properties';
import UserDashboard from '../components/Dashboard/User';
import AdminDashboard from '../components/Dashboard/Admin';

const Dashboard = () => {
    const checkAdmin = useCheckAdmin()
    return (
        <>



            {!checkAdmin &&
                <UserDashboard />
            }

            {checkAdmin &&
                <AdminDashboard />
            }


        </>
    )
}

export default Dashboard;