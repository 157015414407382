/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import { usePageWaiting, useToast } from "../utils/Properties";
import { Controller, useForm } from "react-hook-form";
import { useApiConnector, useSecureApiConnector } from "../utils/ApiConnector";
import { useCookies } from "react-cookie";
import { isStrongPassword, StrongPasswordValidator } from "../utils/StrongPasswordVelidator";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";

const SignIn = () => {
    const [mounted, setMounted] = useState(false);
    const [needChPasswd, setNeedChPasswd] = useState(false);
    const [forgetPasswd, setForgetPasswd] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["api-token", "need-chpasswd", "token-expires", "directory-id", "tenant-id"])
    const [successToast, errorToast, ,] = useToast();
    const setPageWaiting = usePageWaiting();
    const apiSecureInstance = useSecureApiConnector();
    const apiUnsecureInstance = useApiConnector();
    const [dialogRequestSuccess, setDialogRequestSuccess] = useState(false);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [email, setEmail] = useState("");

    const defaultValues = {
        email: '',
        password: '',
        passwordAgain: '',
    }
    const { control, watch, formState: { errors }, handleSubmit, getValues, reset } = useForm({ defaultValues });

    const checkCresential = (email: string, password: string) => {
        setPageWaiting(true)

        apiUnsecureInstance.authentication.checkCredential({
            email: email,
            password: password,
            directory_activated_id: cookies['directory-id']
        })
            .then(res => res.data)
            .then(res => {
                updateCountBlock(email, -1)

                setCookie("api-token", res.access_token, {
                    path: "/",
                    expires: new Date(res.access_token_expires!)
                })
                setCookie("token-expires", res.access_token_expires!, {
                    path: "/",
                    expires: new Date(res.access_token_expires!)
                })
                setCookie("need-chpasswd", res.need_chpasswd, {
                    path: "/",
                    expires: new Date(res.access_token_expires!)
                })
                setCookie("directory-id", res.default_directory_id, {
                    path: "/",
                })
                setCookie("tenant-id", res.default_tenant_id, {
                    path: "/",
                })
                setPageWaiting(false)
                successToast('your credentials are logged in');
                reset();
                if (res.need_chpasswd) {
                    setNeedChPasswd(true)
                } else {
                    navigate("/dashboard")
                }

            })
            .catch((res) => {
                updateCountBlock(email)
                removeCookie("api-token")
                removeCookie("need-chpasswd")
                removeCookie("token-expires")
                setPageWaiting(false)
                if (!!res.error) {
                    errorToast(res.error.msg);
                } else {
                    errorToast("Sign in failed");
                }

            })
    }

    const updateCountBlock = async (email: string, count?: number) => {

        if (count !== undefined) {

            await apiSecureInstance.users.updateBlockUser({ email: email, block_count: count }).then(res => res.data).then(res => {
                setCount(res.block_count!)
            })
        } else {

            await apiSecureInstance.users.updateBlockUser({ email: email }).then(res => res.data).then(res => {
                setCount(res.block_count!)
            })
        }

    }

    const checkCountSignIn = () => {
        console.log(count);

        if (count >= 5) {
            console.log(email);

            apiSecureInstance.users.blockUser({ email: email }).then(res => res.data).then(res => {
                console.log(res);
                setCount(0)

            })

        }

    }

    const onSubmit = (data: any) => {

        setEmail(data.email)
        checkCresential(data.email, data.password)
    };
    const forgetPassword = (data: any) => {
        setPageWaiting(true)
        apiUnsecureInstance.users.forgetPasswordUser({
            email: data.email,
        })
            .then(() => {
                removeCookie("api-token")
                removeCookie("need-chpasswd")
                setPageWaiting(false)
                successToast('request to reset password success');
                setDialogRequestSuccess(true)
                setNeedChPasswd(false)
                setForgetPasswd(false)
                reset();
            })
            .catch(() => {
                removeCookie("api-token")
                removeCookie("need-chpasswd")
                setPageWaiting(false)
                errorToast('cannot request to reset password');
            })
    }
    const chpasswd = (data: any) => {
        setPageWaiting(true)
        apiSecureInstance.users.bringYourOwnPasswordUser({
            new_password: data.password,
        })
            .then(() => {

                successToast('password changed');
                removeCookie("api-token")
                removeCookie("need-chpasswd")
                setNeedChPasswd(false)
                reset();
                setPageWaiting(false)
            })
            .catch(() => {
                errorToast('change password failed');
                setPageWaiting(true)
            })

    };

    useEffect(() => {
        checkCountSignIn();
    }, [count]);

    return (
        <>
            <Dialog closable={false} draggable={false}
                visible={dialogRequestSuccess}
                className="custom-dialog p-0 m-0 w-23rem md:w-30rem"
                showHeader={false}
                onHide={() => {
                    setDialogRequestSuccess(false)
                }}
            >
                <div className="w-full gable-bg-gray py-2 px-4">
                    <div className="grid grid-nogutter">
                        <div className="col-10 text-xl hidden md:flex text-white relative">
                            {"request successfully".toLocaleUpperCase()}
                        </div>
                        <div className="col-10 text-sm flex md:hidden text-white relative" style={{ top: '4px' }}>
                            {"request successfully".toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setDialogRequestSuccess(false)
                                }}
                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="p-5 text-center">
                    <i className="mdi mdi-check-circle text-7xl gable-text-orange"></i>
                    <div className="gable-text-gray text-xl">
                        The G-RPA send a password reset request for G-RPA account to your email. <br />Reset link will expire in 30 minutes.
                    </div>
                </div>
            </Dialog>
            <div className="grid grid-nogutter p-0 h-screen bg-bluegray-50">
                <div className="col-9 login-background overflow-hidden p-0 hidden lg:flex"
                // style={{ background: '#211E52' }}
                // style={{ background: 'linear-gradient(to top, #211E52, #7C60FF)' }}
                >

                    <img
                        src={require("../assets/Frame.png")}
                        className="relative"
                        style={{
                            width: '70%',
                            height: '42%',
                            top: '30%',
                            left: '15%',
                            objectFit: 'cover'
                        }}
                        alt="Background"
                    />
                </div>
                {/* <div className="col p-8 text-left flex align-items-center justify-content-center"> */}
                <div className="col flex justify-content-center">
                    <section style={{ maxWidth: '400px' }}>
                        <img src={require("../assets/logo2.png")} alt="logo" style={{ paddingTop: '25%', paddingBottom: '26%', paddingLeft: '20%' }} />
                        {/* <span className="block text-6xl gable-text-orange font-bold mb-1">
                            G-ABLE
                        </span> */}
                        {((needChPasswd && !forgetPasswd) || forgetPasswd) &&
                            <p className="mt-0 mb-4 gable-text-gray line-height-3">
                                {(needChPasswd && !forgetPasswd) &&
                                    <>
                                        Bring Your Own Password
                                    </>
                                }
                                {(forgetPasswd) &&
                                    <>
                                        Request to reset your password
                                    </>
                                }
                            </p>
                        }


                        {(needChPasswd && !forgetPasswd) &&
                            <>
                                <form onSubmit={handleSubmit(chpasswd)} className="p-fluid" style={{ maxWidth: '400px' }} >

                                    <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                        <div className="m-2">
                                            {/* <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span> */}
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password"
                                                feedback={false}
                                                className={!!errors.password ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />

                                    <Controller name="passwordAgain" control={control} rules={{
                                        required: 'Password Agian is required.',
                                        validate: (passwd) => (getValues("password") == passwd)
                                    }} render={({ field, fieldState }) => (
                                        <div className="m-2">
                                            {/* <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span> */}
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password Again"
                                                feedback={false}
                                                className={!!errors.password ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />


                                    <Button
                                        label="Set Password"
                                        type="submit"
                                        className="p-button m-2 p-button-rounded"
                                        style={{ background: '#292666' }}
                                        disabled={!(isStrongPassword(watch().password) && watch().password == watch().passwordAgain)}
                                    />


                                </form>
                                <StrongPasswordValidator password={getValues("password")} />
                                <Divider />
                            </>
                        }

                        {(!needChPasswd && !forgetPasswd) &&
                            <>
                                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid" style={{ maxWidth: '400px' }} >

                                    <Controller name="email" control={control}
                                        rules={{
                                            required: 'Email is required.', pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Invalid email address. E.g. example@g-able.com'
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <div className="m-2">
                                                {/* <span className="p-inputgroup-addon">
                                                    <i className="pi pi-user" />
                                                </span> */}
                                                <label htmlFor="email" style={{ fontFamily: 'Roboto', fontSize: '18px' }}>Email</label>
                                                <InputText
                                                    id={field.name} {...field}
                                                    className={!!errors.email ? 'text-input-v2' : 'text-input-v1'}
                                                    placeholder="Email"
                                                    style={{ marginTop: '3%' }}
                                                />
                                            </div>
                                        )} />


                                    <Controller name="password"
                                        control={control}
                                        rules={{ required: 'Password is required.' }}
                                        render={({ field, fieldState }) => (
                                            <div className="m-2">
                                                {/* <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span> */}
                                                <label htmlFor="password" style={{ fontFamily: 'Roboto', fontSize: '18px' }}>Password</label>

                                                <Password
                                                    id={field.name} {...field}
                                                    placeholder="Password"
                                                    feedback={false}
                                                    className={!!errors.password ? 'p-invalid' : ''}
                                                    toggleMask
                                                    style={{ marginTop: '3%' }}
                                                />
                                            </div>
                                        )} />

                                    <div className="my-3 mx-2">
                                        <Button
                                            label="Sign In"
                                            type="submit"
                                            className="p-button p-button-rounded p-button-lg"
                                            style={{ background: '#292666', fontFamily: 'Roboto', fontSize: '18px' }}
                                        />
                                    </div>




                                </form>

                            </>
                        }

                        {(forgetPasswd) &&
                            <>
                                <form onSubmit={handleSubmit(forgetPassword)} className="p-fluid" style={{ maxWidth: '400px' }} >

                                    <Controller name="email" control={control}
                                        rules={{
                                            required: 'Email is required.', pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Invalid email address. E.g. example@g-able.com'
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <div className="p-inputgroup m-2">
                                                {/* <span className="p-inputgroup-addon">
                                                    <i className="pi pi-user" />
                                                </span> */}
                                                <label htmlFor="email" style={{ fontFamily: 'Roboto', fontSize: '18px' }}>Email</label>
                                                <InputText
                                                    id={field.name} {...field}
                                                    className={!!errors.email ? 'text-input-v2' : 'text-input-v1'}
                                                    placeholder="Email"
                                                    style={{ marginTop: '3%' }}
                                                />
                                            </div>
                                        )} />

                                    <Button
                                        label="Request"
                                        type="submit"
                                        className="p-button m-2 p-button-rounded"
                                        style={{ background: '#292666' }}
                                    />


                                </form>
                                <div className="pl-2">
                                    Go back to sign-in page&nbsp;
                                    <span
                                        className="text-primary cursor-pointer underline"
                                        onClick={() => {
                                            setNeedChPasswd(false)
                                            setForgetPasswd(false)
                                        }}
                                    >Click here</span>
                                </div>
                            </>
                        }



                        {(errors.email || errors.password || errors.passwordAgain) &&
                            <div className="pl-2">
                                <p className="p-error font-bold">Hint</p>
                                <p className="m-0 p-error">{errors.email ? <small>{errors.email.message}</small> : <></>}</p>
                                <p className="m-0 p-error">{errors.password ? <small>{errors.password.message}</small> : <></>}</p>
                                <p className="m-0 p-error">{errors.passwordAgain ? <small>{errors.passwordAgain.message ? errors.passwordAgain.message : "Password not match"}</small> : <></>}</p>
                            </div>
                        }


                    </section>
                </div>
            </div>
        </>
    );
};

export default SignIn;
